import { get, post, put, deleteById } from "@api";
import {
    SET_PARTNER_PRODUCTS,
    SET_PARTNER_PRODUCT_ERROR,
    SET_PARTNER_PRODUCTS_LOADING,
    SET_CREATED_PARTNER_PRODUCT,
    SET_UPDATED_PARTNER_PRODUCT,
    REMOVE_DELETED_PARTNER_PRODUCT,
    SET_PARTNER_PRODUCTS_FETCHING,
} from "../types/partnerProducts.types";
import { setNotice } from "@actions/notice.action";

export const setPartnerProductsLoading = () => dispatch =>
    dispatch({
        type: SET_PARTNER_PRODUCTS_LOADING,
    });

export const setPartnerProductsFetching = () => dispatch =>
    dispatch({
        type: SET_PARTNER_PRODUCTS_FETCHING,
    });

export const setPartnerProducts = partnerProducts => dispatch =>
    dispatch({
        type: SET_PARTNER_PRODUCTS,
        partnerProducts,
    });

export const setCreatedPartnerProduct = partnerProduct => dispatch =>
    dispatch({
        type: SET_CREATED_PARTNER_PRODUCT,
        partnerProduct,
    });

export const setUpdatedPartnerProduct = updatedPartnerProduct => dispatch =>
    dispatch({
        type: SET_UPDATED_PARTNER_PRODUCT,
        updatedPartnerProduct,
    });

export const removeDeletedPartnerProduct = partnerProductId => dispatch =>
    dispatch({
        type: REMOVE_DELETED_PARTNER_PRODUCT,
        partnerProductId,
    });

export const setPartnerProductsError = error => dispatch =>
    dispatch({
        type: SET_PARTNER_PRODUCT_ERROR,
        error,
    });

export const getAllPartnerProducts = async dispatch => {
    try {
        dispatch(setPartnerProductsFetching());

        const partnerProducts = await get(`/product-partners`);

        dispatch(setPartnerProducts(partnerProducts));

        return partnerProducts;
    } catch (error) {
        dispatch(setPartnerProductsError(error.message));
    }
};

export const getPartnerProductById = partnerProductId => async dispatch => {
    try {
        const partnerProduct = await get(`/product-partners/${partnerProductId}`);

        return {
            ...partnerProduct,
        };
    } catch (error) {
        dispatch(setPartnerProductsError(error.message));
    }
};

export const createPartnerProduct = partnerProduct => async dispatch => {
    try {
        dispatch(setPartnerProductsLoading());

        const createdPartnerProduct = await post("/product-partners", partnerProduct);

        dispatch(setCreatedPartnerProduct(createdPartnerProduct.data));
        dispatch(setNotice({ message: createdPartnerProduct.message, type: "success" }));

        return createdPartnerProduct;
    } catch (error) {
        dispatch(setPartnerProductsError(error.message));
        dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
    }
};

export const updatePartnerProduct = (partnerProductId, partnerProduct) => async dispatch => {
    try {
        dispatch(setPartnerProductsLoading());

        const updatedPartnerProduct = await put(`/product-partners/${partnerProductId}`, partnerProduct);
        //dispatch(setUpdatedPartnerProduct(updatedPartnerProduct.data));
        dispatch(setNotice({ message: updatedPartnerProduct.message, type: "success" }));

        return updatedPartnerProduct;
    } catch (error) {
        dispatch(setPartnerProductsError(error.message));
        dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
    }
};

export const deletePartnerProductById = partnerProductId => async dispatch => {
    try {
        dispatch(setPartnerProductsLoading());

        const deletedPartnerProduct = await deleteById("/product-partners", partnerProductId);

        dispatch(removeDeletedPartnerProduct(partnerProductId));
        dispatch(setNotice({ message: deletedPartnerProduct.message, type: "success" }));

        return deletedPartnerProduct;
    } catch (error) {
        dispatch(setPartnerProductsError(error.message));
        dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
    }
};
