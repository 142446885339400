import React from "react";
import { forwardRef, useCallback, useState } from "react";
import { CheckboxInput, Checkmark } from "./Checkbox.styled";

import Label from "@components/Label";

const Checkbox = forwardRef(
  (
    {
      name,
      label,
      defaultChecked,
      disabled = false,
      colorDisabled = false,
      onChange,
      labelFontSize,
      labelWeight,
      uppercase,
      marked,
      ...otherProps
    },
    ref
  ) => {
    const [isChecked, setChecked] = useState(!!defaultChecked);

    const toggle = useCallback(() => {
      const newCheckedState = !isChecked;
      setChecked(newCheckedState);

      if (onChange) {
        onChange(newCheckedState);
      }
    }, [isChecked, onChange]);

    return (
      <Label
        title={label || ""}
        labelFontSize={labelFontSize}
        labelWeight={labelWeight}
        uppercase={uppercase}
        disabled={disabled}
        position="right">
        <CheckboxInput
          type="checkbox"
          id={name}
          checked={isChecked}
          disabled={disabled}
          onChange={toggle}
          ref={ref}
          colorDisabled={colorDisabled}
          {...otherProps}
        />
        <Checkmark marked={marked} />
      </Label>
    );
  }
);

export default Checkbox;
