import styled from "styled-components";

export const Input = styled.input`
  width: ${({ width = "50px" }) => width};
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  background-color: ${({ theme: { sectionAccent }, isreadonly = false }) =>
    isreadonly ? "transparent" : sectionAccent};
  border: 1px solid #959595;
  border-radius: 2px;
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  appearance: none;

  ${({ withArrows = false }) =>
    !withArrows &&
    `
  &::-webkit-inner-spin-button, 
  &::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0;
  }`}
`;
