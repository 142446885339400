import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import moment from "moment";

import { Form, FormButtons } from "@styles/form";
import { Row } from "@styles/grid";
import { Span } from "@styles/typography";
import TextInput from "@components/inputs/TextInput";
import Checkbox from "@components/inputs/Checkbox";
import { ButtonPrimary, ButtonSecondary, ButtonSecondaryLink } from "@components/Button";

import { useHistory } from "react-router-dom";
import { editApiApplicationSchema } from "@utils/validation";
import { updateApiApplication } from "@actions/apiApplications.action";

const ApiApplicationViewForm = ({ apiApplication }) => {
  const [apiApplicationState, setApiApplicationState] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.apiApplications.loading);

  useEffect(() => {
    if (apiApplication) {
      setApiApplicationState(apiApplication);
    }
  }, [apiApplication]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      application_name: apiApplicationState ? apiApplicationState.application_name : "",
      api_key: true,
    },
    validationSchema: editApiApplicationSchema,
    onSubmit: async ({ application_name, api_key }) => {
      try {
        const newApiApplication = {
          application_name,
          api_key,
        };

        const savedApiApplication = await dispatch(updateApiApplication(apiApplication.id, newApiApplication));

        if (savedApiApplication.data) {
          setApiApplicationState(savedApiApplication.data);
          setEditMode(false);
          history.push("/api-applications");
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
  });

  const handleCancel = () => {
    setEditMode(prevMode => !prevMode);

    formik.setValues({
      application_name: apiApplicationState.application_name,
      api_key: editMode,
    });
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextInput
        position
        label="Name"
        labelPosition="left"
        fluid
        placeholder={editMode ? "Api Application Name" : ""}
        name="application_name"
        id="application_name"
        value={formik.values.application_name}
        onChange={formik.handleChange}
        formik={formik}
        isreadonly={!editMode}
        readOnly={!editMode}
      />
      {editMode && (
        <Row align="center">
          <Span size={1.6} weight={500} margin="0 2rem 0 0">
            Do you want to generate a new key?
          </Span>
          <Checkbox
            name="api_key"
            id="api_key"
            value={formik.values.api_key}
            checked={formik.values.api_key}
            onClick={formik.handleChange}
            formik={formik}
          />
        </Row>
      )}

      {!editMode && (
        <>
          <TextInput
            position
            label="Api Key"
            labelPosition="left"
            fluid
            value={apiApplicationState && apiApplicationState.api_key}
            isreadonly={true}
          />

          <TextInput
            position
            label="Created"
            labelPosition="left"
            fluid
            id="application_name"
            value={apiApplicationState && moment(apiApplicationState.created).format("LLL")}
            isreadonly={true}
          />
          <TextInput
            position
            label="Last update"
            labelPosition="left"
            fluid
            value={apiApplicationState && moment(apiApplicationState.modified).format("LLL")}
            isreadonly={true}
          />
        </>
      )}

      <Row margin="3rem 0" direction="column" align="flex-end">
        <FormButtons>
          {!editMode && (
            <ButtonSecondaryLink outlined uppercase href="/api-applications">
              Back to Api Applications
            </ButtonSecondaryLink>
          )}
          <ButtonSecondary uppercase onClick={handleCancel}>
            {editMode ? "cancel" : "Edit"}
          </ButtonSecondary>{" "}
          {editMode && (
            <ButtonPrimary type="submit" onClick={formik.handleSubmit} uppercase disabled={loading}>
              Save Api Application
            </ButtonPrimary>
          )}
        </FormButtons>
      </Row>
    </Form>
  );
};

export default ApiApplicationViewForm;
