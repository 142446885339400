import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { Row, Indent } from "@styles/grid";
import TextInput from "@components/inputs/TextInput";
import Checkbox from "@components/inputs/Checkbox";
import Select from "@components/inputs/Select";
import { ButtonPrimary, ButtonSecondaryLink } from "@components/Button";
import { Form } from "@styles/form";
import { createProduct } from "@actions/products.action";
import { nullIfEmpty } from "@utils/string";
import { getAllCategories } from "@actions/categories.action";
import { getAllBrands } from "@actions/brands.action";
import { productValidationSchema } from "@utils/validation";

const ProductAddForm = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const categories = useSelector(state => state.categories.categories.filter(({ selectable }) => !!selectable));
  const brands = useSelector(state => state.brands.brands);
  const loading = useSelector(state => state.products.loading);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      category_id: null,
      brand_id: null,
      amazon_upc: "",
      walmart_upc: "",
      keywords: "",
      value_pack: false,
    },
    validationSchema: productValidationSchema,
    onSubmit: async ({ name, category_id, brand_id, amazon_upc, walmart_upc, keywords, value_pack }) => {
      try {
        const newProduct = {
          name,
          category_id,
          brand_id,
          amazon_upc: nullIfEmpty(amazon_upc),
          walmart_upc: nullIfEmpty(walmart_upc),
          keywords: nullIfEmpty(keywords),
          value_pack,
        };

        const savedProduct = await dispatch(createProduct(newProduct));

        if (savedProduct.data) {
          return history.push("/products");
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
  });

  useEffect(() => {
    const isBrandsNotEmpty = brands && brands.length;
    const isCategoriesNotEmpty = categories && categories.length;
    if (!isBrandsNotEmpty) {
      dispatch(getAllBrands());
    }
    if (!isCategoriesNotEmpty) {
      dispatch(getAllCategories());
    }
  }, []);

  const handleSelectCategory = category => {
    formik.setFieldValue("category_id", category.value);
    setSelectedCategory(category);
  };

  const handleSelectBrand = brand => {
    formik.setFieldValue("brand_id", brand.value);
    setSelectedBrand(brand);
  };

  const categoriesSelectOptions =
    categories && categories.length
      ? categories.map(category => ({
          value: category.id,
          label: category.name,
        }))
      : [];

  const brandsSelectOptions =
    brands && brands.length
      ? [
          { value: null, label: "None" },
          ...brands.map(brand => ({
            value: brand.id,
            label: brand.name,
          })),
        ]
      : [];

  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextInput
        position
        label="Name"
        labelPosition="left"
        fluid
        placeholder="Product Name"
        name="name"
        id="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        formik={formik}
      />
      <Select
        label="Category"
        labelPosition="left"
        placeholder="Select Category"
        options={categoriesSelectOptions}
        value={selectedCategory}
        onChange={handleSelectCategory}
        fluid
        formik={formik}
        name="category_id"
        id="category_id"
      />
      <Select
        label="Brand"
        labelPosition="left"
        placeholder="Select Brand"
        options={brandsSelectOptions}
        value={selectedBrand}
        onChange={handleSelectBrand}
        fluid
        formik={formik}
        name="brand_id"
        id="brand_id"
      />
      <TextInput
        position
        label="Amazon UPC"
        labelPosition="left"
        fluid
        placeholder="Enter product Amazon UPC"
        onChange={formik.handleChange}
        name="amazon_upc"
        id="amazon_upc"
        value={formik.values.amazon_upc}
        formik={formik}
      />
      <TextInput
        position
        label="Walmart UPC"
        labelPosition="left"
        fluid
        placeholder="Enter product Walmart UPC"
        onChange={formik.handleChange}
        name="walmart_upc"
        id="walmart_upc"
        value={formik.values.walmart_upc}
        formik={formik}
      />
      <TextInput
        position
        label="Keywords"
        labelPosition="left"
        fluid
        placeholder="Enter keywords for search"
        onChange={formik.handleChange}
        name="keywords"
        id="keywords"
        value={formik.values.keywords}
        formik={formik}
      />
      <Indent margin="0 3rem 2.5rem auto">
        <Checkbox
          label="Value Pack"
          name="value_pack"
          id="value_pack"
          labelFontSize={1.2}
          value={formik.values.value_pack}
          checked={formik.values.value_pack}
          onClick={formik.handleChange}
          formik={formik}
        />
      </Indent>
      <Row margin="3rem 0" justify="flex-end">
        <Indent margin="0 2rem 0">
          <ButtonSecondaryLink uppercase outlined href="/products">
            back to products
          </ButtonSecondaryLink>
        </Indent>
        <ButtonPrimary type="submit" uppercase disabled={loading} onClick={formik.handleSubmit}>
          Create Product
        </ButtonPrimary>
      </Row>
    </Form>
  );
};

export default ProductAddForm;
