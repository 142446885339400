import { get, post, put, deleteById } from "@api";
import {
  SET_PRODUCTS,
  SET_CREATED_PRODUCT,
  SET_UPDATED_PRODUCT,
  REMOVE_DELETED_PRODUCT,
  SET_PRODUCT_ERROR,
  SET_PRODUCTS_LOADING,
  SET_PRODUCTS_FETCHING,
} from "../types/product.types";
import { setNotice } from "./notice.action";

export const setProductsLoading = () => dispatch =>
  dispatch({
    type: SET_PRODUCTS_LOADING,
  });

export const setProductsFetching = () => dispatch =>
  dispatch({
    type: SET_PRODUCTS_FETCHING,
  });

export const setProducts = products => dispatch =>
  dispatch({
    type: SET_PRODUCTS,
    products,
  });

export const setCreatedProduct = product => dispatch =>
  dispatch({
    type: SET_CREATED_PRODUCT,
    product,
  });

export const setUpdatedProduct = updatedProduct => dispatch =>
  dispatch({
    type: SET_UPDATED_PRODUCT,
    updatedProduct,
  });

export const removeDeletedProduct = productId => dispatch =>
  dispatch({
    type: REMOVE_DELETED_PRODUCT,
    productId,
  });

export const setProductsError = error => dispatch =>
  dispatch({
    type: SET_PRODUCT_ERROR,
    error,
  });

export const getAllProducts = (include = "") => async dispatch => {
  try {
    dispatch(setProductsFetching());
    const products = await get(`/products?include=${include}`);

    dispatch(setProducts(products));

    return products;
  } catch (error) {
    dispatch(setProductsError(error.message));
  }
};

export const getProductById = (productId, include = "") => async dispatch => {
  try {
    const product = await get(`/products/${productId}?include=${include}`);

    return product;
  } catch (error) {
    dispatch(setProductsError(error.message));
  }
};

export const createProduct = product => async dispatch => {
  try {
    dispatch(setProductsLoading());

    const createdProduct = await post("products", product);

    dispatch(setCreatedProduct(createdProduct.data));
    dispatch(setNotice({ message: createdProduct.message, type: "success" }));

    return createdProduct;
  } catch (error) {
    dispatch(setProductsError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const setProductViews = products => () => {
  try {
    post("products/views", products);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateProduct = (productId, product) => async dispatch => {
  try {
    dispatch(setProductsLoading());

    const updatedProduct = await put(`products/${productId}`, product);

    dispatch(setUpdatedProduct(updatedProduct.data));
    dispatch(setNotice({ message: updatedProduct.message, type: "success" }));

    return updatedProduct;
  } catch (error) {
    dispatch(setProductsError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const deleteProductById = productId => async dispatch => {
  try {
    dispatch(setProductsLoading());

    const deletedProduct = await deleteById("products", productId);

    dispatch(removeDeletedProduct(productId));
    dispatch(setNotice({ message: deletedProduct.message, type: "success" }));

    return deletedProduct;
  } catch (error) {
    dispatch(setProductsError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const getProductsByName = (name, selected) => async () => {
  try {
    const selectedProducts = selected && selected.length ? `&selected=${selected.join(",")}` : "";

    const products = await get(`products/search/q?name=${name}${selectedProducts}`);

    return products;
  } catch (error) {
    console.error(error.message);
  }
};
