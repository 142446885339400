import styled from "styled-components";
import { media } from "@styles";

export const Container = styled.div`
  margin: 0 auto;
  max-width: 100rem;
  padding: 0 1.5rem;

  ${media.giant`
    max-width: 100rem;
    width: 100%;
  `}

  ${media.tablet`
    max-width: 100rem;
    width: 100%;
  `}
`;

export const Row = styled.div`
  width: ${({ fluid }) => (fluid ? "auto" : "100%")};
  display: flex;
  ${({ direction }) => direction && `flex-direction: ${direction}`};
  ${({ justify }) => justify && `justify-content: ${justify}`};
  ${({ align }) => align && `align-items: ${align}`};
  ${({ wrap = false }) => wrap && `flex-wrap: wrap`};
  flex: 1 1 auto;
  margin: ${({ margin = "0" }) => margin};
  padding: ${({ padding = "0" }) => padding};
`;

export const Indent = styled.div`
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
`;
