import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { Row, Indent } from "@styles/grid";
import TextInput from "@components/inputs/TextInput";
import Checkbox from "@components/inputs/Checkbox";
import { ButtonPrimary, ButtonSecondary, ButtonSecondaryLink } from "@components/Button";
import { Form, FormButtons } from "@styles/form";

import { useHistory } from "react-router-dom";
import { editBucketValidationSchema } from "@utils/validation";
import slugify from "slugify";
import { updateBucket } from "@actions/buckets.action";

const BucketViewForm = ({ bucket }) => {
  const [bucketState, setBucketState] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.buckets.loading);

  useEffect(() => {
    if (bucket) {
      setBucketState(bucket);
    }
  }, [bucket]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: bucketState ? bucketState.name : "",
      slug: bucketState ? bucketState.slug : "",
      initial: bucketState ? Boolean(bucketState.initial) : false,
      enabled: bucketState ? Boolean(bucketState.enabled) : false,
    },
    validationSchema: editBucketValidationSchema,
    onSubmit: async ({ name, slug, initial, enabled }) => {
      const nameToLowerCase = name.toLowerCase();
      try {
        const newBucket = {
          name: nameToLowerCase,
          slug,
          initial,
          enabled,
        };

        const savedBucket = await dispatch(updateBucket(bucket.id, newBucket));

        if (savedBucket.data) {
          setBucketState(savedBucket.data);
          setEditMode(false);
          history.push("/buckets");
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
  });

  const handleCancel = () => {
    setEditMode(prevMode => !prevMode);

    formik.setValues({
      name: bucketState.name,
      slug: bucketState.slug,
      initial: Boolean(bucketState.initial),
      enabled: Boolean(bucketState.enabled),
    });
  };

  useEffect(() => {
    if (formik.values.name) {
      formik.setFieldValue("slug", slugify(formik.values.name, { lower: true }));
    }
  }, [formik.values.name]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextInput
        position
        label="Name"
        labelPosition="left"
        fluid
        placeholder={editMode ? "Bucket Name" : ""}
        name="name"
        id="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        formik={formik}
        isreadonly={!editMode}
        readOnly={!editMode}
      />

      <TextInput
        label="Slug"
        labelPosition="left"
        fluid
        placeholder="Bucket Slug"
        name="slug"
        id="slug"
        value={formik.values.slug}
        onChange={formik.handleChange}
        formik={formik}
        isreadonly={true}
        readOnly={true}
      />

      <Indent margin="1rem 0">
        <Checkbox
          label="Initial bucket"
          name="initial"
          id="initial"
          labelFontSize={1.8}
          value={formik.values.initial}
          checked={formik.values.initial}
          formik={formik}
          colorDisabled
        />
      </Indent>

      <Indent margin="1rem 0">
        <Checkbox
          label="Enabled / Disabled"
          name="enabled"
          id="enabled"
          labelFontSize={1.8}
          value={formik.values.enabled}
          checked={formik.values.enabled}
          onClick={!editMode ? () => null : formik.handleChange}
          formik={formik}
          readOnly={true}
        />
      </Indent>

      <Row margin="3rem 0" direction="column" align="flex-end">
        <FormButtons>
          {!editMode && (
            <ButtonSecondaryLink outlined uppercase href="/buckets">
              Back to Buckets
            </ButtonSecondaryLink>
          )}
          <ButtonSecondary uppercase onClick={handleCancel}>
            {editMode ? "cancel" : "Edit"}
          </ButtonSecondary>{" "}
          {editMode && (
            <ButtonPrimary type="submit" onClick={formik.handleSubmit} uppercase disabled={loading}>
              Save Bucket
            </ButtonPrimary>
          )}
        </FormButtons>
      </Row>
    </Form>
  );
};

export default BucketViewForm;
