import styled from "styled-components";

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;

  > label {
    margin-bottom: 3rem;
  }

  > span {
    margin-top: -2rem;
    margin-bottom: 3rem;
  }
`;

export const SaveButtons = styled.div`
  display: flex;

  > * {
    margin-right: 1.5rem;

    &:last-child {
      margin-right: unset;
    }
  }
`;

export const FormButtons = styled.div`
  display: flex;
  margin-top: 2rem;

  > *:first-child {
    margin-right: 2rem;
  }
`;

export const FormInputs = styled.div`
  > label {
    width: 100%;
    margin-bottom: 3rem;
  }

  > span {
    margin-top: -2rem;
    margin-bottom: 3rem;
    display: block;
  }
`;
