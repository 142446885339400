import { AUTH_LOGOUT } from "../types/auth.types";
import {
  REMOVE_DELETED_ROLE,
  SET_CREATED_ROLE,
  SET_ROLES,
  SET_ROLES_FETCHING,
  SET_ROLES_LOADING,
  SET_ROLE_ERROR,
  SET_UPDATED_ROLE,
} from "../types/roles.types";

const initialState = {
  roles: [],
  loading: false,
  fetching: false,
  error: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ROLES:
      return {
        ...state,
        roles: action.roles,
        fetching: false,
      };
    case SET_CREATED_ROLE:
      return {
        ...state,
        roles: [...state.roles, action.role],
        loading: false,
        error: "",
      };
    case SET_UPDATED_ROLE: {
      const copyOfRoles = [...state.roles];
      const foundedRoleIndex = copyOfRoles.findIndex(({ id }) => id === action.updatedRole.id);
      copyOfRoles[foundedRoleIndex] = action.updatedRole;

      return {
        ...state,
        roles: [...copyOfRoles],
        loading: false,
        error: "",
      };
    }
    case REMOVE_DELETED_ROLE: {
      return {
        ...state,
        roles: state.roles.filter(role => role.id !== action.roleId),
        loading: false,
        error: "",
      };
    }
    case SET_ROLES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_ROLES_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case SET_ROLE_ERROR:
      return {
        ...state,
        error: Array.isArray(action.error) ? action.error[0] : action.error,
        loading: false,
      };
    case AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
