import { get, post, put, deleteById } from "@api";
import {
  SET_BUNDLES,
  SET_BUNDLES_ERROR,
  SET_BUNDLES_LOADING,
  SET_UPDATED_BUNDLE,
  SET_CREATED_BUNDLE,
  SET_BUNDLES_FETCHING,
  REMOVE_DELETED_BUNDLE,
} from "../types/bundles.types";
import { setNotice } from "@actions/notice.action";

export const setBundlesLoading = () => dispatch =>
  dispatch({
    type: SET_BUNDLES_LOADING,
  });

export const setBundlesFetching = () => dispatch =>
  dispatch({
    type: SET_BUNDLES_FETCHING,
  });

export const setBundles = bundles => dispatch =>
  dispatch({
    type: SET_BUNDLES,
    bundles,
  });

export const setCreatedBundle = bundle => dispatch =>
  dispatch({
    type: SET_CREATED_BUNDLE,
    bundle,
  });

export const setUpdatedBundle = updatedBundle => dispatch =>
  dispatch({
    type: SET_UPDATED_BUNDLE,
    updatedBundle,
  });

export const removeDeletedBundle = bundleId => dispatch =>
  dispatch({
    type: REMOVE_DELETED_BUNDLE,
    bundleId,
  });

export const setBundlesError = error => dispatch =>
  dispatch({
    type: SET_BUNDLES_ERROR,
    error,
  });

export const getAllBundles = (include = "") => async dispatch => {
  try {
    dispatch(setBundlesFetching());

    const bundles = await get(`/bundles?include=${include}`);

    dispatch(setBundles(bundles));

    return bundles;
  } catch (error) {
    dispatch(setBundlesError(error.message));
  }
};

export const getBundleById = (bundleId, include = "") => async dispatch => {
  try {
    const bundle = await get(`/bundles/${bundleId}?include=${include}`);

    return bundle;
  } catch (error) {
    dispatch(setBundlesError(error.message));
  }
};

export const createBundle = bundle => async dispatch => {
  try {
    dispatch(setBundlesLoading());

    const createdBundle = await post("bundles", bundle);

    dispatch(setCreatedBundle(createdBundle.data));
    dispatch(setNotice({ message: createdBundle.message, type: "success" }));

    return createdBundle;
  } catch (error) {
    dispatch(setBundlesError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const updateBundle = (bundleId, bundle) => async dispatch => {
  try {
    dispatch(setBundlesLoading());

    const updatedBundle = await put(`bundles/${bundleId}`, bundle);

    dispatch(setUpdatedBundle(updatedBundle.data));
    dispatch(setNotice({ message: updatedBundle.message, type: "success" }));

    return updatedBundle;
  } catch (error) {
    dispatch(setBundlesError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const deleteBundleById = bundleId => async dispatch => {
  try {
    dispatch(setBundlesLoading());

    const deletedBundle = await deleteById("bundles", bundleId);

    dispatch(removeDeletedBundle(bundleId));
    dispatch(setNotice({ message: deletedBundle.message, type: "success" }));

    return deletedBundle;
  } catch (error) {
    dispatch(setBundlesError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};
