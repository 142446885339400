import React, { useState, useEffect } from "react";
import slugify from "slugify";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Row } from "@styles/grid";
import { Span } from "@styles/typography";
import TextInput from "@components/inputs/TextInput";
import TextArea from "@components/inputs/Textarea";
import Select from "@components/inputs/Select";
import { getCollegesByState } from "@actions/colleges.action";
import { ButtonPrimary, ButtonRoundedPlus, ButtonSecondary, ButtonSecondaryLink } from "@components/Button";
import { Form, FormButtons, FormInputs } from "@styles/form";
import ChecklistViewFormProducts from "./ChecklistViewFormProducts";
import generateUUID from "@lib/generateUUID";
import { updateChecklist } from "@actions/checklist.action";
import { setNotice } from "@actions/notice.action";
import { nullIfEmpty, emptyIfNull } from "@utils/string";
import { checklistValidationSchema } from "@utils/validation";
import { getStates } from "@actions/states.action";
import GuardComponent from "@components/GuardComponent";
import { USER_ROLES } from "@constants";

const ChecklistViewForm = ({ checklist }) => {
  const [editMode, setEditMode] = useState(false);
  const [checklistStateColleges, setChecklistStateColleges] = useState([]);
  const [stateColleges, setStateColleges] = useState([]);
  const [savedChecklist, setSavedChecklist] = useState(null);
  const [school, setSchool] = useState(null);
  const [renderProducts, setRenderProducts] = useState([
    {
      key: generateUUID(),
      id: null,
      product_name: null,
      product_id: null,
      quantity: 1,
      memo: "",
      suggested: false,
    },
  ]);

  const dispatch = useDispatch();
  const me = useSelector(({ me }) => me);
  const loading = useSelector(state => state.checklists.loading);
  const states = useSelector(state => state.states.states);

  const userSchools = useSelector(({ colleges }) => colleges.collegesByState); // schools that are associated with user.role "college"

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      name: "",
      slug: "",
      description: "",
      school_id: null,
      not_bring: "",
      checklist_details: [],
      state: null,
    },
    validationSchema: checklistValidationSchema,
    onSubmit: () => {},
  });

  const state = formik.values.state;

  const setInitialState = () => {
    setStateColleges(colleges =>
      checklist.school.state_id !== savedChecklist.school.state_id ? colleges : checklistStateColleges
    );
    setSchool(savedChecklist.school);

    const savedRenderProducts = savedChecklist.checklist_details.map(({ id, product, quantity, memo, suggested }) => ({
      key: generateUUID(),
      id,
      product_id: product.id,
      product_name: product.name,
      quantity,
      memo,
      suggested,
    }));

    formik.setValues({
      name: savedChecklist.name,
      slug: savedChecklist.slug,
      description: emptyIfNull(savedChecklist.description),
      school_id: savedChecklist.school_id,
      not_bring: emptyIfNull(savedChecklist.not_bring),
      checklist_details: savedRenderProducts.map(({ product_id, quantity, memo, suggested }) => ({
        product_id,
        quantity,
        memo,
        suggested,
      })),
      state: savedChecklist.school.state_id
        ? states.find(({ id }) => id === savedChecklist.school.state_id)
        : checklist.school.state,
    });

    const renderProductsWithGenerated = [...savedRenderProducts];

    setRenderProducts([...renderProductsWithGenerated]);
  };

  useEffect(() => {
    (async () => {
      if (!states || !states.length) {
        if (me.role === USER_ROLES.college) {
          const userSchoolIds = `user_school_ids=${me.schools.join()}`;
          return await dispatch(getStates(userSchoolIds));
        }
        return await dispatch(getStates());
      }
    })();
  }, [states]);

  useEffect(() => {
    if (me.role !== USER_ROLES.college) {
      const getCollegesByStateForChecklist = async () => {
        setChecklistStateColleges(await dispatch(getCollegesByState(checklist.school.state_id)));
      };
      if (checklist) {
        setSavedChecklist(checklist);
        getCollegesByStateForChecklist();
      }
    } else {
      setSavedChecklist(checklist);
    }
  }, [checklist]);

  useEffect(() => {
    if (savedChecklist) {
      setInitialState();
      if (me.role === USER_ROLES.college) {
        setStateColleges(userSchools);
      }
    }
  }, [editMode, savedChecklist]);

  useEffect(() => {
    if (savedChecklist && formik.values.name && formik.values.name !== savedChecklist.name) {
      formik.setFieldValue("slug", slugify(formik.values.name, { lower: true }));
    }
  }, [formik.values.name]);

  const handleSubmit = async () => {
    try {
      await formik.submitForm();
      const validationErrors = await formik.validateForm(formik.values);

      if (Object.keys(validationErrors).length) {
        return;
      }

      const { name, slug, description, school_id, checklist_details, not_bring } = formik.values;
      const newChecklist = {
        name,
        slug,
        description: nullIfEmpty(description),
        school_id,
        not_bring: nullIfEmpty(not_bring),
        checklist_details,
      };

      const updatedChecklistResponse = await dispatch(updateChecklist(savedChecklist.id, newChecklist));

      setSavedChecklist(updatedChecklistResponse.data);
      setEditMode(false);
      dispatch(setNotice({ message: updatedChecklistResponse.message, type: "success" }));
      return updatedChecklistResponse.data;
    } catch (error) {
      dispatch(setNotice({ message: error.message, type: "error" }));
    }
  };

  const clearSchool = () => {
    formik.setFieldValue("school_id", null);
    setSchool(null);
  };

  const handleStateChange = async state => {
    formik.setFieldValue("state", state);
    clearSchool();
    const colleges = await dispatch(getCollegesByState(state.id));
    setStateColleges(colleges);
  };

  const handleCollegeChange = college => {
    setSchool(college);
    formik.setFieldValue("school_id", college.id);

    if (me.role === USER_ROLES.college) {
      formik.setFieldValue("state", college.state);
    }
  };

  const changeRenderProducts = (fields, key) =>
    renderProducts.map(renderProduct => {
      if (renderProduct.key !== key) {
        return { ...renderProduct };
      }

      return {
        ...renderProduct,
        ...fields,
      };
    });

  const setProductsToFormikValue = products => {
    formik.setFieldValue(
      "checklist_details",
      products
        .filter(({ product_id }) => product_id)
        .map(({ product_id, quantity, memo, suggested }) => ({ product_id, quantity, memo, suggested }))
    );

    setRenderProducts([...products]);
  };

  const onChangeProduct = (product, suggestionKey) => {
    const foundedProduct = changeRenderProducts(
      {
        product_id: product.id,
        product_name: product.name,
      },
      suggestionKey
    );

    setProductsToFormikValue(foundedProduct);
  };

  const quantityChange = (quantity, suggestionKey) => {
    const foundedProduct = changeRenderProducts({ quantity: +quantity }, suggestionKey);
    setProductsToFormikValue(foundedProduct);
  };

  const onClearProduct = suggestionKey => {
    const foundedProduct = changeRenderProducts(
      {
        product_id: null,
        product_name: null,
      },
      suggestionKey
    );

    setProductsToFormikValue(foundedProduct);
  };

  const onChangeMemo = (memo, suggestionKey) => {
    const foundedProduct = changeRenderProducts(
      {
        memo,
      },
      suggestionKey
    );

    setProductsToFormikValue(foundedProduct);
  };

  const onChangeSuggested = (suggested, suggestionKey) => {
    const foundedProduct = changeRenderProducts(
      {
        suggested,
      },
      suggestionKey
    );

    setProductsToFormikValue(foundedProduct);
  };

  const removeProduct = suggestionKey => {
    const copyOfRenderProducts = [...renderProducts];
    const foundedIndex = copyOfRenderProducts.findIndex(({ key }) => key === suggestionKey);
    copyOfRenderProducts.splice(foundedIndex, 1);

    setProductsToFormikValue(copyOfRenderProducts);
  };

  const addRenderProduct = () => {
    setRenderProducts(prevRenderProducts => [
      ...prevRenderProducts,
      {
        key: generateUUID(),
        id: null,
        product_id: null,
        product_name: null,
        quantity: 1,
        memo: "",
        suggested: false,
      },
    ]);
  };

  const handleCancel = () => {
    setEditMode(prevMode => !prevMode);

    formik.setValues({
      name: savedChecklist.name,
      slug: savedChecklist.slug,
      description: savedChecklist.description || "",
      school_id: savedChecklist.school.id,
      not_bring: savedChecklist.not_bring || "",
      checklist_details: savedChecklist.checklist_details.map(({ product_id, quantity }) => ({ product_id, quantity })),
      state: savedChecklist.school.state_id
        ? states.find(({ id }) => id === savedChecklist.school.state_id)
        : checklist.school.state,
    });
  };

  const collegeOptions =
    stateColleges && stateColleges.length
      ? stateColleges.map(college => ({ value: college.id, label: college.name, ...college }))
      : [];

  const selectedCollegeValue = school && {
    value: school.id,
    label: `${school.name}, ${school.location.split(",").join(" ")}`,
  };

  const statesOptions =
    states && states.length ? states.map(state => ({ value: state.id, label: state.name, ...state })) : [];

  const selectedStateValue = state && {
    value: state.id,
    label: state.name,
  };

  return (
    <Form>
      <FormInputs>
        <TextInput
          position
          label="Name"
          labelPosition="left"
          fluid
          placeholder={editMode ? "Checklist Name" : ""}
          isreadonly={!editMode}
          readOnly={!editMode}
          value={formik.values.name}
          id="name"
          name="name"
          formik={formik}
          onChange={formik.handleChange}
        />
        <TextInput
          position
          label="Slug"
          labelPosition="left"
          fluid
          placeholder={editMode ? "Checklist Slug" : ""}
          isreadonly={!editMode}
          readOnly={!editMode}
          value={formik.values.slug}
          id="slug"
          name="slug"
          formik={formik}
          onChange={formik.handleChange}
        />
        <TextArea
          label="Notes / Description"
          labelPosition="left-top"
          fluid
          placeholder={editMode ? "Checklist Notes / Description / What is provided by the college or university." : ""}
          isreadonly={!editMode}
          readOnly={!editMode}
          value={formik.values.description}
          id="description"
          name="description"
          formik={formik}
          onChange={formik.handleChange}
        />
        {editMode && me.role === USER_ROLES.college ? (
          <TextInput
            position
            label="State"
            labelPosition="left"
            fluid
            value={selectedStateValue && selectedStateValue.label}
            isreadonly={true}
            readOnly={true}
          />
        ) : editMode ? (
          <Select
            label="State"
            labelPosition="left"
            placeholder="Select State"
            options={statesOptions}
            value={selectedStateValue}
            onChange={handleStateChange}
            id="state"
            name="state"
            formik={formik}
            fluid
          />
        ) : (
          <TextInput
            position
            label="State"
            labelPosition="left"
            fluid
            value={selectedStateValue && selectedStateValue.label}
            isreadonly={!editMode}
            readOnly={!editMode}
          />
        )}
        {editMode ? (
          <Select
            label="School"
            labelPosition="left"
            placeholder="Select School"
            options={collegeOptions}
            value={selectedCollegeValue}
            onChange={handleCollegeChange}
            id="school_id"
            name="school_id"
            formik={formik}
            fluid
          />
        ) : (
          <TextInput
            position
            label="School"
            labelPosition="left"
            fluid
            defaultValue={
              savedChecklist && `${savedChecklist.school.name}, ${savedChecklist.school.location.split(",").join(" ")}`
            }
            isreadonly={!editMode}
            readOnly={!editMode}
          />
        )}
      </FormInputs>
      {renderProducts && renderProducts.length ? (
        <ChecklistViewFormProducts
          quantityChange={quantityChange}
          renderProducts={renderProducts}
          onChangeProduct={onChangeProduct}
          onChangeMemo={onChangeMemo}
          onChangeSuggested={onChangeSuggested}
          removeProduct={removeProduct}
          onClearProduct={onClearProduct}
          editMode={editMode}
        />
      ) : null}

      {formik.errors.checklist_details && formik.touched.checklist_details && (
        <Span color="red" align="right" size={1.4}>
          {Array.isArray(formik.errors.checklist_details)
            ? formik.errors.checklist_details[formik.errors.checklist_details.length - 1][
                Object.keys(formik.errors.checklist_details[formik.errors.checklist_details.length - 1])
              ]
            : formik.errors.checklist_details}
        </Span>
      )}

      <Row margin="3rem 0" direction="column" align="flex-end">
        {editMode && <ButtonRoundedPlus onClick={addRenderProduct} />}
      </Row>
      <TextArea
        label="Not to bring"
        labelPosition="left-top"
        fluid
        placeholder={editMode ? "Checklist Not to bring" : ""}
        isreadonly={!editMode}
        readOnly={!editMode}
        value={formik.values.not_bring}
        id="not_bring"
        name="not_bring"
        formik={formik}
        onChange={formik.handleChange}
        minRows={4}
      />
      <Row margin="3rem 0" direction="column" align="flex-end">
        <FormButtons>
          {!editMode && (
            <ButtonSecondaryLink outlined uppercase href="/checklists">
              Back to checklists
            </ButtonSecondaryLink>
          )}
          <GuardComponent roles={[USER_ROLES.admin, USER_ROLES.dataminer, USER_ROLES.college]}>
            <ButtonSecondary uppercase onClick={handleCancel}>
              {editMode ? "cancel" : "Edit"}
            </ButtonSecondary>
            {editMode && (
              <ButtonPrimary type="submit" onClick={() => handleSubmit()} uppercase disabled={loading}>
                {loading ? "Wait..." : "Save Checklist"}
              </ButtonPrimary>
            )}
          </GuardComponent>
        </FormButtons>
      </Row>
    </Form>
  );
};

export default ChecklistViewForm;
