import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "@components/Table";
import { ButtonSecondary, ButtonPrimaryLink } from "@components/Button";
import { deletePartnerProductById } from "@actions/partnerProducts.action";
import Modal from "@components/Modal";
import { Span } from "@styles/typography";
import { Row, Indent } from "@styles/grid";

const EditPartnerProduct = ({ partnerProductId }) => {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.partnerProducts.loading);

    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

    const handleDeletePartnerProduct = () => async () => {
        try {
            await dispatch(deletePartnerProductById(partnerProductId));
            setIsRemoveModalOpen(false);
        } catch (error) {
            throw new Error(error.message);
        }
    };

    return (
        <Row justify="flex-end" padding="0 2rem 0 0">
            <Indent margin="0 1.5rem 0 0">
                <ButtonPrimaryLink uppercase href={`/partnerproducts/${partnerProductId}`} outlined size="small" weight="bold">
                    edit
                </ButtonPrimaryLink>
            </Indent>
            <ButtonSecondary uppercase onClick={() => setIsRemoveModalOpen(true)} size="small" weight="bold">
                Remove
            </ButtonSecondary>

            <Modal isOpen={isRemoveModalOpen} onClose={() => setIsRemoveModalOpen(false)} title="Remove Partner Product">
                <Indent margin="4rem 0">
                    <Span size={1.8}>Are you sure you want to delete this partner product?</Span>
                </Indent>
                <Row justify="flex-end">
                    <Indent margin="0 2rem 0">
                        <ButtonSecondary outlined onClick={() => setIsRemoveModalOpen(false)} uppercase>
                            Close
                        </ButtonSecondary>
                    </Indent>
                    <ButtonSecondary autoFocus onClick={handleDeletePartnerProduct()} uppercase disabled={loading}>
                        Remove
                    </ButtonSecondary>
                </Row>
            </Modal>
        </Row>
    );
};

const PartnerProductsTable = () => {
    const { all: partnerProducts, fetching } = useSelector(({ partnerProducts }) => partnerProducts);

    const columns = useMemo(
        () => [
            {
                Header: "Partner_Name",
                accessor: "partner.name",
                isFiltered: true,
                isSorting: true,
            },
            {
                Header: "Product_Name",
                accessor: "product.name",
                isFiltered: true,
                isSorting: true,
            },
            {
                Header: "Partner_Category",
                accessor: "partner_category.name",
                isFiltered: true,
                isSorting: true,
            },
            {
                Header: "UPC",
                accessor: "upc",
                isFiltered: true,
                isSorting: true,
            },
            {
                Header: "Name_Alias",
                accessor: "name_alias",
                isFiltered: true,
                isSorting: true,
            },

            {
                Header: "Quantity",
                accessor: "quantity",
                isFiltered: true,
                isSorting: true,
            },
            {
                Header: "Order",
                accessor: "order",
                isFiltered: true,
                isSorting: true,
            },
            {
                id: "colEdit",
                Cell: ({ row }) => <EditPartnerProduct partnerProductId={row.original.id} />,
            },
        ],
        []
    );

    const data = useMemo(() => partnerProducts, [partnerProducts]);

    return <Table columns={columns} data={data} hasGlobalFilter loading={fetching} />;
};

export default PartnerProductsTable;
