import { get, post, put, deleteById } from "@api";
import {
  SET_PARTNERS,
  SET_PARTNERS_ERROR,
  SET_PARTNERS_LOADING,
  SET_UPDATED_PARTNER,
  SET_CREATED_PARTNER,
  REMOVE_DELETED_PARTNER,
  SET_PARTNERS_FETCHING,
} from "../types/partners.types";
import { setNotice } from "@actions/notice.action";

export const setPartnersLoading = () => dispatch =>
  dispatch({
    type: SET_PARTNERS_LOADING,
  });

export const setPartnersFetching = () => dispatch =>
  dispatch({
    type: SET_PARTNERS_FETCHING,
  });

export const setPartners = partners => dispatch =>
  dispatch({
    type: SET_PARTNERS,
    partners,
  });

export const setCreatedPartner = partner => dispatch =>
  dispatch({
    type: SET_CREATED_PARTNER,
    partner,
  });

export const setUpdatedPartner = updatedPartner => dispatch =>
  dispatch({
    type: SET_UPDATED_PARTNER,
    updatedPartner,
  });

export const removeDeletedPartner = partnerId => dispatch =>
  dispatch({
    type: REMOVE_DELETED_PARTNER,
    partnerId,
  });

export const setPartnersError = error => dispatch =>
  dispatch({
    type: SET_PARTNERS_ERROR,
    error,
  });

export const getAllPartners = (include = "") => async dispatch => {
  try {
    dispatch(setPartnersFetching());

    const partners = await get(`/partners?include=${include}`);

    dispatch(setPartners(partners));

    return partners;
  } catch (error) {
    dispatch(setPartnersError(error.message));
  }
};

export const getPartnerById = (partnerId, include = "") => async dispatch => {
  try {
    const partner = await get(`/partners/${partnerId}?include=${include}`);

    return {
      ...partner,
      iframe: {
        ...partner.iframe,
        walmart_button: Boolean(partner.iframe.walmart_button),
        amazon_button: Boolean(partner.iframe.amazon_button),
        with_ccl_brand: Boolean(partner.iframe.with_ccl_brand),
      },
    };
  } catch (error) {
    dispatch(setPartnersError(error.message));
  }
};

export const createPartner = partner => async dispatch => {
  try {
    dispatch(setPartnersLoading());

    const createdPartner = await post("partners", partner);

    dispatch(setCreatedPartner(createdPartner.data));
    dispatch(setNotice({ message: createdPartner.message, type: "success" }));

    return createdPartner;
  } catch (error) {
    dispatch(setPartnersError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const updatePartner = (partnerId, partner) => async dispatch => {
  try {
    dispatch(setPartnersLoading());

    const updatedPartner = await put(`partners/${partnerId}`, partner);
    dispatch(setUpdatedPartner(updatedPartner.data));
    dispatch(setNotice({ message: updatedPartner.message, type: "success" }));

    return updatedPartner;
  } catch (error) {
    dispatch(setPartnersError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const deletePartnerById = partnerId => async dispatch => {
  try {
    dispatch(setPartnersLoading());

    const deletedPartner = await deleteById("partners", partnerId);

    dispatch(removeDeletedPartner(partnerId));
    dispatch(setNotice({ message: deletedPartner.message, type: "success" }));

    return deletedPartner;
  } catch (error) {
    dispatch(setPartnersError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};
