import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "@components/Table";
import { ButtonSecondary, ButtonPrimaryLink } from "@components/Button";
import Modal from "@components/Modal";
import { Span } from "@styles/typography";
import { Row, Indent } from "@styles/grid";
import { deleteRoleById } from "@actions/roles.action";

const EditRole = ({ roleId }) => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.roles.loading);

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const handleDeleteRole = () => async () => {
    try {
      await dispatch(deleteRoleById(roleId));
      setIsRemoveModalOpen(false);
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return (
    <Row justify="flex-end" padding="0 2rem 0 0">
      <Indent margin="0 1.5rem 0 0">
        <ButtonPrimaryLink uppercase href={`/roles/${roleId}`} outlined size="small" weight="bold">
          edit
        </ButtonPrimaryLink>
      </Indent>
      <ButtonSecondary uppercase onClick={() => setIsRemoveModalOpen(true)} size="small" weight="bold">
        Remove
      </ButtonSecondary>

      <Modal isOpen={isRemoveModalOpen} onClose={() => setIsRemoveModalOpen(false)} title="Remove Role">
        <Indent margin="4rem 0">
          <Span size={1.8}>Are you sure you want to delete this role?</Span>
        </Indent>
        <Row justify="flex-end">
          <Indent margin="0 2rem 0">
            <ButtonSecondary outlined onClick={() => setIsRemoveModalOpen(false)} uppercase>
              Close
            </ButtonSecondary>
          </Indent>
          <ButtonSecondary autoFocus onClick={handleDeleteRole()} uppercase disabled={loading}>
            Remove
          </ButtonSecondary>
        </Row>
      </Modal>
    </Row>
  );
};

const RolesTable = () => {
  const { roles, fetching } = useSelector(({ roles }) => roles);
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        isFiltered: true,
        isSorting: true,
      },
      {
        Header: "Can create",
        accessor: "can_create",
        isFiltered: false,
        isSorting: false,
      },
      {
        Header: "Can edit",
        accessor: "can_edit",
        isFiltered: false,
        isSorting: false,
      },
      {
        Header: "Can access api",
        accessor: "can_access_api",
        isFiltered: false,
        isSorting: false,
      },
      {
        Header: "Can access admin",
        accessor: "can_access_admin",
        isFiltered: false,
        isSorting: false,
      },

      {
        id: "colEdit",
        Cell: ({ row }) => <EditRole roleId={row.original.id} />,
      },
    ],
    []
  );

  const data = useMemo(() => roles, [roles]);

  return <Table columns={columns} data={data} hasGlobalFilter loading={fetching} />;
};

export default RolesTable;
