import { SET_ME, CLEAR_ME } from "../types/me.types";

export const setMe = me => dispatch =>
  dispatch({
    type: SET_ME,
    me,
  });

export const clearMe = () => dispatch =>
  dispatch({
    type: CLEAR_ME,
  });
