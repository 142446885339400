import { get, post, put, deleteById } from "@api";
import {
  REMOVE_DELETED_BUCKET,
  SET_CREATED_BUCKET,
  SET_BUCKETS,
  SET_BUCKETS_FETCHING,
  SET_BUCKETS_LOADING,
  SET_BUCKET_ERROR,
  SET_UPDATED_BUCKET,
} from "../types/buckets.types";

import { setNotice } from "./notice.action";

export const setBucketsLoading = () => dispatch =>
  dispatch({
    type: SET_BUCKETS_LOADING,
  });

export const setBucketsFetching = () => dispatch =>
  dispatch({
    type: SET_BUCKETS_FETCHING,
  });

export const setBuckets = buckets => dispatch =>
  dispatch({
    type: SET_BUCKETS,
    buckets,
  });

export const setCreatedBucket = bucket => dispatch =>
  dispatch({
    type: SET_CREATED_BUCKET,
    bucket,
  });

export const setUpdatedBucket = updatedBucket => dispatch =>
  dispatch({
    type: SET_UPDATED_BUCKET,
    updatedBucket,
  });

export const removeDeletedBucket = bucketId => dispatch =>
  dispatch({
    type: REMOVE_DELETED_BUCKET,
    bucketId,
  });

export const setBucketsError = error => dispatch =>
  dispatch({
    type: SET_BUCKET_ERROR,
    error,
  });

export const getAllBuckets = (include = "") => async dispatch => {
  try {
    dispatch(setBucketsFetching());
    const buckets = await get(`/buckets?include=${include}&initial=true`);

    dispatch(setBuckets(buckets));

    return buckets;
  } catch (error) {
    dispatch(setBucketsError(error.message));
  }
};

export const getBucketById = (bucketId, include = "") => async dispatch => {
  try {
    const bucket = await get(`/buckets/${bucketId}?include=${include}`);

    return bucket;
  } catch (error) {
    dispatch(setBucketsError(error.message));
  }
};

export const createBucket = bucket => async dispatch => {
  try {
    dispatch(setBucketsLoading());

    const createdBucket = await post("buckets", bucket);

    dispatch(setCreatedBucket(createdBucket.data));
    dispatch(setNotice({ message: createdBucket.message, type: "success" }));

    return createdBucket;
  } catch (error) {
    dispatch(setBucketsError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const updateBucket = (bucketId, bucket) => async dispatch => {
  try {
    dispatch(setBucketsLoading());

    const updatedBucket = await put(`buckets/${bucketId}`, bucket);

    dispatch(setUpdatedBucket(updatedBucket.data));
    dispatch(setNotice({ message: updatedBucket.message, type: "success" }));
    return updatedBucket;
  } catch (error) {
    dispatch(setBucketsError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const deleteBucketById = bucketId => async dispatch => {
  try {
    dispatch(setBucketsLoading());

    const deletedBucket = await deleteById("buckets", bucketId);

    dispatch(removeDeletedBucket(bucketId));
    dispatch(setNotice({ message: deletedBucket.message, type: "success" }));

    return deletedBucket;
  } catch (error) {
    dispatch(setBucketsError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const getBucketsByName = (name, selected) => async () => {
  try {
    const selectedBuckets = selected && selected.length ? `&selected=${selected.join(",")}` : "";

    const buckets = await get(`buckets/search/q?name=${name}${selectedBuckets}`);

    return buckets;
  } catch (error) {
    console.error(error.message);
  }
};
