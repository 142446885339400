import { get, post, put, deleteById } from "@api";
import moment from "moment";
import {
  REMOVE_API_APPLICATION,
  SET_API_APPLICATIONS,
  SET_API_APPLICATIONS_FETCHING,
  SET_API_APPLICATIONS_LOADING,
  SET_API_APPLICATION_ERROR,
  SET_CREATED_API_APPLICATION,
  SET_UPDATED_API_APPLICATION,
} from "../types/apiApplications.types";

import { setNotice } from "./notice.action";

export const setApiApplicationsLoading = () => dispatch =>
  dispatch({
    type: SET_API_APPLICATIONS_LOADING,
  });

export const setApiApplicationsFetching = () => dispatch =>
  dispatch({
    type: SET_API_APPLICATIONS_FETCHING,
  });

export const setApiApplications = apiApplications => dispatch =>
  dispatch({
    type: SET_API_APPLICATIONS,
    apiApplications,
  });

export const setCreatedApiApplication = apiApplication => dispatch =>
  dispatch({
    type: SET_CREATED_API_APPLICATION,
    apiApplication,
  });

export const setUpdatedApiApplication = updatedApiApplication => dispatch =>
  dispatch({
    type: SET_UPDATED_API_APPLICATION,
    updatedApiApplication,
  });

export const removeApiApplication = apiApplicationId => dispatch =>
  dispatch({
    type: REMOVE_API_APPLICATION,
    apiApplicationId,
  });

export const setApiApplicationsError = error => dispatch =>
  dispatch({
    type: SET_API_APPLICATION_ERROR,
    error,
  });

export const getAllApiApplications = (include = "") => async dispatch => {
  try {
    dispatch(setApiApplicationsFetching());
    const response = await get(`/api-applications?include=${include}`);
    const apiApplications = response.map(apiApplication => ({
      ...apiApplication,
      created: moment(apiApplication.created).format("LLL"),
      modified: moment(apiApplication.modified).format("LLL"),
    }));

    dispatch(setApiApplications(apiApplications));

    return apiApplications;
  } catch (error) {
    dispatch(setApiApplicationsError(error.message));
  }
};

export const getApiApplicationById = (apiApplicationId, include = "") => async dispatch => {
  try {
    const apiApplication = await get(`/api-applications/${apiApplicationId}/find?include=${include}`);

    return apiApplication;
  } catch (error) {
    dispatch(setApiApplicationsError(error.message));
  }
};

export const createApiApplication = apiApplication => async dispatch => {
  try {
    dispatch(setApiApplicationsLoading());

    const response = await post("api-applications", apiApplication);
    const createdApiApplication = {
      ...response,
      data: {
        ...response.data,
        created: moment(response.data.created).format("LLL"),
        modified: moment(response.data.modified).format("LLL"),
      },
    };
    dispatch(setCreatedApiApplication(createdApiApplication.data));
    dispatch(setNotice({ message: createdApiApplication.message, type: "success" }));

    return createdApiApplication;
  } catch (error) {
    dispatch(setApiApplicationsError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const updateApiApplication = (apiApplicationId, apiApplication) => async dispatch => {
  try {
    dispatch(setApiApplicationsLoading());

    const response = await put(`api-applications/${apiApplicationId}`, apiApplication);

    const updatedApiApplication = {
      ...response,
      data: {
        ...response.data,
        created: moment(response.data.created).format("LLL"),
        modified: moment(response.data.modified).format("LLL"),
      },
    };

    dispatch(setUpdatedApiApplication(updatedApiApplication.data));
    dispatch(setNotice({ message: updatedApiApplication.message, type: "success" }));

    return updatedApiApplication;
  } catch (error) {
    dispatch(setApiApplicationsError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const deleteApiApplicationById = apiApplicationId => async dispatch => {
  try {
    dispatch(setApiApplicationsLoading());

    const deletedApiApplication = await deleteById("api-applications", apiApplicationId);

    dispatch(removeApiApplication(apiApplicationId));
    dispatch(setNotice({ message: deletedApiApplication.message, type: "success" }));

    return deletedApiApplication;
  } catch (error) {
    dispatch(setApiApplicationsError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const getApiApplicationsByName = (name, selected) => async () => {
  try {
    const selectedApiApplications = selected && selected.length ? `&selected=${selected.join(",")}` : "";

    const apiApplications = await get(`api-applications/search/q?name=${name}${selectedApiApplications}`);

    return apiApplications;
  } catch (error) {
    console.error(error.message);
  }
};
