import { AUTH_LOGOUT } from "../types/auth.types";
import {
  SET_CATEGORIES,
  SET_CATEGORIES_ERROR,
  SET_CATEGORIES_LOADING,
  SET_CREATED_CATEGORY,
  SET_UPDATED_CATEGORY,
  REMOVE_DELETED_CATEGORY,
  SET_CATEGORIES_FETCHING,
} from "../types/categories.types";

const initialState = {
  categories: [],
  loading: false,
  fetching: false,
  error: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
        fetching: false,
      };
    case SET_CREATED_CATEGORY:
      return {
        ...state,
        categories: state.categories && state.categories.length ? [...state.categories, action.category] : [],
        loading: false,
        error: "",
      };
    case SET_UPDATED_CATEGORY: {
      const copyOfCategories = [...state.categories];
      const foundedCategoryIndex = copyOfCategories.findIndex(({ id }) => id === action.updatedCategory.id);
      copyOfCategories[foundedCategoryIndex] = action.updatedCategory;

      return {
        ...state,
        categories: [...copyOfCategories],
        loading: false,
        error: "",
      };
    }
    case REMOVE_DELETED_CATEGORY: {
      const copyOfCategories = [...state.categories];
      const foundedCategoryIndex = copyOfCategories.findIndex(({ id }) => id === action.categoryId);
      copyOfCategories.splice(foundedCategoryIndex, 1);

      return {
        ...state,
        categories: [...copyOfCategories],
        loading: false,
        error: "",
      };
    }
    case SET_CATEGORIES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_CATEGORIES_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case SET_CATEGORIES_ERROR:
      return {
        ...state,
        error: Array.isArray(action.error) ? action.error[0] : action.error,
        loading: false,
      };
    case AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
