import React, { memo, useState, useEffect } from "react";
import styled from "styled-components";

import theme from "@styles";
import { Span } from "@styles/typography";
import { Row } from "@styles/grid";
import { useDispatch } from "react-redux";
import { removeSponsorshipChecklistDetail } from "@actions/checklistDetails.action";

const Wrapper = styled.div`
  width: 100%;
  margin: 4rem auto 2rem;
`;

const ProductWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 0;
  margin-left: auto;
`;

const ProductPosition = styled.div`
  width: 3rem;
  margin-right: 2rem;
`;

const ProductButton = styled.button`
  width: min-content;
  height: 3rem;
  border: 1px solid ${({ buttonColor }) => buttonColor};
  border-radius: 5px;
  background-color: ${({ buttonColor }) => buttonColor};
  color: white;
  margin: ${({ margin }) => margin};
  padding: 0 1.5rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.42857143;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;

  &:hover {
    opacity: 0.8;
  }
`;

const SponsorshipProduct = memo(({ product, index, selectedProducts }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const dispatch = useDispatch();
  const editMode = false;

  useEffect(() => {
    if (product.product_id && selectedProducts.includes(product.product_id)) {
      setSelectedProduct(product);
    }
  }, [selectedProducts, product]);

  return (
    <ProductWrapper editMode={editMode}>
      <ProductPosition>
        #
        <Span size={1.6} weight="bold">
          {index + 1}
        </Span>
      </ProductPosition>

      <Row align="center" padding="0 3rem">
        <Span size={1.6}>{selectedProduct && selectedProduct.product.name}</Span>
      </Row>
      <Row fluid>
        <ProductButton
          buttonColor={theme.colorRed}
          onClick={() => dispatch(removeSponsorshipChecklistDetail(selectedProduct.id))}>
          Remove
        </ProductButton>
      </Row>
    </ProductWrapper>
  );
});

const SponsorshipProductsList = ({
  renderProducts,
  quantityChange,
  onChangeProduct,
  onChangeMemo,
  onChangeSuggested,
  removeProduct,
  onClearProduct,
}) => {
  const selectedProducts = renderProducts.filter(({ product_id }) => product_id).map(({ product_id }) => product_id);

  return (
    <Wrapper>
      {renderProducts.map((detail, index) => (
        <SponsorshipProduct
          key={index}
          index={index}
          product={detail}
          quantityChange={quantityChange}
          onChangeMemo={onChangeMemo}
          onChangeSuggested={onChangeSuggested}
          onChangeProduct={onChangeProduct}
          removeProduct={removeProduct}
          onClearProduct={onClearProduct}
          selectedProducts={selectedProducts}
        />
      ))}
    </Wrapper>
  );
};

export default SponsorshipProductsList;
