import React, { memo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import theme from "@styles";
import { Span } from "@styles/typography";
import { Row, Indent } from "@styles/grid";
import { ButtonRemoveRounded } from "@components/Button";
import NumberInput from "@components/inputs/Number";
import AutocompleteInput from "@components/inputs/AutocompleteInput";
import Textarea from "@components/inputs/Textarea";
import Checkbox from "@components/inputs/Checkbox";
import { getProductsByName } from "@actions/products.action";
import { setNotice } from "@actions/notice.action";
import { debounce } from "@utils/func";

const Wrapper = styled.div`
  flex: 1;
  margin: 5rem -6rem 0 auto;
  max-width: 81rem;
  width: 100%;
`;

const Title = styled.div`
  padding-right: 2.5rem;
`;

const ProductWrapper = styled.div`
  position: relative;
  max-width: 79rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 1.5rem 0;
  margin-left: auto;
`;

const ProductPosition = styled.div`
  position: absolute;
  left: -5rem;
  top: 0.5rem;
`;

const ProductInput = styled.div`
  flex: 1;
  margin: 0 3rem 1.5rem;
`;

const getSuggestionProductValue = suggestion => suggestion.name;

const AddProduct = memo(
  ({
    product,
    index,
    quantityChange,
    onChangeProduct,
    onChangeMemo,
    onChangeSuggested,
    removeProduct,
    onClearProduct,
    selectedProducts,
  }) => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [productValue, setProductValue] = useState("");
    const [products, setProducts] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
      if (product.product_id && selectedProducts.includes(product.product_id)) {
        setSelectedProduct(product);
      }
    }, [selectedProducts, product]);

    const loadProductsByName = async value => {
      const foundedProducts = await dispatch(getProductsByName(value, selectedProducts));

      setProducts(foundedProducts && foundedProducts.length ? [...foundedProducts] : []);
    };

    const debouncedProductFetchRequested = debounce(loadProductsByName, 300);

    const onProductFetchRequested = ({ value }) => {
      debouncedProductFetchRequested(value);
    };

    const onProductClearRequested = () => setProducts([]);

    const handleChangeProduct = (event, { newValue }) => {
      setProductValue(newValue);

      if (selectedProduct && newValue.length !== selectedProduct.product_name.length) {
        onClearProduct(selectedProduct.key);
        setSelectedProduct(null);
      }
    };

    const handleSelectProduct = returnedProduct => {
      const foundedProduct = selectedProducts.findIndex(id => returnedProduct.id === id);

      if (foundedProduct !== -1) {
        setProductValue("");
        setSelectedProduct(null);
        return dispatch(
          setNotice({ type: "error", message: `This product already selected at ${foundedProduct + 1} position` })
        );
      }

      onChangeProduct(returnedProduct, product.key);
    };

    return (
      <>
        <ProductWrapper>
          <ProductPosition>
            #
            <Span size={1.6} weight="bold">
              {index + 1}
            </Span>
          </ProductPosition>
          <NumberInput
            defaultValue={product.quantity}
            onChange={event => quantityChange(event.target.value, product.key)}
          />
          <Row direction="column">
            <ProductInput>
              <AutocompleteInput
                fluid
                inputProps={{
                  placeholder: "Start typing product name",
                  value: productValue
                    ? productValue
                    : selectedProduct && selectedProduct.product_name
                    ? selectedProduct.product_name
                    : "",
                  onChange: handleChangeProduct,
                }}
                getSuggestionValue={getSuggestionProductValue}
                fetchRequested={onProductFetchRequested}
                clearRequested={onProductClearRequested}
                onSuggestionSelected={(event, { suggestion }) => handleSelectProduct(suggestion, product.key)}
                options={products}
                borderColor={selectedProduct && theme.colorGreen}
              />
            </ProductInput>
            <ProductInput>
              <Textarea
                fluid
                placeholder="Memo for product"
                defaultValue={(selectedProduct && selectedProduct.memo) || ""}
                onChange={event => onChangeMemo(event.target.value, product.key)}
                minRows={2}
              />
            </ProductInput>
            <Indent margin="0 3rem 2.5rem auto">
              <Checkbox
                label="Suggested"
                name="suggested"
                id="suggested"
                labelFontSize={1.2}
                value={selectedProduct ? selectedProduct.suggested : ""}
                checked={selectedProduct ? selectedProduct.suggested : product.suggested}
                onClick={event => onChangeSuggested(event.target.checked, product.key)}
              />
            </Indent>
          </Row>
          <ButtonRemoveRounded size="small" weight="bold" onClick={() => removeProduct(product.key)} />
        </ProductWrapper>
      </>
    );
  }
);

const ChecklistAddFormProducts = ({
  renderProducts,
  quantityChange,
  onChangeProduct,
  onChangeMemo,
  onChangeSuggested,
  removeProduct,
  onClearProduct,
}) => {
  const selectedProducts = renderProducts.filter(({ product_id }) => product_id).map(({ product_id }) => product_id);

  return (
    <Wrapper margin="5rem 0" justify="space-between" align="center">
      <Row>
        <Title>
          <Span size={1.6} weight={500} color="#303032">
            Quantity
          </Span>
        </Title>
        <Span size={1.6} weight={500} color="#303032">
          Product
        </Span>
      </Row>
      {renderProducts.map((product, index) => (
        <AddProduct
          key={product.key}
          index={index}
          product={product}
          quantityChange={quantityChange}
          onChangeMemo={onChangeMemo}
          onChangeSuggested={onChangeSuggested}
          onChangeProduct={onChangeProduct}
          removeProduct={removeProduct}
          onClearProduct={onClearProduct}
          selectedProducts={selectedProducts}
        />
      ))}
    </Wrapper>
  );
};

export default ChecklistAddFormProducts;
