import React from "react";
import styled from "styled-components";

import { ButtonPrimaryLink, ButtonSecondaryLink } from "@components/Button";
import Layout from "@components/Layout";

import { Row } from "@styles/grid";

import AdminChecklistsTable from "./AdminChecklistsTable";
import GuardComponent from "@components/GuardComponent";
import { USER_ROLES } from "@constants";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem auto 5.5rem;
  max-width: 125rem;
  width: 100%;
  padding: 0 2.5rem;
`;

const AdminChecklists = () => (
  <Layout pageTitle="Checklists">
    <Content>
      <Row justify="space-between" margin="2rem 0">
        <ButtonSecondaryLink outlined uppercase href="/">
          Back to Dashboard
        </ButtonSecondaryLink>
        <GuardComponent roles={[USER_ROLES.admin, USER_ROLES.college, USER_ROLES.dataminer]}>
          <ButtonPrimaryLink uppercase href="/checklists/add">
            Add checklist
          </ButtonPrimaryLink>
        </GuardComponent>
      </Row>
      <AdminChecklistsTable />
    </Content>
  </Layout>
);

export default AdminChecklists;
