import { AUTH_LOGOUT } from "../types/auth.types";
import {
  SET_COLLEGES,
  SET_CREATED_SCHOOL,
  SET_SCHOOL_LOADING,
  SET_UPDATED_SCHOOL,
  SET_COLLEGES_BY_STATE,
  DELETE_SCHOOL,
  SET_SELECTED_COLLEGE,
  SET_COLLEGE_ERROR,
  SET_SCHOOL_FETCHING,
  DELETE_LOADING,
} from "../types/colleges.types";

const initialState = {
  colleges: [],
  collegesByState: [],
  loading: false,
  fetching: false,
  selected: null,
  error: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COLLEGES:
      return {
        ...state,
        colleges: action.colleges,
        fetching: false,
      };
    case SET_COLLEGES_BY_STATE:
      return {
        ...state,
        collegesByState: action.colleges,
      };
    case SET_SELECTED_COLLEGE:
      return {
        ...state,
        selected: action.college,
      };
    case SET_CREATED_SCHOOL:
      return {
        ...state,
        colleges: state.colleges && state.colleges.length ? [...state.colleges, action.school] : [],
        loading: false,
        error: "",
      };
    case SET_UPDATED_SCHOOL: {
      const copyOfSchools = [...state.colleges];
      const foundedSchoolIndex = copyOfSchools.findIndex(({ id }) => id === action.updatedSchool.id);
      copyOfSchools[foundedSchoolIndex] = action.updatedSchool;

      return {
        ...state,
        colleges: [...copyOfSchools],
        loading: false,
        error: "",
      };
    }
    case DELETE_SCHOOL: {
      const copyOfSchools = [...state.colleges];
      const foundedSchoolIndex = copyOfSchools.findIndex(({ id }) => id === action.schoolId);
      copyOfSchools.splice(foundedSchoolIndex, 1);

      return {
        ...state,
        colleges: [...copyOfSchools],
        error: "",
        loading: false,
      };
    }
    case SET_COLLEGE_ERROR:
      return {
        ...state,
        error: Array.isArray(action.error) ? action.error[0] : action.error,
        loading: false,
      };
    case SET_SCHOOL_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_SCHOOL_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case DELETE_LOADING:
      return {
        ...state,
        loading: false,
        fetching: false,
      };
    case AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
