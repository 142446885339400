import styled from "styled-components";
import theme from "@styles";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 3.5rem;
`;

export const MenuWrapper = styled.div`
  position: relative;
`;

export const MenuTitle = styled.div`
  padding: 0.7rem 2.5rem;
  background-color: white;
  border-radius: 4px;
  border-bottom: 1px solid ${theme.colorBorder};
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    border-bottom: 1px solid black;
  }
`;

export const UserDropdown = styled.div`
  position: absolute;
  width: 100%;
  bottom: -4.5rem;
  padding: 0.7rem 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: white;
  box-shadow: 0px 1px 4px ${theme.colorShadow};
`;

export const DropdownItem = styled.div`
  padding: 0.7rem 1.5rem 0.7rem 1rem;
  background-color: white;
  transition: background-color 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colorBlue200};
  }
`;
