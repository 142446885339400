import styled from "styled-components";

import Layout from "@components/Layout";
import SchoolPartnersViewForm from "./SchoolPartnersViewForm";

import { Container } from "@styles/grid";

import { getSchoolPartnersById } from "@actions/schoolPartners.action";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 5.5rem 5.5rem;
`;

const SchoolsPartnersView = () => {
  const [schoolPartners, setSchoolPartners] = useState(null);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    (async () => {
      setSchoolPartners(await dispatch(getSchoolPartnersById(params.id, "school,partner,checklist")));
    })();
  }, [params.id]);

  return (
    <Layout pageTitle={schoolPartners ? schoolPartners.partner_school_id : "School Partners"}>
      <Container>
        <Content>
          <SchoolPartnersViewForm schoolPartners={schoolPartners} />
        </Content>
      </Container>
    </Layout>
  );
};

export default SchoolsPartnersView;
