import styled from "styled-components";

export const Wrapper = styled.div`
  flex: ${({ fluid }) => fluid && 1};

  .container {
    width: 100%;
    background-color: white;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    padding: 1rem;
    font-size: 1.4rem;
    box-sizing: border-box;
    resize: none;

    &:disabled {
      background-color: #f7f7f7;
    }
  }
`;

export const Suggestion = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #f7f7f7;
  cursor: pointer;
  transition: all 0.2s easy;
  width: 100%;
`;
