import {
  REMOVE_API_APPLICATION,
  SET_API_APPLICATIONS,
  SET_API_APPLICATIONS_FETCHING,
  SET_API_APPLICATIONS_LOADING,
  SET_API_APPLICATION_ERROR,
  SET_CREATED_API_APPLICATION,
  SET_UPDATED_API_APPLICATION,
} from "../types/apiApplications.types";
import { AUTH_LOGOUT } from "../types/auth.types";

const initialState = {
  apiApplications: [],
  loading: false,
  fetching: false,
  error: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_API_APPLICATIONS:
      return {
        ...state,
        apiApplications: action.apiApplications,
        fetching: false,
      };
    case SET_CREATED_API_APPLICATION:
      return {
        ...state,
        apiApplications: [...state.apiApplications, action.apiApplication],
        loading: false,
        error: "",
      };
    case SET_UPDATED_API_APPLICATION: {
      const copyOfApiApplications = [...state.apiApplications];
      const foundedApiApplicationIndex = copyOfApiApplications.findIndex(
        ({ id }) => id === action.updatedApiApplication.id
      );
      copyOfApiApplications[foundedApiApplicationIndex] = action.updatedApiApplication;

      return {
        ...state,
        apiApplications: [...copyOfApiApplications],
        loading: false,
        error: "",
      };
    }
    case REMOVE_API_APPLICATION: {
      return {
        ...state,
        apiApplications: state.apiApplications.filter(apiApplication => apiApplication.id !== action.apiApplicationId),
        loading: false,
        error: "",
      };
    }
    case SET_API_APPLICATIONS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_API_APPLICATIONS_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case SET_API_APPLICATION_ERROR:
      return {
        ...state,
        error: Array.isArray(action.error) ? action.error[0] : action.error,
        loading: false,
      };
    case AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
