import styled from "styled-components";

import { ButtonPrimaryLink, ButtonSecondaryLink } from "@components/Button";
import Layout from "@components/Layout";

import { Row } from "@styles/grid";

import UsersTable from "./UsersTable";
import { getAllUsers } from "@actions/users.action";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect } from "react";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem auto 5.5rem;
  max-width: 125rem;
  width: 100%;
  padding: 0 2.5rem;
`;

const Users = () => {
  const { all } = useSelector(({ users }) => users);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (!all || !all.length) {
        await dispatch(getAllUsers());
      }
    })();
  }, [all]);

  return (
    <Layout pageTitle="Users">
      <Content>
        <Row justify="space-between" margin="2rem 0">
          <ButtonSecondaryLink outlined uppercase href="/">
            Back to Dashboard
          </ButtonSecondaryLink>
          <ButtonPrimaryLink uppercase href="/users/add">
            Add user
          </ButtonPrimaryLink>
        </Row>
        <UsersTable />
      </Content>
    </Layout>
  );
};

export default Users;
