const timestamp = () => {
  let now = new Date();
  return now.getDate() + now.getMilliseconds();
};

const s4 = () => {
  return Math.floor((timestamp() + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
};

const generateUUID = () => {
  return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
};

export default generateUUID;
