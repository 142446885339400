import React from "react";
import { useDispatch } from "react-redux";
import { Wrapper, FileInput, ImageWrapper, Image, InputLabel } from "./ImageUploader.styled";
import { setNotice } from "@actions/notice.action";
import Loader from "../Loader";
import { ButtonSecondary } from "@components/Button";

const ImageUploader = ({
  width,
  height,
  imageSrc,
  loading,
  title,
  onChange,
  onRemove,
  name,
  maxSize,
  editMode = false,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const maxSizeToMB = maxSize * 1024 * 1024;

  const handleChange = event => {
    event.persist();
    const image = event.target.files[0];

    if (image.size >= maxSizeToMB) {
      dispatch(setNotice({ message: `Your image is too big. Please, select less than ${maxSize}mb`, type: "error" }));
      onRemove();
      return;
    }

    onChange(image);
  };

  return (
    <Wrapper>
      {editMode && (
        <>
          <FileInput type="file" accept="image/*" onChange={handleChange} name={name} {...otherProps} />
          <InputLabel htmlFor={name}>{title}</InputLabel>
        </>
      )}
      <ImageWrapper width={width} height={height}>
        {loading ? <Loader /> : imageSrc ? <Image src={imageSrc} /> : null}
      </ImageWrapper>
      {imageSrc && editMode ? (
        <ButtonSecondary type="button" outlined uppercase onClick={onRemove}>
          Remove Image
        </ButtonSecondary>
      ) : null}
    </Wrapper>
  );
};

export default ImageUploader;
