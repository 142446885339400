import React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { ButtonPrimaryLink, ButtonSecondaryLink } from "@components/Button";
import Layout from "@components/Layout";
import { Row } from "@styles/grid";
import { useSelector, useDispatch } from "react-redux";
import PartnerProductsTable from "./PartnerProductsTable";
import { getAllPartnerProducts } from "@actions/partnerProducts.action";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem auto 5.5rem;
  max-width: 125rem;
  width: 100%;
  padding: 0 2.5rem;
`;

const PartnerProducts = () => {
    const { all: partnerProducts } = useSelector(({ partnerProducts }) => partnerProducts);
    const dispatch = useDispatch();
    useEffect(() => {
        (async () => {
            if (!partnerProducts || !partnerProducts.length) {
                await dispatch(getAllPartnerProducts);
            }
        })();
    }, [partnerProducts]);

    return (
        <Layout pageTitle="partnerproducts">
            <Content>
                <Row justify="space-between" margin="2rem 0">
                    <ButtonSecondaryLink outlined uppercase href="/">
                        Back to Dashboard
                    </ButtonSecondaryLink>
                    <ButtonPrimaryLink uppercase href="/partnerproducts/add">
                        Add Partner Product
                    </ButtonPrimaryLink>
                </Row>
                <PartnerProductsTable />
            </Content>
        </Layout>
    );
};

export default PartnerProducts;
