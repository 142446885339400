import { AUTH_LOGOUT } from "../types/auth.types";
import {
  REMOVE_DELETED_BUCKET,
  SET_CREATED_BUCKET,
  SET_BUCKETS,
  SET_BUCKETS_FETCHING,
  SET_BUCKETS_LOADING,
  SET_BUCKET_ERROR,
  SET_UPDATED_BUCKET,
} from "../types/buckets.types";

const initialState = {
  buckets: [],
  loading: false,
  fetching: false,
  error: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BUCKETS:
      return {
        ...state,
        buckets: action.buckets,
        fetching: false,
      };
    case SET_CREATED_BUCKET:
      return {
        ...state,
        buckets: [...state.buckets, action.bucket],
        loading: false,
        error: "",
      };
    case SET_UPDATED_BUCKET: {
      const copyOfBuckets = [...state.buckets];
      const foundedBucketIndex = copyOfBuckets.findIndex(({ id }) => id === action.updatedBucket.id);
      copyOfBuckets[foundedBucketIndex] = action.updatedBucket;

      return {
        ...state,
        buckets: [...copyOfBuckets],
        loading: false,
        error: "",
      };
    }
    case REMOVE_DELETED_BUCKET: {
      return {
        ...state,
        buckets: state.buckets.filter(bucket => bucket.id !== action.bucketId),
        loading: false,
        error: "",
      };
    }
    case SET_BUCKETS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_BUCKETS_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case SET_BUCKET_ERROR:
      return {
        ...state,
        error: Array.isArray(action.error) ? action.error[0] : action.error,
        loading: false,
      };
    case AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
