import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import useComponentVisible from "@hooks/useComponentVisible";
import { logout } from "@actions/auth.action";

import { Wrapper, MenuWrapper, MenuTitle, UserDropdown, DropdownItem } from "./UserHeader.styled";
import { Span } from "@styles/typography";

const UserHeader = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { name } = useSelector(state => state.me);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const handleLogout = () => {
    dispatch(logout());
    history.push("/login");
  };

  const handleOpenMenu = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  if (!name) return null;

  return (
    <Wrapper>
      <MenuWrapper ref={ref}>
        <MenuTitle onClick={handleOpenMenu}>
          <Span size={1.5}>{name}</Span>
        </MenuTitle>
        {isComponentVisible && (
          <UserDropdown>
            <DropdownItem onClick={handleLogout}>
              <Span>Logout</Span>
            </DropdownItem>
          </UserDropdown>
        )}
      </MenuWrapper>
    </Wrapper>
  );
};

export default UserHeader;
