import styled from "styled-components";

import { media } from "@styles";
import backgroundImgPath from "@assets/images/main_background.png";
import background2xImgPath from "@assets/images/main_background@2x.png";
import backgroundSmallImgPath from "@assets/images/main_background@small.png";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Content = styled.div`
  flex: 1;
`;

export const BackgroundImage = styled.div`
  width: 100%;
  height: 29rem;
  background-image: url(${background2xImgPath});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;

  ${media.giant`
    background-image: url(${background2xImgPath});  
  `}

  ${media.desktop`
    background-image: url(${backgroundImgPath}); 
  `} 
    
  ${media.phone`
    height: 34rem;
    background-image: url(${backgroundSmallImgPath});
  `} 
  
  @media only screen and (max-width: 400px) {
    height: 29rem;
  }
`;
