import { AUTH_SET_TOKEN, AUTH_REGISTER, REQ_AUTH, SET_AUTH_ERROR, AUTH_LOGOUT } from "../types/auth.types";

const initialState = {
  token: null,
  loggedIn: false,
  loading: false,
  error: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQ_AUTH:
      return {
        ...state,
        loading: true,
      };
    case AUTH_SET_TOKEN:
      return {
        ...state,
        token: action.token,
        loggedIn: true,
        loading: false,
        error: "",
      };
    case AUTH_REGISTER:
      return {
        ...state,
        token: action.user.token,
        loggedIn: true,
        loading: false,
        error: "",
      };
    case AUTH_LOGOUT:
      return initialState;
    case SET_AUTH_ERROR:
      return {
        ...state,
        error: Array.isArray(action.error) ? action.error[0] : action.error,
        loading: false,
      };
    default:
      return state;
  }
};
