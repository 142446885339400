import styled from "styled-components";
import Textarea from "react-textarea-autosize";

export const StyledTextArea = styled(Textarea)`
  width: 100%;
  background-color: white;
  border: ${({ isreadonly }) => `1px solid ${isreadonly ? "white" : "#d1d1d1"}`};
  border-radius: 5px;
  padding: 1rem;
  font-size: 1.4rem;
  box-sizing: border-box;
  resize: none;
  color: ${({ theme: { fontColor } }) => fontColor};

  &:disabled {
    background-color: #f7f7f7;
  }
`;
