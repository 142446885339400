import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { createApiApplication } from "@actions/apiApplications.action";
import { Form } from "@styles/form";
import { Row, Indent } from "@styles/grid";
import TextInput from "@components/inputs/TextInput";
import { ButtonPrimary, ButtonSecondaryLink } from "@components/Button";
import { addApiApplicationSchema } from "@utils/validation";

const ApiApplicationAddForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.apiApplications.loading);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      application_name: "",
    },
    validationSchema: addApiApplicationSchema,
    onSubmit: async ({ application_name }) => {
      try {
        const newApiApplication = {
          application_name,
        };

        const savedApiApplication = await dispatch(createApiApplication(newApiApplication));

        if (savedApiApplication.data) {
          return history.push("/api-applications");
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextInput
        position
        label="Name"
        labelPosition="left"
        fluid
        placeholder="Api Application Name"
        name="application_name"
        id="application_name"
        value={formik.values.application_name}
        onChange={formik.handleChange}
        formik={formik}
      />

      <Row margin="3rem 0" justify="flex-end">
        <Indent margin="0 2rem 0">
          <ButtonSecondaryLink uppercase outlined href="/api-applications">
            back to api applications
          </ButtonSecondaryLink>
        </Indent>
        <ButtonPrimary type="submit" uppercase disabled={loading} onClick={formik.handleSubmit}>
          Create Api Application
        </ButtonPrimary>
      </Row>
    </Form>
  );
};

export default ApiApplicationAddForm;
