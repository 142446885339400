import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import slugify from "slugify";

import { Row } from "@styles/grid";
import TextInput from "@components/inputs/TextInput";
import TextArea from "@components/inputs/Textarea";
import Select from "@components/inputs/Select";
import { ButtonPrimary, ButtonSecondary, ButtonSecondaryLink } from "@components/Button";
import { Form, FormButtons } from "@styles/form";
import { updateSchool } from "@actions/colleges.action";
import { getStates } from "@actions/states.action";
import { nullIfEmpty, emptyIfNull } from "@utils/string";
import { schoolValidationSchema } from "@utils/validation";

const SchoolViewForm = ({ school }) => {
  const [schoolState, setSchoolState] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const dispatch = useDispatch();
  const states = useSelector(state => state.states.states);
  const loading = useSelector(state => state.colleges.loading);

  useEffect(() => {
    if (school) {
      setSchoolState(school);
    }
  }, [school]);

  const foundedState = schoolState && states.find(({ id }) => id === schoolState.state_id);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: schoolState ? schoolState.name : "",
      slug: schoolState ? schoolState.slug : "",
      state: schoolState ? schoolState.state_id : "",
      city: schoolState ? emptyIfNull(schoolState.city) : "",
      rank: schoolState ? emptyIfNull(schoolState.rank) : "",
      link: schoolState ? emptyIfNull(schoolState.link) : "",
      address: schoolState ? emptyIfNull(schoolState.address) : "",
      address2: schoolState ? emptyIfNull(schoolState.address2) : "",
      zip: schoolState ? emptyIfNull(schoolState.zip) : "",
      notes: schoolState ? emptyIfNull(schoolState.notes) : "",
      short_code: schoolState ? emptyIfNull(schoolState.short_code) : "",
      ipeds_id: schoolState ? emptyIfNull(schoolState.ipeds_id) : "",
    },
    validationSchema: schoolValidationSchema,
    onSubmit: async ({ name, slug, state, city, rank, link, address, address2, zip, short_code, ipeds_id, notes }) => {
      try {
        const newSchool = {
          name,
          slug,
          state_id: state,
          city,
          location: `${city}, ${selectedState ? selectedState.label : foundedState.code}`,
          rank: nullIfEmpty(rank),
          link: nullIfEmpty(link),
          address: nullIfEmpty(address),
          address2: nullIfEmpty(address2),
          zip: nullIfEmpty(zip),
          short_code: nullIfEmpty(short_code),
          ipeds_id: nullIfEmpty(ipeds_id),
          notes: nullIfEmpty(notes),
        };

        const savedSchool = await dispatch(updateSchool(school.id, newSchool));

        if (savedSchool.data) {
          setSchoolState(savedSchool.data);
          setEditMode(false);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
  });

  useEffect(() => {
    if (schoolState && formik.values.name && formik.values.name !== schoolState.name) {
      formik.setFieldValue("slug", slugify(formik.values.name, { lower: true }));
    }
  }, [formik.values.name]);

  useEffect(() => {
    (async () => {
      if (!states || !states.length) {
        dispatch(getStates());
      }
    })();
  }, [states]);

  const handleSelectState = state => {
    formik.setFieldValue("state", state.value);
    setSelectedState(state);
  };

  const handleCancel = () => {
    setEditMode(prevMode => !prevMode);

    formik.setValues({
      name: schoolState.name,
      slug: schoolState.slug,
      state: schoolState.state_id,
      city: schoolState.city,
      rank: emptyIfNull(schoolState.rank),
      link: emptyIfNull(schoolState.link),
      address: emptyIfNull(schoolState.address),
      address2: emptyIfNull(schoolState.address2),
      zip: emptyIfNull(schoolState.zip),
      short_code: emptyIfNull(schoolState.short_code),
      ipeds_id: emptyIfNull(schoolState.ipeds_id),
      notes: emptyIfNull(schoolState.notes),
    });
  };

  const statesSelectOptions =
    states && states.length
      ? states.map(state => ({
          value: state.id,
          label: state.code,
        }))
      : [];

  const statesSelectValue = foundedState && {
    value: foundedState.id,
    label: foundedState.code,
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextInput
        label="Name"
        labelPosition="left"
        fluid
        placeholder={editMode ? "School Name" : ""}
        name="name"
        id="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        formik={formik}
        isreadonly={!editMode}
        readOnly={!editMode}
      />
      <TextInput
        label="Slug"
        labelPosition="left"
        fluid
        placeholder={editMode ? "School Slug" : ""}
        name="slug"
        id="slug"
        value={formik.values.slug}
        onChange={formik.handleChange}
        formik={formik}
        isreadonly={!editMode}
        readOnly={!editMode}
      />
      {editMode ? (
        <Select
          label="State"
          labelPosition="left"
          placeholder={editMode ? "Select State" : ""}
          options={statesSelectOptions}
          value={selectedState || statesSelectValue}
          onChange={handleSelectState}
          fluid
          formik={formik}
          name="state"
          id="state"
        />
      ) : (
        <TextInput
          label="State"
          labelPosition="left"
          fluid
          value={statesSelectValue ? statesSelectValue.label : ""}
          isreadonly={!editMode}
          readOnly={!editMode}
        />
      )}
      <TextInput
        label="City"
        labelPosition="left"
        fluid
        placeholder={editMode ? "Enter school city" : ""}
        onChange={formik.handleChange}
        name="city"
        id="city"
        value={formik.values.city}
        formik={formik}
        isreadonly={!editMode}
        readOnly={!editMode}
      />
      <TextInput
        type="number"
        label="Rank"
        labelPosition="left"
        fluid
        placeholder={editMode ? "Enter school rank" : ""}
        onChange={formik.handleChange}
        name="rank"
        id="rank"
        value={formik.values.rank}
        formik={formik}
        isreadonly={!editMode}
        readOnly={!editMode}
      />
      <TextInput
        label="Link"
        labelPosition="left"
        fluid
        placeholder={editMode ? "Enter school website link" : ""}
        onChange={formik.handleChange}
        name="link"
        id="link"
        value={formik.values.link}
        formik={formik}
        isreadonly={!editMode}
        readOnly={!editMode}
      />
      <TextInput
        label="Address"
        labelPosition="left"
        fluid
        placeholder={editMode ? "Enter school address" : ""}
        onChange={formik.handleChange}
        name="address"
        id="address"
        value={formik.values.address}
        formik={formik}
        isreadonly={!editMode}
        readOnly={!editMode}
      />
      <TextInput
        label="Address 2"
        labelPosition="left"
        fluid
        placeholder={editMode ? "Enter school address 2" : ""}
        onChange={formik.handleChange}
        name="address2"
        id="address2"
        value={formik.values.address2}
        formik={formik}
        isreadonly={!editMode}
        readOnly={!editMode}
      />
      <TextInput
        label="ZIP"
        labelPosition="left"
        fluid
        placeholder={editMode ? "Enter school zip" : ""}
        onChange={formik.handleChange}
        name="zip"
        id="zip"
        value={formik.values.zip}
        formik={formik}
        isreadonly={!editMode}
        readOnly={!editMode}
      />
      <TextInput
        label="School Short Code"
        labelPosition="left"
        fluid
        placeholder={editMode ? "Enter School Short Code" : ""}
        onChange={formik.handleChange}
        name="short_code"
        id="short_code"
        value={formik.values.short_code}
        formik={formik}
        isreadonly={!editMode}
        readOnly={!editMode}
      />
      <TextInput
        type="number"
        label="IPEDS ID"
        labelPosition="left"
        fluid
        placeholder={editMode ? "Enter IPEDS id" : ""}
        onChange={formik.handleChange}
        name="ipeds_id"
        id="ipeds_id"
        value={formik.values.ipeds_id}
        formik={formik}
        isreadonly={!editMode}
        readOnly={!editMode}
      />
      <TextArea
        label="Notes"
        labelPosition="left-top"
        fluid
        placeholder={editMode ? "Enter school notes" : ""}
        onChange={formik.handleChange}
        name="notes"
        id="notes"
        value={formik.values.notes}
        formik={formik}
        isreadonly={!editMode}
        readOnly={!editMode}
      />
      <Row margin="3rem 0" direction="column" align="flex-end">
        <FormButtons>
          {!editMode && (
            <ButtonSecondaryLink outlined uppercase href="/schools">
              Back to Schools
            </ButtonSecondaryLink>
          )}
          <ButtonSecondary uppercase onClick={handleCancel}>
            {editMode ? "cancel" : "Edit"}
          </ButtonSecondary>{" "}
          {editMode && (
            <ButtonPrimary type="submit" onClick={formik.handleSubmit} uppercase disabled={loading}>
              Save School
            </ButtonPrimary>
          )}
        </FormButtons>
      </Row>
    </Form>
  );
};

export default SchoolViewForm;
