import { get, post, put, deleteById } from "@api";
import {
  SET_BRANDS,
  SET_BRANDS_ERROR,
  SET_BRANDS_LOADING,
  SET_UPDATED_BRAND,
  SET_CREATED_BRAND,
  REMOVE_DELETED_BRAND,
  SET_BRANDS_FETCHING,
} from "../types/brands.types";
import { setNotice } from "@actions/notice.action";

export const setBrandsLoading = () => dispatch =>
  dispatch({
    type: SET_BRANDS_LOADING,
  });

export const setBrandsFetching = () => dispatch =>
  dispatch({
    type: SET_BRANDS_FETCHING,
  });

export const setBrands = brands => dispatch =>
  dispatch({
    type: SET_BRANDS,
    brands,
  });

export const setCreatedBrand = brand => dispatch =>
  dispatch({
    type: SET_CREATED_BRAND,
    brand,
  });

export const setUpdatedBrand = updatedBrand => dispatch =>
  dispatch({
    type: SET_UPDATED_BRAND,
    updatedBrand,
  });

export const removeDeletedBrand = brandId => dispatch =>
  dispatch({
    type: REMOVE_DELETED_BRAND,
    brandId,
  });

export const setBrandsError = error => dispatch =>
  dispatch({
    type: SET_BRANDS_ERROR,
    error,
  });

export const getAllBrands = (include = "") => async dispatch => {
  try {
    dispatch(setBrandsFetching());
    const brands = await get(`/brands?include=${include}`);

    dispatch(setBrands(brands));

    return brands;
  } catch (error) {
    dispatch(setBrandsError(error.message));
  }
};

export const getBrandById = (brandId, include = "") => async dispatch => {
  try {
    const brand = await get(`/brands/${brandId}?include=${include}`);

    return brand;
  } catch (error) {
    dispatch(setBrandsError(error.message));
  }
};

export const createBrand = brand => async dispatch => {
  try {
    dispatch(setBrandsLoading());

    const createdBrand = await post("brands", brand);

    dispatch(setCreatedBrand(createdBrand.data));
    dispatch(setNotice({ message: createdBrand.message, type: "success" }));

    return createdBrand;
  } catch (error) {
    dispatch(setBrandsError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const updateBrand = (brandId, brand) => async dispatch => {
  try {
    dispatch(setBrandsLoading());

    const updatedBrand = await put(`brands/${brandId}`, brand);

    dispatch(setUpdatedBrand(updatedBrand.data));
    dispatch(setNotice({ message: updatedBrand.message, type: "success" }));

    return updatedBrand;
  } catch (error) {
    dispatch(setBrandsError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const deleteBrandById = brandId => async dispatch => {
  try {
    dispatch(setBrandsLoading());

    const deletedBrand = await deleteById("brands", brandId);

    dispatch(removeDeletedBrand(brandId));
    dispatch(setNotice({ message: deletedBrand.message, type: "success" }));

    return deletedBrand;
  } catch (error) {
    dispatch(setBrandsError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};
