import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Row } from "@styles/grid";
import TextInput from "@components/inputs/TextInput";
import Select from '@components/inputs/Select';
import { ButtonPrimary, ButtonSecondary, ButtonSecondaryLink } from "@components/Button";
import { Form, FormButtons } from "@styles/form";
import { emptyIfNull } from "@utils/string";
import { updatePartnerProduct } from "@actions/partnerProducts.action";
import { editPartnerProductValidationSchema } from "@utils/validation";
import { getPartnerCategoryByPartnerId } from '@actions/partnerCategories.action';

const PartnerProductViewForm = ({ partnerProduct }) => {
    const [partnerProductState, setPartnerProductState] = useState(null);
    const [selectedPartnerCategory, setSelectedPartnerCategory] = useState(0);
    const [partnerCats, setPartnerCats] = useState([])
    const [editMode, setEditMode] = useState(false);
    const dispatch = useDispatch();
    const loading = useSelector(state => state.partnerProducts.loading);

    useEffect(() => {
        if (partnerProduct) {
            setPartnerProductState(partnerProduct);
        }

        if (partnerProduct && partnerProduct.partner_category) {
            setSelectedPartnerCategory(partnerProduct.partner_category);
        }

        if (partnerProduct && partnerProduct.partner && partnerProduct.partner.id) {
            (async () => {
                const pCs = await dispatch(getPartnerCategoryByPartnerId(partnerProduct.partner.id));
                if (pCs) setPartnerCats(pCs)
            })();
        }
    }, [partnerProduct]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name_alias: partnerProductState ? emptyIfNull(partnerProductState.name_alias) : null,
            product: partnerProductState ? emptyIfNull(partnerProductState.product.name) : "",
            partner: partnerProductState ? emptyIfNull(partnerProductState.partner.name) : "",
            upc: partnerProductState ? emptyIfNull(partnerProductState.upc) : "",
            order: partnerProductState ? emptyIfNull(partnerProductState.order) : "",
            quantity: partnerProductState ? emptyIfNull(partnerProductState.quantity) : "",
            partner_category_id: selectedPartnerCategory ? emptyIfNull(selectedPartnerCategory.value) : null,
        },
        validationSchema: editPartnerProductValidationSchema,
        onSubmit: async ({ name_alias, upc, order, quantity, partner_category_id }) => {
            try {
                const newPartnerProduct = {
                    name_alias: name_alias ? name_alias : null,
                    upc: upc,
                    order: parseInt(order),
                    quantity: parseInt(quantity),
                    partner_category_id: partner_category_id ? partner_category_id : null,
                };

                const savedPartnerProduct = await dispatch(updatePartnerProduct(partnerProduct.id, newPartnerProduct));
                if (savedPartnerProduct.message === "Product Partner successfully updated") {
                    setEditMode(false);
                    window.location.reload();
                }
            } catch (error) {
                throw new Error(error.message);
            }
        },
    });

    const handleCancel = () => {
        setEditMode(prevMode => !prevMode);

        formik.setValues({
            name_alias: partnerProductState ? emptyIfNull(partnerProductState.name_alias) : null,
            product: partnerProductState ? emptyIfNull(partnerProductState.product.name) : "",
            partner: partnerProductState ? emptyIfNull(partnerProductState.partner.name) : "",
            upc: partnerProductState ? emptyIfNull(partnerProductState.upc) : "",
            order: partnerProductState ? emptyIfNull(partnerProductState.order) : "",
            quantity: partnerProductState ? emptyIfNull(partnerProductState.quantity) : "",
            partner_category_id: partnerProductState ? emptyIfNull(partnerProductState?.partner_category?.id) : null,
        });
    };

    const partnerCategoriesSelectOptions =
        partnerCats && partnerCats.length
            ? [
                { value: null, label: "None" },
                ...partnerCats.map(cat => ({
                    value: cat.id,
                    label: cat.name,
                })),
            ]
            : [{ value: null, label: "None" }];

    const handleSelectCategory = category => {
        setSelectedPartnerCategory({ label: category.label, value: category.value })
    }

    const placeholder = () => {
        if (selectedPartnerCategory && selectedPartnerCategory.label) {
            return selectedPartnerCategory.label;
        }
        if (partnerProduct && partnerProduct.partner_category
            && partnerProduct.partner_category.name) {
            return partnerProduct.partner_category.name;
        }

        return 'Select a partner category'
    }

    return (
        <Form onSubmit={formik.handleSubmit}>
            <TextInput
                label="Product"
                labelPosition="left"
                fluid
                name="product"
                id="product"
                value={formik.values.product}
                formik={formik}
                isreadonly={true}
                readOnly={true}
            />

            <TextInput
                label="Partner"
                labelPosition="left"
                fluid
                name="partner"
                id="partner"
                value={formik.values.partner}
                formik={formik}
                isreadonly={true}
                readOnly={true}
            />

            <Select
                label="Partner Category"
                labelPosition="left"
                placeholder={placeholder()}
                options={partnerCategoriesSelectOptions}
                value={formik.values.partner_category_id}
                onChange={handleSelectCategory}
                fluid
                formik={formik}
                name="partner_category_id"
                id="partner_category_id"
                isreadonly={!editMode}
                readOnly={!editMode}
                disabled={editMode ? false : true}
            />

            <TextInput
                label="UPC"
                labelPosition="left"
                fluid
                placeholder={editMode ? "upc" : ""}
                name="upc"
                id="upc"
                value={formik.values.upc}
                onChange={formik.handleChange}
                formik={formik}
                isreadonly={!editMode}
                readOnly={!editMode}
            />

            <TextInput
                label="Order"
                labelPosition="left"
                fluid
                placeholder={editMode ? "order" : ""}
                name="order"
                id="order"
                value={formik.values.order}
                onChange={formik.handleChange}
                formik={formik}
                isreadonly={!editMode}
                readOnly={!editMode}
            />

            <TextInput
                label="Name Alias"
                labelPosition="left"
                fluid
                placeholder={editMode ? "name alias" : ""}
                name="name_alias"
                id="name_alias"
                value={formik.values.name_alias}
                onChange={formik.handleChange}
                formik={formik}
                isreadonly={!editMode}
                readOnly={!editMode}
            />

            <TextInput
                label="Quantity"
                labelPosition="left"
                fluid
                placeholder={editMode ? "quantity" : ""}
                name="quantity"
                id="quantity"
                value={formik.values.quantity}
                onChange={formik.handleChange}
                formik={formik}
                isreadonly={!editMode}
                readOnly={!editMode}
            />

            <Row margin="3rem 0" direction="column" align="flex-end">
                <FormButtons>
                    {!editMode && (
                        <ButtonSecondaryLink type="button" outlined uppercase href="/partnerproducts">
                            Back to Partner Products
                        </ButtonSecondaryLink>
                    )}
                    <ButtonSecondary type="button" uppercase onClick={handleCancel}>
                        {editMode ? "cancel" : "Edit"}
                    </ButtonSecondary>
                    {editMode && (
                        <ButtonPrimary type="submit" onClick={formik.handleSubmit} uppercase disabled={loading}>
                            Save Partner Product
                        </ButtonPrimary>
                    )}
                </FormButtons>
            </Row>
        </Form>
    );
};

export default PartnerProductViewForm;
