import { SET_SELECTED_STATE, SET_STATES, SET_STATES_LOADING, SET_STATE_ERROR } from "../types/states.types";
import { get } from "@api";
import { setCollegesByState } from "./colleges.action";

export const setStatesLoading = () => dispatch =>
  dispatch({
    type: SET_STATES_LOADING,
  });

export const setStates = states => dispatch =>
  dispatch({
    type: SET_STATES,
    states,
  });

export const setSelectedState = state => dispatch =>
  dispatch({
    type: SET_SELECTED_STATE,
    state,
  });

export const setStateError = error => dispatch =>
  dispatch({
    type: SET_STATE_ERROR,
    error,
  });

export const getStates = (user_school_ids = "") => async dispatch => {
  try {
    dispatch(setStatesLoading());

    const response = await get(`states/schools/count?${user_school_ids}`);

    if (user_school_ids && user_school_ids.length) {
      const states = [];
      const schools = [];
      response.forEach(item => {
        states.push(item.state);
        schools.push(item);
      });
      dispatch(setStates(states));
      dispatch(setCollegesByState(schools));
      return states;
    }
    dispatch(setStates(response));

    return response;
  } catch (error) {
    dispatch(setStateError(error.message));
  }
};
