import { get, post, deleteById, put } from "@api";
import {
  SET_CHECKLISTS,
  SET_CHECKLISTS_BY_COLLEGE,
  SET_SELECTED_CHECKLIST,
  SET_SAVED_CHECKLISTS,
  SET_UPDATED_CHECKLISTS,
  DELETE_CHECKLIST,
  SET_CHECKLIST_ERROR,
  CLEAR_CHECKLIST,
  SET_CHECKLIST_LOADING,
  UNSET_CHECKLIST_LOADING,
  SET_CHECKLIST_FETCHING,
} from "../types/checklist.types";
import { setNotice } from "./notice.action";
import { includeIfExist } from "@utils/string";
import { createURLParams } from "@utils/url";

export const setChecklists = checklists => dispatch =>
  dispatch({
    type: SET_CHECKLISTS,
    checklists,
  });

export const setChecklistsByCollege = checklists => dispatch =>
  dispatch({
    type: SET_CHECKLISTS_BY_COLLEGE,
    checklists,
  });

export const setSelectedChecklist = selectedChecklist => dispatch =>
  dispatch({
    type: SET_SELECTED_CHECKLIST,
    selectedChecklist,
  });

export const setSavedChecklist = checklist => dispatch =>
  dispatch({
    type: SET_SAVED_CHECKLISTS,
    checklist,
  });

export const setUpdatedChecklist = updatedChecklist => dispatch =>
  dispatch({
    type: SET_UPDATED_CHECKLISTS,
    updatedChecklist,
  });

export const deleteChecklist = checklistId => dispatch =>
  dispatch({
    type: DELETE_CHECKLIST,
    checklistId,
  });

export const setChecklistError = error => dispatch =>
  dispatch({
    type: SET_CHECKLIST_ERROR,
    error,
  });

export const clearChecklist = () => dispatch =>
  dispatch({
    type: CLEAR_CHECKLIST,
  });

export const setChecklistLoading = () => dispatch =>
  dispatch({
    type: SET_CHECKLIST_LOADING,
  });

export const setChecklistFetching = () => dispatch =>
  dispatch({
    type: SET_CHECKLIST_FETCHING,
  });

export const unsetChecklistLoading = () => dispatch =>
  dispatch({
    type: UNSET_CHECKLIST_LOADING,
  });

export const getChecklistById = (checklistId, include) => async dispatch => {
  try {
    const checklist = await get(`checklists/${checklistId}${includeIfExist("include", include)}`);

    return checklist;
  } catch (error) {
    dispatch(setChecklistError(error.message));
  }
};

export const getChecklistBySlug = (slug, include) => async dispatch => {
  try {
    const checklist = await get(`checklists/slug/${slug}${includeIfExist("include", include)}`);

    return checklist;
  } catch (error) {
    dispatch(setChecklistError(error.message));
  }
};

export const getSponsoredChecklistBySlug = (slug, include) => async dispatch => {
  try {
    const checklist = await get(`checklists/slug/${slug}/sponsored${includeIfExist("include", include)}`);

    return checklist;
  } catch (error) {
    dispatch(setChecklistError(error.message));
  }
};

export const getChecklists = (params = ["include=school"]) => async dispatch => {
  const queryParams = createURLParams(params);

  try {
    dispatch(setChecklistFetching());
    const checklists = await get(`checklists${queryParams}`);

    dispatch(setChecklists(checklists));

    return checklists;
  } catch (error) {
    dispatch(setChecklistError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "warning" }));
  }
};

export const getChecklistsByCollege = collegeId => async dispatch => {
  try {
    const checklists = await get(`schools/${collegeId}/checklists`);

    dispatch(setChecklistsByCollege(checklists));

    return checklists;
  } catch (error) {
    dispatch(setChecklistError(error.message));
  }
};

export const getChecklistsByCollegeSlug = (collegeSlug, include) => async dispatch => {
  try {
    const checklists = await get(`schools/slug/${collegeSlug}/checklists${includeIfExist("include", include)}`);

    dispatch(setChecklistsByCollege(checklists));

    return checklists;
  } catch (error) {
    dispatch(setChecklistError(error.message));
  }
};

export const saveChecklist = checklist => async dispatch => {
  try {
    dispatch(setChecklistLoading());

    const savedChecklist = await post(`checklists`, checklist);

    dispatch(setSavedChecklist(savedChecklist.data));
    return savedChecklist;
  } catch (error) {
    dispatch(setChecklistError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const updateChecklist = (checklistId, checklist) => async dispatch => {
  try {
    dispatch(setChecklistLoading());

    const updatedChecklist = await put(`checklists/${checklistId}`, checklist);

    dispatch(setUpdatedChecklist(updatedChecklist.data));
    return updatedChecklist;
  } catch (error) {
    dispatch(setChecklistError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const deleteChecklistById = checklistId => async dispatch => {
  try {
    dispatch(setChecklistLoading());

    const deletedChecklist = await deleteById(`checklists`, checklistId);

    dispatch(deleteChecklist(checklistId));
    dispatch(setNotice({ message: deletedChecklist.message, type: "success" }));
    return deletedChecklist;
  } catch (error) {
    dispatch(setChecklistError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const setChecklistViews = identifier => () => {
  try {
    post(`checklists/${identifier}/views?by=slug`, identifier);
  } catch (error) {
    throw new Error(error.message);
  }
};
