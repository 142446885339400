import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "@components/Table";
import { ButtonSecondary, ButtonPrimaryLink } from "@components/Button";
import { deleteSchoolById } from "@actions/colleges.action";
import Modal from "@components/Modal";
import { Span } from "@styles/typography";
import { Row, Indent } from "@styles/grid";

const EditSchool = ({ schoolId }) => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.colleges.loading);

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const handleDeleteSchool = () => async () => {
    try {
      await dispatch(deleteSchoolById(schoolId));
      setIsRemoveModalOpen(false);
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return (
    <Row justify="flex-end" padding="0 2rem 0 0">
      <Indent margin="0 1.5rem 0 0">
        <ButtonPrimaryLink uppercase href={`/schools/${schoolId}`} outlined size="small" weight="bold">
          edit
        </ButtonPrimaryLink>
      </Indent>
      <ButtonSecondary uppercase onClick={() => setIsRemoveModalOpen(true)} size="small" weight="bold">
        Remove
      </ButtonSecondary>

      <Modal isOpen={isRemoveModalOpen} onClose={() => setIsRemoveModalOpen(false)} title="Remove School">
        <Indent margin="4rem 0">
          <Span size={1.8}>Are you sure you want to delete this school?</Span>
        </Indent>
        <Row justify="flex-end">
          <Indent margin="0 2rem 0">
            <ButtonSecondary outlined onClick={() => setIsRemoveModalOpen(false)} uppercase>
              Close
            </ButtonSecondary>
          </Indent>
          <ButtonSecondary autoFocus onClick={handleDeleteSchool()} uppercase disabled={loading}>
            Remove
          </ButtonSecondary>
        </Row>
      </Modal>
    </Row>
  );
};

const SchoolsTable = () => {
  const { colleges, fetching } = useSelector(({ colleges }) => colleges);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        isFiltered: true,
        isSorting: true,
      },
      {
        Header: "Location",
        accessor: "location",
        isFiltered: true,
        isSorting: true,
      },
      {
        Header: "Rank",
        accessor: "rank",
        isFiltered: true,
        isSorting: true,
      },
      {
        id: "colEdit",
        Cell: ({ row }) => <EditSchool schoolId={row.original.id} />,
      },
    ],
    []
  );

  const data = useMemo(() => colleges, [colleges]);

  return <Table columns={columns} data={data} hasGlobalFilter loading={fetching} />;
};

export default SchoolsTable;
