import styled from "styled-components";

export const StyledTextInput = styled.input`
  width: 100%;
  background-color: white;
  border: ${({ isreadonly, hasError }) => `1px solid ${isreadonly ? "white" : hasError ? "red" : "#d1d1d1"}`};
  border-radius: 5px;
  padding: 1rem;
  font-size: 1.4rem;
  box-sizing: border-box;
  resize: none;

  &:disabled {
    background-color: #f7f7f7;
  }
`;
