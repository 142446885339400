import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { Row, Indent } from "@styles/grid";
import { Span } from "@styles/typography";
import TextInput from "@components/inputs/TextInput";
import Select from "@components/inputs/Select";
import { ButtonPrimary, ButtonSecondary, ButtonSecondaryLink } from "@components/Button";
import Loader from "@components/Loader";
import { Form, FormButtons } from "@styles/form";
import { updateUser } from "@actions/users.action";
import { getAllRoles } from "@actions/roles.action";
import { getStates } from "@actions/states.action";
import { getCollegesByState } from "@actions/colleges.action";
import { editUserValidationSchema } from "@utils/validation";
import { capitalize } from "@utils/string";
import generateUUID from "@lib/generateUUID";
import { USER_ROLES } from "@constants";
import moment from "moment";

const UserViewForm = ({ user }) => {
  const [userState, setUserState] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const { loading, fetching } = useSelector(({ users }) => users);
  const { roles } = useSelector(({ roles }) => roles);
  const { states } = useSelector(({ states }) => states);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      setUserState(user);
    }
  }, [user]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: userState ? userState.name : "",
      email: userState ? userState.email : "",
      role: userState ? { value: userState.role.id, label: capitalize(userState.role.name) } : { value: "", label: "" },
      schools: [],
    },
    validationSchema: editUserValidationSchema,
    onSubmit: async ({ name, email, role, schools }) => {
      try {
        const newUser = {
          name,
          email,
          role: role.value,
          schools: Array.from(new Set(schools.filter(({ school }) => school).map(({ school }) => school.value))),
        };

        if (role.label.toLowerCase() !== USER_ROLES.college) {
          delete newUser.schools;
        }

        const savedUser = await dispatch(updateUser(user.id, newUser));

        setUserState(savedUser.data);
        setEditMode(false);
      } catch (error) {
        throw new Error(error.message);
      }
    },
  });

  useEffect(() => {
    (async () => {
      if (!roles || !roles.length) {
        await dispatch(getAllRoles());
      }
      if (!states || !states.length) {
        await dispatch(getStates());
      }
    })();
  }, [roles, states]);

  const getUserStatesSchools = async () => {
    if (userState && userState.schools && userState.schools.length && states && states.length) {
      const userStatesSchools = await Promise.all([
        ...userState.schools.map(({ state_id }) => dispatch(getCollegesByState(state_id))),
      ]);

      formik.setFieldValue("schools", [
        ...userState.schools.map((school, idx) => {
          const stateOfUser = states.find(({ id }) => school.state_id === id);

          return {
            key: generateUUID(),
            state: { ...stateOfUser, value: stateOfUser.id, label: stateOfUser.name },
            school: { ...school, value: school.id, label: school.name },
            schools: userStatesSchools[idx].map(school => ({ ...school, value: school.id, label: school.name })),
          };
        }),
        {
          key: generateUUID(),
          state: null,
          school: null,
          schools: [],
        },
      ]);
    } else {
      formik.setFieldValue("schools", [
        {
          key: generateUUID(),
          state: null,
          school: null,
          schools: [],
        },
      ]);
    }
  };

  useEffect(() => {
    getUserStatesSchools();
  }, [userState, states]);

  const handleSelectRole = role => {
    formik.setFieldValue("role", role);
  };

  const handleCancel = () => {
    let mode = null;
    setEditMode(prevMode => {
      mode = !prevMode;
      return mode;
    });

    if (!mode) getUserStatesSchools();
  };

  const handleSelectState = async ({ value, label }, idx) => {
    try {
      const collegesByState = await dispatch(getCollegesByState(value));

      const newFormikSchools = [...formik.values.schools];
      newFormikSchools[idx] = {
        ...newFormikSchools[idx],
        state: {
          value,
          label,
        },
        school: null,
        schools: collegesByState.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      };
      formik.setFieldValue("schools", newFormikSchools);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectSchool = async (school, idx) => {
    try {
      const newFormikSchools = [...formik.values.schools];
      newFormikSchools[idx] = {
        ...newFormikSchools[idx],
        school: school,
      };
      const newSchool = [
        ...newFormikSchools.filter(({ school }) => school),
        {
          key: generateUUID(),
          state: null,
          school: null,
          schools: [],
        },
      ];

      formik.setFieldValue("schools", newSchool);
    } catch (error) {
      console.error(error);
    }
  };

  const rolesSelectOptions =
    roles && roles.length
      ? roles.map(role => ({
          value: role.id,
          label: capitalize(role.name),
        }))
      : [];

  const statesOptions =
    states && states.length
      ? states.map(({ id, name }) => ({
          label: name,
          value: id,
        }))
      : [];

  const rolesSelectValue = {
    value: formik.values.role.id,
    label: capitalize(formik.values.role.label),
  };

  return (
    <>
      {fetching ? (
        <Loader />
      ) : (
        <Form onSubmit={formik.handleSubmit}>
          <TextInput
            label="Name"
            labelPosition="left"
            fluid
            placeholder={editMode ? "User First Name" : ""}
            name="name"
            id="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            formik={formik}
            isreadonly={!editMode}
            readOnly={!editMode}
          />

          <TextInput
            label="Email"
            labelPosition="left"
            fluid
            placeholder={editMode ? "User email" : ""}
            name="email"
            id="email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            formik={formik}
            isreadonly={!editMode}
            readOnly={!editMode}
          />
          {editMode ? (
            <Select
              label="Role"
              labelPosition="left"
              placeholder="Select Role"
              options={rolesSelectOptions}
              value={rolesSelectValue}
              onChange={handleSelectRole}
              fluid
              formik={formik}
              name="role"
              id="role"
            />
          ) : (
            <TextInput
              label="Role"
              labelPosition="left"
              fluid
              value={formik.values.role ? formik.values.role.label : ""}
              isreadonly={!editMode}
              readOnly={!editMode}
            />
          )}

          {userState && userState.partner ? (
            <TextInput
              label="User from partner"
              labelPosition="left"
              fluid
              value={userState.partner.name}
              isreadonly={true}
              readOnly={true}
            />
          ) : null}

          <TextInput
            position
            label="Created"
            labelPosition="left"
            fluid
            value={userState && moment(userState.created_at).format("LLL")}
            isreadonly={true}
          />
          <TextInput
            position
            label="Updated"
            labelPosition="left"
            fluid
            value={userState && moment(userState.update_at).format("LLL")}
            isreadonly={true}
          />

          {formik.values.role && formik.values.role.label.toLowerCase() === USER_ROLES.college
            ? formik.values.schools.map((school, idx) => {
                if (!editMode && !school.state) {
                  return null;
                }

                return (
                  <Row direction="column" margin="3rem 0 0" key={school.key}>
                    <Indent margin="4rem 0 0">
                      <Span size={2} weight="bold">
                        {idx + 1} School
                      </Span>
                    </Indent>
                    <Row margin="0 0 1rem">
                      {editMode ? (
                        <Select
                          label={`School State`}
                          labelPosition="left"
                          placeholder="Select State"
                          options={statesOptions}
                          value={school.state}
                          onChange={state => handleSelectState(state, idx)}
                          name="state"
                          formik={formik}
                          fluid
                        />
                      ) : (
                        <TextInput
                          label={`School State`}
                          labelPosition="left"
                          fluid
                          value={school.state ? school.state.label : ""}
                          isreadonly={!editMode}
                          readOnly={!editMode}
                        />
                      )}
                    </Row>
                    {school.state && editMode ? (
                      <Select
                        label={`School`}
                        labelPosition="left"
                        placeholder="Select School"
                        options={school.schools}
                        value={school.school}
                        onChange={school => handleSelectSchool(school, idx)}
                        name="school_id"
                        formik={formik}
                        fluid
                      />
                    ) : !editMode && school.state ? (
                      <TextInput
                        label={`School`}
                        labelPosition="left"
                        fluid
                        value={school.school.name}
                        isreadonly={!editMode}
                        readOnly={!editMode}
                      />
                    ) : null}
                  </Row>
                );
              })
            : null}

          <Row margin="3rem 0" direction="column" align="flex-end">
            <FormButtons>
              {!editMode && (
                <ButtonSecondaryLink outlined uppercase href="/users">
                  Back to Users
                </ButtonSecondaryLink>
              )}
              <ButtonSecondary uppercase onClick={handleCancel}>
                {editMode ? "cancel" : "Edit"}
              </ButtonSecondary>{" "}
              {editMode && (
                <ButtonPrimary type="submit" uppercase disabled={loading}>
                  Save User
                </ButtonPrimary>
              )}
            </FormButtons>
          </Row>
        </Form>
      )}
    </>
  );
};

export default UserViewForm;
