import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { Row, Indent } from "@styles/grid";
import Select from "@components/inputs/Select";
import TextInput from "@components/inputs/TextInput";
import Textarea from "@components/inputs/Textarea";
import Checkbox from "@components/inputs/Checkbox";
import { ButtonPrimary, ButtonSecondaryLink } from "@components/Button";
import { Form } from "@styles/form";
import { createCategory } from "@actions/categories.action";
import { getAllBrands } from "@actions/brands.action";
import { nullIfEmpty } from "@utils/string";
import { categoryValidationSchema } from "@utils/validation";

const CategoryAddForm = () => {
  const [selectedSponsor, setSelectedSponsor] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.categories.loading);
  const brands = useSelector(state => state.brands.brands);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      tooltip: "",
      brand_id: null,
      selectable: false,
    },
    validationSchema: categoryValidationSchema,
    onSubmit: async ({ name, tooltip, brand_id, selectable }) => {
      try {
        const newCategory = {
          name,
          tooltip: nullIfEmpty(tooltip),
          brand_id,
          selectable,
        };

        const savedCategory = await dispatch(createCategory(newCategory));

        if (savedCategory.data) {
          return history.push("/categories");
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
  });

  useEffect(() => {
    const getBrands = async () => {
      const isBrandsNotEmpty = brands && brands.length;

      if (!isBrandsNotEmpty) {
        await dispatch(getAllBrands());
      }
    };

    getBrands();
  }, []);

  const handleSelectSponsor = brand => {
    formik.setFieldValue("brand_id", brand.value);
    setSelectedSponsor(brand);
  };

  const brandsSelectOptions =
    brands && brands.length
      ? [
          { value: null, label: "None" },
          ...brands.map(brand => ({
            value: brand.id,
            label: brand.name,
          })),
        ]
      : [];

  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextInput
        label="Name"
        labelPosition="left"
        fluid
        placeholder="Category Name"
        name="name"
        id="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        formik={formik}
      />
      <Select
        label="Sponsor"
        labelPosition="left"
        placeholder="Select Sponsor"
        options={brandsSelectOptions}
        value={selectedSponsor}
        onChange={handleSelectSponsor}
        fluid
        formik={formik}
        name="brand_id"
        id="brand_id"
      />
      <Textarea
        label="Tooltip"
        labelPosition="left-top"
        fluid
        placeholder="Category Tooltip"
        name="tooltip"
        id="tooltip"
        value={formik.values.tooltip}
        onChange={formik.handleChange}
        formik={formik}
      />
      <Indent margin="0 0 0 auto">
        <Checkbox
          label="Selectable"
          name="selectable"
          id="selectable"
          labelFontSize={1.2}
          value={formik.values.selectable}
          checked={formik.values.selectable}
          onClick={formik.handleChange}
          formik={formik}
        />
      </Indent>
      <Row margin="3rem 0" justify="flex-end">
        <Indent margin="0 2rem 0">
          <ButtonSecondaryLink outlined uppercase href="/categories">
            Back to categories
          </ButtonSecondaryLink>
        </Indent>
        <ButtonPrimary type="submit" uppercase disabled={loading} onClick={formik.handleSubmit}>
          Create Category
        </ButtonPrimary>
      </Row>
    </Form>
  );
};

export default CategoryAddForm;
