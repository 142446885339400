import React from "react";
import { useSelector } from "react-redux";

const GuardComponent = ({ roles, children }) => {
  const role = useSelector(({ me }) => me.role);

  if (roles.includes(role)) return <>{children}</>;

  return null;
};

export default GuardComponent;
