import React from "react";
import RSelect from "react-select";
import Label from "@components/Label";
import theme from "@styles";
import { ErrorMessage } from "@styles/typography";
import useXBreakpoint from "@hooks/useXBreakpoint";

const Select = ({
  label,
  labelPosition = "top",
  labelFontSize,
  labelWeight,
  uppercase,
  disabled,
  containerWidth,
  fluid,
  id,
  formik,
  name,
  onBlur,
  ...otherProps
}) => {
  const meta = formik && formik.getFieldMeta(name);
  const hasError = meta && meta.touched && meta.error;
  const isMobile = useXBreakpoint(450);

  const customStyles = {
    container: provided => ({
      ...provided,
      width: isMobile ? "100%" : containerWidth,
      flex: fluid ? 1 : "unset",
      fontSize: "1.4rem",
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: "0px 3px 6px #00000029",
      border: hasError && "1px solid red",
      borderRadius: 0,
      backgroundColor: state.isDisabled ? theme.colorDisabled : "white",
      cursor: state.isDisabled ? "not-allowed" : "pointer",
    }),
    menu: provided => ({
      ...provided,
      zIndex: 10,
    }),
    loadingIndicator: provided => ({
      ...provided,
      position: "absolute",
      left: 5,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: state.selectProps.isLoading ? "0" : "1.2rem",
      color: theme.colorTextBlack,
    }),
    indicatorSeparator: () => null,
  };
  return (
    <>
      <Label
        title={label}
        position={labelPosition}
        disabled={disabled}
        labelFontSize={labelFontSize}
        labelWeight={labelWeight}
        fluid={fluid}
        uppercase={uppercase}>
        <RSelect
          isDisabled={disabled}
          styles={customStyles}
          onBlur={() => (formik ? formik.setFieldTouched(name, true) : onBlur)}
          instanceId={id}
          {...otherProps}
        />
      </Label>

      {hasError && <ErrorMessage position={labelPosition}>{meta.error}</ErrorMessage>}
    </>
  );
};

export default Select;
