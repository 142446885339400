import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { Row, Indent } from "@styles/grid";
import TextInput from "@components/inputs/TextInput";
import Textarea from "@components/inputs/Textarea";
import { ButtonPrimary, ButtonSecondaryLink } from "@components/Button";
import { Form } from "@styles/form";
import { createBrand } from "@actions/brands.action";
import ImageUploader from "@components/ImageUploader";
import { post } from "@api";
import { nullIfEmpty } from "@utils/string";
import { brandValidationSchema } from "@utils/validation";

const BrandAddForm = () => {
  const [imageLoading, setImageLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.brands.loading);
  const formRef = useRef(null);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
      notes: "",
      image: null,
    },
    validationSchema: brandValidationSchema,
    onSubmit: async ({ name, description, notes, image }) => {
      try {
        const newBrand = {
          name,
          description: nullIfEmpty(description),
          notes: nullIfEmpty(notes),
          image: nullIfEmpty(image),
        };

        const savedBrand = await dispatch(createBrand(newBrand));

        if (savedBrand.data) {
          return history.push("/brands");
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
  });

  const uploadImageOnChange = async image => {
    try {
      setImageLoading(true);

      const fData = new FormData();
      fData.append("image", image);

      const { imagePath } = await post("static/images/sponsors", fData);

      formik.setFieldValue("image", imagePath);
      setImageLoading(false);
    } catch (error) {
      setImageLoading(false);
      console.error(error);
    }
  };

  const handleRemoveImage = () => {
    formik.setFieldValue("image", "");
    formRef.current.reset();
  };

  return (
    <Form ref={formRef} onSubmit={formik.handleSubmit}>
      <TextInput
        label="Name"
        labelPosition="left"
        fluid
        placeholder="Brand Name"
        name="name"
        id="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        formik={formik}
      />
      <Textarea
        label="Description"
        labelPosition="left-top"
        fluid
        placeholder="Brand Description"
        name="description"
        id="description"
        value={formik.values.description}
        onChange={formik.handleChange}
        formik={formik}
      />
      <Textarea
        label="Notes"
        labelPosition="left-top"
        fluid
        placeholder="Brand Notes"
        name="notes"
        id="notes"
        value={formik.values.notes}
        onChange={formik.handleChange}
        formik={formik}
      />
      <Row direction="column" align="center">
        <ImageUploader
          title="Select Brand Image"
          name="image"
          id="image"
          imageSrc={formik.values.image}
          loading={imageLoading}
          onChange={uploadImageOnChange}
          onRemove={handleRemoveImage}
          maxSize={2}
          editMode
        />
      </Row>
      <Row margin="3rem 0" justify="flex-end">
        <Indent margin="0 2rem 0">
          <ButtonSecondaryLink outlined uppercase href="/brands">
            Back to brands
          </ButtonSecondaryLink>
        </Indent>
        <ButtonPrimary type="submit" uppercase disabled={loading} onClick={formik.handleSubmit}>
          Create Brand
        </ButtonPrimary>
      </Row>
    </Form>
  );
};

export default BrandAddForm;
