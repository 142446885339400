import React from "react";
import Label from "@components/Label";
import { StyledTextInput } from "./TextInput.styled";
import { ErrorMessage } from "@styles/typography";

const TextInput = ({
  disabled,
  label,
  fluid,
  isreadonly,
  labelPosition = "top",
  labelFontSize,
  labelWeight,
  uppercase,
  formik,
  name,
  onBlur,
  ...otherProps
}) => {
  const meta = formik && formik.getFieldMeta(name);
  const hasError = meta && meta.touched && meta.error;

  return (
    <>
      <Label
        title={label}
        position={labelPosition}
        fluid={fluid}
        disabled={disabled}
        labelFontSize={labelFontSize}
        labelWeight={labelWeight}
        uppercase={uppercase}>
        <StyledTextInput
          hasError={hasError}
          disabled={disabled}
          isreadonly={isreadonly}
          onBlur={() => (formik ? formik.setFieldTouched(name, true) : onBlur)}
          {...otherProps}
        />
      </Label>
      {hasError && <ErrorMessage position={labelPosition}>{meta.error}</ErrorMessage>}
    </>
  );
};

export default TextInput;
