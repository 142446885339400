import styled from "styled-components";

import { ButtonPrimaryLink, ButtonSecondaryLink } from "@components/Button";
import Layout from "@components/Layout";

import { Row } from "@styles/grid";

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { getAllRoles } from "@actions/roles.action";
import RolesTable from "./RolesTable";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem auto 5.5rem;
  max-width: 125rem;
  width: 100%;
  padding: 0 2.5rem;
`;

const Roles = () => {
  const { roles } = useSelector(({ users }) => users);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (!roles || !roles.length) {
        await dispatch(getAllRoles());
      }
    })();
  }, [roles]);

  return (
    <Layout pageTitle="Users">
      <Content>
        <Row justify="space-between" margin="2rem 0">
          <ButtonSecondaryLink outlined uppercase href="/">
            Back to Dashboard
          </ButtonSecondaryLink>
          <ButtonPrimaryLink uppercase href="/roles/add">
            Add role
          </ButtonPrimaryLink>
        </Row>
        <RolesTable />
      </Content>
    </Layout>
  );
};

export default Roles;
