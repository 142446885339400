import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";
import { Wrapper, Content, Close } from "./Notice.styled";
import { clearNotice } from "@actions/notice.action";

const NoticePortal = ({ type, message, onClose }) => {
  const portalElement = document.getElementById("notice-portal");

  const noticeMarkup = (
    <Wrapper type={type}>
      <Close type={type} onClick={onClose}>
        ✕
      </Close>
      <Content>{message}</Content>
    </Wrapper>
  );

  return createPortal(noticeMarkup, portalElement);
};

const Notice = () => {
  const [display, setDisplay] = useState(null);

  const dispatch = useDispatch();
  const { message, delay, type } = useSelector(state => state.notice);

  const closeNoticeWithDuration = () => {
    setDisplay(false);
    setTimeout(() => {
      dispatch(clearNotice());
    }, 300);
  };

  useEffect(() => {
    setDisplay(false);

    if (message) {
      setDisplay(true);

      let timer = setTimeout(() => {
        closeNoticeWithDuration();
      }, delay);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [message]);

  return (
    <CSSTransition in={display} className="notice" classNames="notice" unmountOnExit timeout={300}>
      <NoticePortal type={type} onClose={() => closeNoticeWithDuration()} message={message} />
    </CSSTransition>
  );
};

export default Notice;
