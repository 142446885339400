import jwt from "jsonwebtoken";
import { AUTH_SET_TOKEN, SET_AUTH_ERROR, REQ_AUTH, AUTH_LOGOUT } from "../types/auth.types";
import { get, post } from "@api";
import { setMe } from "./me.action";
import { setNotice } from "./notice.action";
import { USER_ROLES } from "@constants";

export const reqAuth = () => dispatch =>
  dispatch({
    type: REQ_AUTH,
  });

export const setToken = token => dispatch =>
  dispatch({
    type: AUTH_SET_TOKEN,
    token,
  });

export const callLogout = () => dispatch =>
  dispatch({
    type: AUTH_LOGOUT,
  });

export const setAuthError = error => dispatch =>
  dispatch({
    type: SET_AUTH_ERROR,
    error,
  });

export const setUserData = () => dispatch => {
  const token = localStorage.getItem("token");

  if (token) {
    dispatch(setToken(token));

    const { id, email, role, name, schools } = jwt.decode(token);
    const user = {
      id,
      name,
      email,
      role,
      schools: schools.map(school => school.id),
    };

    if (user.role !== USER_ROLES.college) {
      delete user.schools;
    }

    dispatch(setMe(user));
    localStorage.setItem("user", JSON.stringify(user));
  }
};

export const login = credentials => async dispatch => {
  try {
    dispatch(reqAuth());

    const response = await post("auth/login", credentials);
    const token = response.data.token;
    const { id, email, role, name, schools } = jwt.decode(token);
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify({ id, email, role, name, schools }));

    dispatch(setUserData());
    return response;
  } catch (error) {
    dispatch(setAuthError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const logout = () => async dispatch => {
  try {
    localStorage.removeItem("token");
    localStorage.removeItem("user");

    dispatch(callLogout());
  } catch (error) {
    dispatch(setAuthError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const verifyMe = () => async dispatch => {
  try {
    await get("auth/verify");
  } catch (error) {
    dispatch(logout());
  }
};
