import styled from "styled-components";
import theme, { media } from "@styles";

export const WrapperBlank = styled.div`
  background: white;
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;
  }

  thead {
    box-shadow: 0px 2px 6px ${theme.colorShadow};
  }

  th {
    color: #44566c;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5rem;
    padding: 1rem 0;
    user-select: none;

    &:first-child {
      padding: 1rem 0 1rem 3rem;

      ${media.tablet`
        padding: 1rem;
      `}
    }
  }

  tbody {
    padding: 0 1.5rem;

    tr {
      border-bottom: 1px solid #cbcbcb;
      font-size: 1.5rem;
      font-weight: 500;

      td {
        padding: 1rem 0;

        &:first-child {
          padding: 1rem 1rem 1rem 3rem;

          ${media.tablet`
            padding: 1rem;
          `}
        }
      }
    }
  }
`;

export const Wrapper = styled.div`
  background: white;
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid ${theme.colorBorder};
  }

  thead {
    box-shadow: 0px 2px 6px ${theme.colorShadow};
    background-color: ${theme.colorBlue300};
  }

  th {
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5rem;
    padding: 1rem 0;
    user-select: none;
    vertical-align: top;

    &:first-child {
      padding: 1rem 1rem 1rem 3rem;

      ${media.tablet`
        padding: 1rem;
      `}
    }
  }

  tbody {
    padding: 0 1.5rem;

    tr {
      font-size: 1.5rem;
      font-weight: 500;

      &:nth-child(2n) {
        background-color: #d0e4ed87;
      }

      td {
        padding: 1rem 0;

        &:first-child {
          padding: 1rem 1rem 1rem 3rem;

          ${media.tablet`
            padding: 1rem;
          `}
        }
      }
    }
  }
`;

export const Pagination = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
