import styled from "styled-components";

import Layout from "@components/Layout";

import { Container } from "@styles/grid";

import PartnerCategoriesViewForm from "./PartnerCategoriesViewForm";
import { getPartnerCategoryById } from "@actions/partnerCategories.action";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 5.5rem 5.5rem;
`;

const PartnerCategoriesView = () => {
  const [partnerCategory, setPartnerCategory] = useState(null);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    (async () => {
      setPartnerCategory(await dispatch(getPartnerCategoryById(params.id)));
    })();
  }, [params.id]);

  return (
    <Layout pageTitle={`${partnerCategory ? partnerCategory.id : ""} Partner Category`}>
      <Container>
        <Content>
          <PartnerCategoriesViewForm partnerCategory={partnerCategory} />
        </Content>
      </Container>
    </Layout>
  );
};

export default PartnerCategoriesView;
