import { get, post, put, deleteById } from "@api";
import moment from "moment";

import {
  SET_USERS,
  SET_CREATED_USER,
  SET_UPDATED_USER,
  REMOVE_DELETED_USER,
  SET_USER_ERROR,
  SET_USERS_LOADING,
  SET_USERS_FETCHING,
  DELETE_USERS_FETCHING,
} from "../types/user.types";

import { setNotice } from "./notice.action";

export const setUsersLoading = () => dispatch =>
  dispatch({
    type: SET_USERS_LOADING,
  });

export const setUsersFetching = () => dispatch =>
  dispatch({
    type: SET_USERS_FETCHING,
  });
export const deleteUsersFetching = () => dispatch =>
  dispatch({
    type: DELETE_USERS_FETCHING,
  });

export const setUsers = users => dispatch =>
  dispatch({
    type: SET_USERS,
    users,
  });

export const setCreatedUser = user => dispatch =>
  dispatch({
    type: SET_CREATED_USER,
    user,
  });

export const setUpdatedUser = updatedUser => dispatch =>
  dispatch({
    type: SET_UPDATED_USER,
    updatedUser,
  });

export const removeDeletedUser = userId => dispatch =>
  dispatch({
    type: REMOVE_DELETED_USER,
    userId,
  });

export const setUsersError = error => dispatch =>
  dispatch({
    type: SET_USER_ERROR,
    error,
  });

export const getAllUsers = (include = "") => async dispatch => {
  try {
    dispatch(setUsersFetching());
    const response = await get(`/users?include=${include}`);

    const users = response.map(user => ({
      ...user,
      role: user.role.name,
      schools_count: !user.schools.length ? "No" : "Yes",
      partner: user.partner ? user.partner.name : "",
      created_at: moment(user.created_at).format("LLL"),
      updated_at: moment(user.updated_at).format("LLL"),
    }));

    dispatch(setUsers(users));

    return users;
  } catch (error) {
    dispatch(setUsersError(error.message));
  }
};

export const getUserById = (userId, include = "") => async dispatch => {
  try {
    dispatch(setUsersFetching());
    const user = await get(`/users/${userId}?include=${include}`);
    dispatch(deleteUsersFetching());
    return user;
  } catch (error) {
    dispatch(setUsersError(error.message));
  }
};

export const createUser = user => async dispatch => {
  try {
    dispatch(setUsersLoading());

    const createdUser = await post("users", user);

    dispatch(setCreatedUser(createdUser.data));
    dispatch(setNotice({ message: createdUser.message, type: "success" }));

    return createdUser;
  } catch (error) {
    dispatch(setUsersError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const setUserViews = users => () => {
  try {
    post("users/views", users);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateUser = (userId, user) => async dispatch => {
  try {
    dispatch(setUsersLoading());

    const updatedUser = await put(`users/${userId}`, user);

    dispatch(setUpdatedUser(updatedUser.data));
    dispatch(setNotice({ message: updatedUser.message, type: "success" }));

    return updatedUser;
  } catch (error) {
    dispatch(setUsersError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const deleteUserById = userId => async dispatch => {
  try {
    dispatch(setUsersLoading());

    const deletedUser = await deleteById("users", userId);

    dispatch(removeDeletedUser(userId));
    dispatch(setNotice({ message: deletedUser.message, type: "success" }));

    return deletedUser;
  } catch (error) {
    dispatch(setUsersError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const getUsersByName = (name, selected) => async () => {
  try {
    const selectedUsers = selected && selected.length ? `&selected=${selected.join(",")}` : "";

    const users = await get(`users/search/q?name=${name}${selectedUsers}`);

    return users;
  } catch (error) {
    console.error(error.message);
  }
};
