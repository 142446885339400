import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { Row } from "@styles/grid";
import TextInput from "@components/inputs/TextInput";
import Checkbox from "@components/inputs/Checkbox";
import { ButtonPrimary, ButtonSecondary, ButtonSecondaryLink } from "@components/Button";
import { Form, FormButtons } from "@styles/form";
import { Span } from "@styles/typography";
import { emptyIfNull } from "@utils/string";
import { updatePartner } from "@actions/partners.action";
import { partnerValidationSchema } from "@utils/validation";
import { useHistory } from "react-router";
import slugify from "slugify";

const PartnerViewForm = ({ partner }) => {
  const [partnerState, setPartnerState] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.partners.loading);

  useEffect(() => {
    if (partner) {
      setPartnerState(partner);
    }
  }, [partner]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: partnerState ? emptyIfNull(partnerState.name) : "",
      slug: partnerState ? emptyIfNull(partnerState.slug) : "",
      walmart_button: partnerState ? emptyIfNull(partnerState.iframe.walmart_button) : "",
      amazon_button: partnerState ? emptyIfNull(partnerState.iframe.amazon_button) : "",
      with_ccl_brand: partnerState ? emptyIfNull(partnerState.iframe.with_ccl_brand) : "",
    },
    validationSchema: partnerValidationSchema,
    onSubmit: async ({ name, slug, walmart_button, amazon_button, with_ccl_brand }) => {
      try {
        const newPartner = {
          name,
          slug,
          walmart_button,
          amazon_button,
          with_ccl_brand,
        };

        const savedPartner = await dispatch(updatePartner(partner.id, newPartner));

        if (savedPartner.data) {
          setPartnerState(savedPartner.data);
          setEditMode(false);
          return history.push("/partners");
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
  });

  const handleCancel = () => {
    setEditMode(prevMode => !prevMode);

    formik.setValues({
      name: partnerState ? emptyIfNull(partnerState.name) : "",
      slug: partnerState ? emptyIfNull(partnerState.slug) : "",
      walmart_button: partnerState ? emptyIfNull(partnerState.iframe.walmart_button) : "",
      amazon_button: partnerState ? emptyIfNull(partnerState.iframe.amazon_button) : "",
      with_ccl_brand: partnerState ? emptyIfNull(partnerState.iframe.with_ccl_brand) : "",
    });
  };

  useEffect(() => {
    if (formik.values.name) {
      formik.setFieldValue("slug", slugify(formik.values.name, { lower: true }));
    }
  }, [formik.values.name]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextInput
        label="Name"
        labelPosition="left"
        fluid
        placeholder={editMode ? "Partner Name" : ""}
        name="name"
        id="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        formik={formik}
        isreadonly={!editMode}
        readOnly={!editMode}
      />
      <TextInput
        label="Slug"
        labelPosition="left"
        fluid
        placeholder={editMode ? "Partner Slug" : ""}
        name="slug"
        id="slug"
        value={formik.values.slug}
        onChange={formik.handleChange}
        formik={formik}
        isreadonly={!editMode}
        readOnly={!editMode}
      />

      <Row margin="1rem 0">
        <Checkbox
          name="walmart_button"
          id="walmart_button"
          value={formik.values.walmart_button}
          checked={formik.values.walmart_button}
          onClick={!editMode ? () => null : formik.handleChange}
          formik={formik}
        />
        <Span size={1.8} margin="0 1rem">
          Walmart button display turn on / off
        </Span>
      </Row>

      <Row margin="1rem 0">
        <Checkbox
          name="amazon_button"
          id="amazon_button"
          value={formik.values.amazon_button}
          checked={formik.values.amazon_button}
          onClick={!editMode ? () => null : formik.handleChange}
          formik={formik}
        />
        <Span size={1.8} margin="0 1rem">
          Amazon button display turn on / off
        </Span>
      </Row>

      <Row margin="1rem 0">
        <Checkbox
          name="with_ccl_brand"
          id="with_ccl_brand"
          value={formik.values.with_ccl_brand}
          checked={formik.values.with_ccl_brand}
          onClick={!editMode ? () => null : formik.handleChange}
          formik={formik}
        />
        <Span size={1.8} margin="0 1rem">
          CCL app logo display on / off{" "}
        </Span>
      </Row>
      <Row margin="3rem 0" direction="column" align="flex-end">
        <FormButtons>
          {!editMode && (
            <ButtonSecondaryLink type="button" outlined uppercase href="/partners">
              Back to Partners
            </ButtonSecondaryLink>
          )}
          <ButtonSecondary type="button" uppercase onClick={handleCancel}>
            {editMode ? "cancel" : "Edit"}
          </ButtonSecondary>
          {editMode && (
            <ButtonPrimary type="submit" onClick={formik.handleSubmit} uppercase disabled={loading}>
              Save Partner
            </ButtonPrimary>
          )}
        </FormButtons>
      </Row>
    </Form>
  );
};

export default PartnerViewForm;
