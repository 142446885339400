import React, { useEffect } from "react";

//import { useSelector } from "react-redux";
import styled from "styled-components";

import { ButtonPrimaryLink, ButtonSecondaryLink } from "@components/Button";
import Layout from "@components/Layout";

import { Row } from "@styles/grid";
import ApiApplicationsTable from "./ApiApplicationsTable";
import { useDispatch, useSelector } from "react-redux";
import { getAllApiApplications } from "@actions/apiApplications.action";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem auto 5.5rem;
  max-width: 125rem;
  width: 100%;
  padding: 0 2.5rem;
`;

const ApiApplications = () => {
  const dispatch = useDispatch();
  const { apiApplications } = useSelector(({ apiApplications }) => apiApplications);

  useEffect(() => {
    (async () => {
      const apiApplicationsNotEmpty = apiApplications && apiApplications.length;
      if (!apiApplicationsNotEmpty) {
        await dispatch(getAllApiApplications());
      }
    })();
  }, []);

  return (
    <Layout pageTitle="Api Applications">
      <Content>
        <Row justify="space-between" margin="2rem 0">
          <ButtonSecondaryLink outlined uppercase href="/">
            Back to Dashboard
          </ButtonSecondaryLink>
          <ButtonPrimaryLink uppercase href="/api-applications/add">
            Add API Application
          </ButtonPrimaryLink>
        </Row>
        <ApiApplicationsTable />
      </Content>
    </Layout>
  );
};

export default ApiApplications;
