import React, { useMemo } from "react";
import { useTable, useSortBy, usePagination, useFilters, useGlobalFilter } from "react-table";
import { Wrapper, Pagination } from "./Table.styled";
import { Span } from "@styles/typography";
import { Row, Indent } from "@styles/grid";
import { ButtonDefault } from "@components/Button";
import Loader from "@components/Loader";
import TextInput from "@components/inputs/TextInput";
import Select from "@components/inputs/Select";
import Number from "@components/inputs/Number";
//import ReactPaginate from "react-paginate";

const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
  const count = preGlobalFilteredRows.length;

  return (
    <Row margin="0 0 0 -1.5rem" align="center">
      <Indent margin="0 1.5rem 0">
        <Span color="white" weight="bold">
          Search:{" "}
        </Span>
      </Indent>
      <TextInput
        value={globalFilter || ""}
        onChange={e => {
          setGlobalFilter(e.target.value || undefined);
        }}
        placeholder={`${count} records...`}
        fluid
      />
    </Row>
  );
};

const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter } }) => {
  const count = preFilteredRows.length;

  return (
    <div onClick={event => event.stopPropagation()} style={{ margin: "1rem 0", paddingRight: "1rem" }}>
      <TextInput
        value={filterValue || ""}
        onChange={event => {
          setFilter(event.target.value || undefined);
        }}
        placeholder={`Search ${count} records...`}
      />
    </div>
  );
};

const Table = ({ columns, data = [], hasGlobalFilter, loading }) => {
  const filterTypes = useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <Wrapper>
      <table {...getTableProps()}>
        <thead>
          {hasGlobalFilter && (
            <tr>
              <th
                colSpan={visibleColumns.length}
                style={{
                  textAlign: "left",
                }}>
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </th>
            </tr>
          )}
          {headerGroups.map((headerGroup, index) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th key={index} style={{ width: column.width }} {...column.getHeaderProps()}>
                  <div
                    {...(column.isSorting ? column.getSortByToggleProps() : {})}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      cursor: column.isSorting ? "pointer" : "unset",
                    }}>
                    <div>
                      {column.render("Header")}
                      <span style={{ marginLeft: 10 }}>
                        {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                      </span>
                    </div>
                    <div>{column.isFiltered && column.canFilter ? column.render("Filter") : null}</div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {loading ? (
          <tbody>
            <tr>
              <td colSpan="100%">
                <Loader />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, i) => {
                    return (
                      <td key={i} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {/* <PaginationContainer>
        <ReactPaginate containerClassName={'paginator'}/>
      </PaginationContainer> */}
      <Pagination>
        <div>
          <ButtonDefault size="small" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </ButtonDefault>{" "}
          <ButtonDefault size="small" onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </ButtonDefault>{" "}
          <ButtonDefault size="small" onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </ButtonDefault>{" "}
          <ButtonDefault size="small" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {">>"}
          </ButtonDefault>
        </div>
        <Span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </Span>
        <div>
          <Span>
            Go to page:{" "}
            <Number
              type="number"
              defaultValue={pageIndex + 1}
              onChange={event => {
                const page = event.target.value ? parseInt(event.target.value) - 1 : 0;
                gotoPage(page);
              }}
              withArrows
              color="transparent"
              width="7rem"
            />
          </Span>
          <Indent margin="1rem 0 0">
            <Select
              id="table-page-size"
              value={{ value: pageSize, label: `Show ${pageSize}` }}
              onChange={({ value }) => setPageSize(value)}
              placeholder="Items per page"
              options={[10, 20, 30, 40, 50].map(size => ({
                label: `Show ${size}`,
                value: size,
              }))}
            />
          </Indent>
        </div>
      </Pagination>
    </Wrapper>
  );
};

export default Table;
