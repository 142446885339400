import React from "react";
import styled from "styled-components";

import Layout from "@components/Layout";
import SchoolAddForm from "./SchoolAddForm";

import { Container } from "@styles/grid";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 5.5rem 5.5rem;
`;

const SchoolsAdd = () => (
  <Layout pageTitle="Add School">
    <Container>
      <Content>
        <SchoolAddForm />
      </Content>
    </Container>
  </Layout>
);

export default SchoolsAdd;
