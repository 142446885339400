import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import styled from "styled-components";

import Layout from "@components/Layout";

import { Container } from "@styles/grid";

import { useDispatch } from "react-redux";
import { getRoleById } from "@actions/roles.action";
import RoleViewForm from "./RoleViewForm";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 5.5rem 5.5rem;
`;

const RoleView = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [role, setRole] = useState(null);

  useEffect(() => {
    (async () => {
      setRole(await dispatch(getRoleById(params.id)));
    })();
  }, []);

  return (
    <Layout pageTitle={`${role ? role.name : ""} Role`}>
      <Container>
        <Content>
          <RoleViewForm role={role} />
        </Content>
      </Container>
    </Layout>
  );
};

export default RoleView;
