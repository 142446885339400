import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { Row, Indent } from "@styles/grid";
import Select from "@components/inputs/Select";
import TextInput from "@components/inputs/TextInput";
import Textarea from "@components/inputs/Textarea";
import Checkbox from "@components/inputs/Checkbox";
import { ButtonPrimary, ButtonSecondary, ButtonSecondaryLink } from "@components/Button";
import { Form, FormButtons } from "@styles/form";
import { updatedPartnerCategory } from "@actions/partnerCategories.action";
import { getAllBrands } from "@actions/brands.action";
import { getAllPartners } from "@actions/partners.action";
import { nullIfEmpty, emptyIfNull } from "@utils/string";
import { partnerCategoryValidationSchema } from "@utils/validation";

const PartnerCategoriesViewForm = ({ partnerCategory }) => {
    const [partnerCategoryState, setPartnerCategoryState] = useState(null);
    const [selectedSponsor, setSelectedSponsor] = useState(null);
    const [selectedPartner, setSelectedPartner] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [partners, setPartners] = useState([]);

    const dispatch = useDispatch();
    const loading = useSelector(state => state.partnerCategories.loading);
    const brands = useSelector(state => state.brands.brands);

    useEffect(() => {
        if (partnerCategory) {
            setPartnerCategoryState(partnerCategory);
        }
    }, [partnerCategory]);

    useEffect(() => {
        const getBrands = async () => {
            const isBrandsNotEmpty = brands && brands.length;

            if (!isBrandsNotEmpty) {
                await dispatch(getAllBrands());
            }
        };

        const getPartners = async () => {
            const isPartnersNotEmpty = partners && partners.length;

            if (!isPartnersNotEmpty) {
                const data = await dispatch(getAllPartners());
                setPartners(data);
            }
        };

        getBrands();
        getPartners();
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: partnerCategoryState ? emptyIfNull(partnerCategoryState.name) : "",
            tooltip: partnerCategoryState ? emptyIfNull(partnerCategoryState.tooltip) : "",
            selectable: partnerCategoryState ? partnerCategoryState.selectable : false,
            brand_id: partnerCategoryState ? partnerCategoryState.brand_id : null,
            partner_id: partnerCategoryState ? partnerCategoryState.partner_id : null,
        },
        validationSchema: partnerCategoryValidationSchema,
        onSubmit: async ({ name, selectable, tooltip, brand_id, partner_id }) => {
            try {
                const newPartnerCategory = {
                    name,
                    selectable,
                    tooltip: nullIfEmpty(tooltip),
                    brand_id,
                    partner_id,
                };

                const savedPartnerCategory = await dispatch(updatedPartnerCategory(partnerCategory.id, newPartnerCategory));

                if (savedPartnerCategory.data) {
                    setPartnerCategoryState(savedPartnerCategory.data);
                    setEditMode(false);
                }
            } catch (error) {
                throw new Error(error.message);
            }
        },
    });

    const handleSelectSponsor = brand => {
        formik.setFieldValue("brand_id", brand.value);
        setSelectedSponsor(brand);
    };

    const handleSelectPartner = partner => {
        formik.setFieldValue("partner_id", partner.value);
        setSelectedPartner(partner);
    };

    const handleCancel = () => {
        setEditMode(prevMode => !prevMode);

        formik.setValues({
            name: partnerCategoryState ? emptyIfNull(partnerCategoryState.name) : "",
            tooltip: partnerCategoryState ? emptyIfNull(partnerCategoryState.tooltip) : "",
            selectable: partnerCategoryState ? !!partnerCategoryState.selectable : false,
            brand_id: partnerCategoryState ? partnerCategoryState.brand_id : null,
            partner_id: partnerCategoryState ? partnerCategoryState.partner_id : null,
        });
    };

    const brandsSelectOptions =
        brands && brands.length
            ? [
                { value: null, label: "None" },
                ...brands.map(brand => ({
                    value: brand.id,
                    label: brand.name,
                })),
            ]
            : [];

    const partnersSelectOptions =
        partners && partners.length
            ? [
                { value: null, label: "None" },
                ...partners.map(partner => ({
                    value: partner.id,
                    label: partner.name,
                })),
            ]
            : [];

    const foundedSponsor = partnerCategoryState && brands.find(({ id }) => id === partnerCategoryState.brand_id);
    const sponsorSelectValue = foundedSponsor && {
        value: foundedSponsor.id,
        label: foundedSponsor.name,
    };

    const foundedPartner = partnerCategoryState && partners.find(({ id }) => id === partnerCategoryState.partner_id);
    const partnerSelectValue = foundedPartner && {
        value: foundedPartner.id,
        label: foundedPartner.name,
    };

    return (
        <Form onSubmit={formik.handleSubmit}>
            {editMode ? (
                <Select
                    label="Partner"
                    labelPosition="left"
                    placeholder="Select Partner"
                    options={partnersSelectOptions}
                    value={selectedPartner || partnerSelectValue}
                    onChange={handleSelectPartner}
                    fluid
                    formik={formik}
                    name="partner_id"
                    id="partner_id"
                />
            ) : (
                <TextInput
                    label="Partner"
                    labelPosition="left"
                    fluid
                    value={partnerSelectValue ? partnerSelectValue.label : ""}
                    isreadonly={!editMode}
                    readOnly={!editMode}
                />
            )}
            <TextInput
                label="Name"
                labelPosition="left"
                fluid
                placeholder={editMode ? "Category Name" : ""}
                name="name"
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                formik={formik}
                isreadonly={!editMode}
                readOnly={!editMode}
            />
            {editMode ? (
                <Select
                    label="Sponsor"
                    labelPosition="left"
                    placeholder="Select Sponsor"
                    options={brandsSelectOptions}
                    value={selectedSponsor || sponsorSelectValue}
                    onChange={handleSelectSponsor}
                    fluid
                    formik={formik}
                    name="brand_id"
                    id="brand_id"
                />
            ) : (
                <TextInput
                    label="Sponsor"
                    labelPosition="left"
                    fluid
                    value={sponsorSelectValue ? sponsorSelectValue.label : ""}
                    isreadonly={!editMode}
                    readOnly={!editMode}
                />
            )}
            <Textarea
                label="Tooltip"
                labelPosition="left-top"
                fluid
                placeholder={editMode ? "Category Tooltip" : ""}
                name="tooltip"
                id="tooltip"
                value={formik.values.tooltip}
                onChange={formik.handleChange}
                formik={formik}
                isreadonly={!editMode}
                readOnly={!editMode}
            />
            <Indent margin="0 0 0 auto">
                <Checkbox
                    label="Selectable"
                    name="selectable"
                    id="selectable"
                    labelFontSize={1.2}
                    value={formik.values.selectable}
                    checked={formik.values.selectable}
                    onClick={!editMode ? () => null : formik.handleChange}
                    formik={formik}
                    readOnly={!editMode}
                />
            </Indent>
            <Row margin="3rem 0" direction="column" align="flex-end">
                <FormButtons>
                    {!editMode && (
                        <ButtonSecondaryLink type="button" outlined uppercase href="/partnercategories">
                            Back to Partner Categories
                        </ButtonSecondaryLink>
                    )}
                    <ButtonSecondary type="button" uppercase onClick={handleCancel}>
                        {editMode ? "cancel" : "Edit"}
                    </ButtonSecondary>
                    {editMode && (
                        <ButtonPrimary type="submit" onClick={formik.handleSubmit} uppercase disabled={loading}>
                            Save Partner Category
                        </ButtonPrimary>
                    )}
                </FormButtons>
            </Row>
        </Form>
    );
};

export default PartnerCategoriesViewForm;
