import styled from "styled-components";
import theme, { colorsSchema } from "@styles";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const InputLabel = styled.label`
  display: inline-block;
  width: ${({ fluid }) => (fluid ? "100%" : "min-content")};
  padding: ${({ padding = "0.9rem 2.1rem 0.8rem" }) => padding};
  font-size: ${({ size = "normal" }) => (size === "normal" ? "1.5rem" : size === "small" ? "1rem" : "1.5rem")};
  font-weight: ${({ weight = "400" }) => weight};
  text-transform: ${({ uppercase = false }) => uppercase && "uppercase"};
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  transition: all 0.2s ease;
  background-color: transparent;
  border: 1px solid ${colorsSchema.primary};
  color: ${colorsSchema.primary};

  &:hover {
    background-color: ${theme.colorBlue100};
    border: 1px solid ${colorsSchema.colorBlue700};
  }
`;

export const ImageWrapper = styled.div`
  width: ${({ width = "20rem" }) => width};
  height: ${({ height = "15rem" }) => height};
  border: 1px solid ${theme.borderColor};
  border-radius: 3px;
  padding: 3px;
  margin: 1.5rem 0;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
