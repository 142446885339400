import { SET_NOTICE, CLEAR_NOTICE } from "../types/notice.types";

export const setNotice = ({ type = "info", message = "", delay = 4000 }) => dispatch =>
  dispatch({
    type: SET_NOTICE,
    notice: { type, message, delay },
  });

export const clearNotice = () => dispatch =>
  dispatch({
    type: CLEAR_NOTICE,
  });
