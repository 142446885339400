import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Row, Indent } from "@styles/grid";
import TextInput from "@components/inputs/TextInput";
import { ButtonPrimary, ButtonSecondaryLink } from "@components/Button";
import { Form } from "@styles/form";
import { createPartnerProduct } from "@actions/partnerProducts.action";
import { getAllPartners } from "@actions/partners.action";
import { getAllProducts } from "@actions/products.action";
import { getAllPartnerCategories } from "@actions/partnerCategories.action";
import Select from "@components/inputs/Select";
import { partnerProductValidationSchema } from "@utils/validation";
import { nullIfEmpty } from "@utils/string";

const PartnerProductsAddForm = () => {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.partnerProducts.loading);
    const [partners, setPartners] = useState([]);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedPartner, setSelectPartner] = useState(0);
    const [selectedProduct, setSelectProduct] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(0);

    useEffect(() => {
        const getPartners = async () => {
            const isPartnersNotEmpty = partners && partners.length;

            if (!isPartnersNotEmpty) {
                const data = await dispatch(getAllPartners());
                setPartners(data);
            }
        };

        const getProducts = async () => {
            const isProductsNotEmpty = products && products.length;

            if (!isProductsNotEmpty) {
                const data = await dispatch(getAllProducts());
                setProducts(data);
            }
        };

        // Getting partner categories
        const getCategories = async () => {
            const isCategoriesNotEmpty = categories && categories.length;

            if (!isCategoriesNotEmpty) {
                const data = await dispatch(getAllPartnerCategories);
                setCategories(data);
            }
        };

        getCategories();
        getPartners();
        getProducts();
    }, []);

    useEffect(() => {
        setSelectProduct(0);
        setSelectedCategory(0);
    }, [selectedPartner])

    // Helper for categoriesSelectOptions
    const partnerCats = (cats, part) => {
        return cats.filter(cat => cat.partner_id === part.value);
    }

    let partnerCatsToBeMapped = [];
    if (selectedPartner && categories.length && categories) {
        partnerCatsToBeMapped = [...partnerCats(categories, selectedPartner)]
    }

    const categoriesSelectOptions =
        categories && categories.length && selectedPartner && partnerCatsToBeMapped.length
            ? [
                { value: null, label: "None" },
                ...partnerCatsToBeMapped.map(cat => ({
                    value: cat.id,
                    label: cat.name,
                })),
            ]
            : [{ value: null, label: "None" }];

    const handleSelectCategory = category => {
        setSelectedCategory({ label: category.label, value: category.value });
    };

    const partnersSelectOptions =
        partners && partners.length
            ? [
                ...partners.map(partner => ({
                    value: partner.id,
                    label: partner.name,
                })),
            ]
            : [];

    const handleSelectPartner = partner => {
        setSelectPartner({ label: partner.label, value: partner.value });
    };

    const productsSelectOptions =
        products && products.length
            ? [
                ...products.map(product => ({
                    value: product.id,
                    label: product.name,
                })),
            ]
            : [];

    const handleSelectProduct = product => {
        setSelectProduct({ label: product.label, value: product.value });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            upc: "",
            name_alias: null,
            partner_id: selectedPartner?.value ? selectedPartner.value : 0,
            product_id: selectedProduct?.value ? selectedProduct.value : 0,
            partner_category_id: selectedCategory?.value ? selectedCategory.value : null,
            order: 1,
            quantity: 1,
        },
        validationSchema: partnerProductValidationSchema,
        onSubmit: async ({ upc, partner_id, product_id, order, quantity, partner_category_id, name_alias }) => {
            try {
                const newPartnerProduct = {
                    name_alias: name_alias ? name_alias : null,
                    upc: upc,
                    partner_id: partner_id,
                    product_id: product_id,
                    partner_category_id: partner_category_id ? partner_category_id : null,
                    order: parseInt(order),
                    quantity: parseInt(quantity),
                };

                const savedPartnerProduct = await dispatch(createPartnerProduct(newPartnerProduct));

                if (savedPartnerProduct.message === "Product Partner successfully created") {
                    return (window.location.href = "/partnerproducts");
                }
            } catch (error) {
                throw new Error(error.message);
            }
        },
    });

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Select
                label="Partner"
                labelPosition="left"
                placeholder="Select Partner"
                options={partnersSelectOptions}
                value={selectedPartner}
                onChange={handleSelectPartner}
                fluid
                formik={formik}
                name="partner_id"
                id="partner_id"
            />

            <Select
                label="Product"
                labelPosition="left"
                placeholder="Select Product"
                options={productsSelectOptions}
                value={selectedProduct}
                onChange={handleSelectProduct}
                fluid
                formik={formik}
                name="product_id"
                id="product_id"
            />
            <Select
                label="Partner Category"
                labelPosition="left"
                placeholder="Select Category"
                options={categoriesSelectOptions}
                value={selectedCategory}
                onChange={handleSelectCategory}
                fluid
                formik={formik}
                disabled={(selectedPartner.value === 0 || selectedPartner === 0 || categories.length === 0) ? true : false}
                name="partner_category_id"
                id="partner_category_id"
            />
            <TextInput
                label="UPC"
                labelPosition="left"
                fluid
                placeholder="upc"
                name="upc"
                id="upc"
                value={formik.values.upc}
                onChange={formik.handleChange}
                formik={formik}
            />

            <TextInput
                label="Order"
                labelPosition="left"
                fluid
                placeholder="1"
                name="order"
                id="order"
                value={formik.values.order}
                onChange={formik.handleChange}
                formik={formik}
            />

            <TextInput
                label="Name Alias"
                labelPosition="left"
                fluid
                placeholder="alias"
                name="name_alias"
                id="name_alias"
                value={formik.values.name_alias}
                onChange={formik.handleChange}
                formik={formik}
            />

            <TextInput
                label="Quantity"
                labelPosition="left"
                fluid
                placeholder="1"
                name="quantity"
                id="quantity"
                value={formik.values.quantity}
                onChange={formik.handleChange}
                formik={formik}
            />

            <Row margin="3rem 0" justify="flex-end">
                <Indent margin="0 2rem 0">
                    <ButtonSecondaryLink outlined uppercase href="/partnerproducts">
                        Back to partner Products
                    </ButtonSecondaryLink>
                </Indent>
                <ButtonPrimary type="submit" uppercase disabled={loading} onClick={formik.handleSubmit}>
                    Create Partner Product
                </ButtonPrimary>
            </Row>
        </Form>
    );
};

export default PartnerProductsAddForm;
