import React from "react";
import styled from "styled-components";

import { TextLink } from "@styles/typography";
import theme from "@styles";

import Layout from "@components/Layout";

import { Container, Indent } from "@styles/grid";
import { USER_ROLES } from "@constants";
import GuardComponent from "@components/GuardComponent";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem 5.5rem 5.5rem;

  > a {
    margin-bottom: 1.5rem;
  }
`;
const CustomTextLink = styled(TextLink)`
  margin-bottom: 4rem !important;
`;

const Admin = () => (
  <Layout pageTitle="Admin Dashboard">
    <Container>
      <Content>
        <GuardComponent roles={[USER_ROLES.admin]}>
          <CustomTextLink to="/api-applications" size={1.6} uppercase color={theme.colorBlue}>
            Api Applications
          </CustomTextLink>
        </GuardComponent>

        <TextLink to="/checklists" size={1.6} uppercase color={theme.colorBlue}>
          Checklists
        </TextLink>

        <GuardComponent roles={[USER_ROLES.admin]}>
          <TextLink to="/schools" size={1.6} uppercase color={theme.colorBlue}>
            Schools
          </TextLink>
          <TextLink to="/products" size={1.6} uppercase color={theme.colorBlue}>
            products
          </TextLink>
          <TextLink to="/categories" size={1.6} uppercase color={theme.colorBlue}>
            categories
          </TextLink>
          <TextLink to="/brands" size={1.6} uppercase color={theme.colorBlue}>
            brands
          </TextLink>
          <TextLink to="/partners" size={1.6} uppercase color={theme.colorBlue}>
            partners
          </TextLink>
          <TextLink to="/schools-partners" size={1.6} uppercase color={theme.colorBlue}>
            schools partners
          </TextLink>
          <TextLink to="/bundles" size={1.6} uppercase color={theme.colorBlue}>
            bundles
          </TextLink>
          <TextLink to="/partnercategories" size={1.6} uppercase color={theme.colorBlue}>
            partner categories
          </TextLink>
          <TextLink to="/partnerproducts" size={1.6} uppercase color={theme.colorBlue}>
            partner products
          </TextLink>
          <TextLink to="/buckets" size={1.6} uppercase color={theme.colorBlue}>
            buckets
          </TextLink>
          <TextLink to="/users" size={1.6} uppercase color={theme.colorBlue}>
            users
          </TextLink>
          <TextLink to="/roles" size={1.6} uppercase color={theme.colorBlue}>
            roles
          </TextLink>
          <Indent padding="2rem 0 0">
            <TextLink to="/sponsorship-products-list" size={1.6} uppercase color={theme.colorBlue}>
              sponsorship products list
            </TextLink>
          </Indent>
        </GuardComponent>
      </Content>
    </Container>
  </Layout>
);

export default Admin;
