import styled from "styled-components";

import Layout from "@components/Layout";

import { Container } from "@styles/grid";

import ProductViewForm from "./ProductViewForm";
import { getProductById } from "@actions/products.action";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 5.5rem 5.5rem;
`;

const ProductView = () => {
  const [product, setProduct] = useState(null);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    (async () => {
      setProduct(await dispatch(getProductById(params.id)));
    })();
  }, [params.id]);

  return (
    <Layout pageTitle={product ? product.name : "Product"}>
      <Container>
        <Content>
          <ProductViewForm product={product} />
        </Content>
      </Container>
    </Layout>
  );
};

export default ProductView;
