import React, { useState, useEffect } from "react";
import slugify from "slugify";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Row, Indent } from "@styles/grid";
import { Span } from "@styles/typography";
import TextInput from "@components/inputs/TextInput";
import TextArea from "@components/inputs/Textarea";
import Select from "@components/inputs/Select";
import { ButtonPrimary, ButtonSecondaryLink, ButtonRoundedPlus } from "@components/Button";
import { Form, SaveButtons, FormInputs } from "@styles/form";
import ChecklistAddFormProducts from "./ChecklistAddFormProducts";
import generateUUID from "@lib/generateUUID";
import { saveChecklist, updateChecklist } from "@actions/checklist.action";
import { getCollegesByState } from "@actions/colleges.action";
import { getStates } from "@actions/states.action";
import { setNotice } from "@actions/notice.action";
import { nullIfEmpty } from "@utils/string";
import { checklistValidationSchema } from "@utils/validation";
import { USER_ROLES } from "@constants";

const generateRenderProducts = length =>
  Array.from(Array(length)).map(() => ({
    key: generateUUID(),
    id: null,
    product_name: null,
    product_id: null,
    quantity: 1,
    memo: "",
    suggested: false,
  }));

const ChecklistAddForm = () => {
  const [savedChecklist, setSavedChecklist] = useState(null);
  const [school, setSchool] = useState(null);
  const [stateColleges, setStateColleges] = useState([]);
  const [renderProducts, setRenderProducts] = useState(generateRenderProducts(30));
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector(({ me }) => me);
  const loading = useSelector(state => state.checklists.loading);
  const states = useSelector(state => state.states.states);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      name: "",
      slug: "",
      description: "",
      not_bring: "",
      school_id: null,
      checklist_details: [],
      state: null,
    },
    validationSchema: checklistValidationSchema,
    onSubmit: () => {},
  });

  const state = formik.values.state;

  useEffect(() => {
    if (savedChecklist) {
      const savedRenderProducts = savedChecklist.checklist_details.map(
        ({ id, product, memo, quantity, suggested }) => ({
          key: generateUUID(),
          id,
          product_id: product.id,
          product_name: product.name,
          quantity,
          memo,
          suggested,
        })
      );
      const generatedRenderProducts =
        savedRenderProducts.length < 30
          ? [...savedRenderProducts, ...generateRenderProducts(30 - savedRenderProducts.length)]
          : [...savedRenderProducts];

      setRenderProducts([...generatedRenderProducts]);
    }
  }, [savedChecklist]);

  useEffect(() => {
    if (formik.values.name) {
      formik.setFieldValue("slug", slugify(formik.values.name, { lower: true }));
    }
  }, [formik.values.name]);

  useEffect(() => {
    (async () => {
      if (!states || !states.length) {
        if (me.role === USER_ROLES.college) {
          const userSchoolIds = `user_school_ids=${me.schools.join()}`;
          return await dispatch(getStates(userSchoolIds));
        }
        return await dispatch(getStates());
      }
    })();
  }, [states]);

  const saveChecklistToDatabase = async () => {
    try {
      await formik.submitForm();
      const validationErrors = await formik.validateForm(formik.values);

      if (Object.keys(validationErrors).length) {
        return;
      }

      const { name, slug, description, school_id, checklist_details, not_bring } = formik.values;
      const checklist = {
        name,
        slug,
        description: nullIfEmpty(description),
        school_id,
        not_bring: nullIfEmpty(not_bring),
        checklist_details,
      };

      const savedChecklistResponse = await dispatch(saveChecklist(checklist));

      setSavedChecklist(savedChecklistResponse.data);
      return savedChecklistResponse.data;
    } catch (error) {
      console.error(error);
    }
  };

  const updateAddedChecklist = async () => {
    try {
      await formik.submitForm();
      const validationErrors = await formik.validateForm(formik.values);

      if (Object.keys(validationErrors).length) {
        return;
      }

      const { name, slug, description, school_id, checklist_details, not_bring } = formik.values;
      const checklist = {
        name,
        slug,
        description: nullIfEmpty(description),
        school_id,
        not_bring: nullIfEmpty(not_bring),
        checklist_details,
      };

      const updatedChecklistResponse = await dispatch(updateChecklist(savedChecklist.id, checklist));

      setSavedChecklist(updatedChecklistResponse.data);
      return updatedChecklistResponse.data;
    } catch (error) {
      console.error(error);
    }
  };

  const saveOrUpdateChecklist = async () => {
    try {
      if (savedChecklist) {
        return await updateAddedChecklist();
      }
      return await saveChecklistToDatabase();
    } catch (error) {
      return new Error(error.message);
    }
  };

  const handleSaveAndCreate = async () => {
    try {
      const response = await saveOrUpdateChecklist();

      if (response) {
        dispatch(setNotice({ message: "Checklist successfully created", type: "success" }));
        return history.push("/checklists");
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const handleSaveChecklist = async event => {
    try {
      event.preventDefault();
      const response = await saveOrUpdateChecklist();

      if (response) {
        dispatch(setNotice({ message: "Checklist successfully saved", type: "success" }));
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const clearSchool = () => {
    formik.setFieldValue("school_id", null);
    setSchool(null);
  };

  const handleStateChange = async state => {
    formik.setFieldValue("state", state);
    clearSchool();
    const colleges = await dispatch(getCollegesByState(state.id));

    setStateColleges(colleges);
  };

  const handleCollegeChange = college => {
    setSchool(college);
    formik.setFieldValue("school_id", college.id);
  };

  const changeRenderProducts = (fields, key) =>
    renderProducts.map(renderProduct => {
      if (renderProduct.key !== key) {
        return { ...renderProduct };
      }

      return {
        ...renderProduct,
        ...fields,
      };
    });

  const setProductsToFormikValue = products => {
    formik.setFieldValue(
      "checklist_details",
      products
        .filter(({ product_id }) => product_id)
        .map(({ product_id, quantity, memo, suggested }) => ({ product_id, quantity, memo, suggested }))
    );

    setRenderProducts([...products]);
  };

  const onChangeProduct = (product, suggestionKey) => {
    const foundedProduct = changeRenderProducts(
      {
        product_id: product.id,
        product_name: product.name,
      },
      suggestionKey
    );

    setProductsToFormikValue(foundedProduct);
  };

  const quantityChange = (quantity, suggestionKey) => {
    const foundedProduct = changeRenderProducts({ quantity: +quantity }, suggestionKey);
    setProductsToFormikValue(foundedProduct);
  };

  const onClearProduct = suggestionKey => {
    const foundedProduct = changeRenderProducts(
      {
        product_id: null,
        product_name: null,
      },
      suggestionKey
    );

    setProductsToFormikValue(foundedProduct);
  };

  const onChangeMemo = (memo, suggestionKey) => {
    const foundedProduct = changeRenderProducts(
      {
        memo,
      },
      suggestionKey
    );

    setProductsToFormikValue(foundedProduct);
  };

  const onChangeSuggested = (suggested, suggestionKey) => {
    const foundedProduct = changeRenderProducts(
      {
        suggested,
      },
      suggestionKey
    );

    setProductsToFormikValue(foundedProduct);
  };

  const removeProduct = suggestionKey => {
    const copyOfRenderProducts = [...renderProducts];
    const foundedIndex = copyOfRenderProducts.findIndex(({ key }) => key === suggestionKey);
    copyOfRenderProducts.splice(foundedIndex, 1);

    setProductsToFormikValue(copyOfRenderProducts);
  };

  const addRenderProduct = () => {
    setRenderProducts(prevRenderProducts => [
      ...prevRenderProducts,
      {
        key: generateUUID(),
        id: null,
        product_id: null,
        product_name: null,
        quantity: 1,
        memo: "",
        suggested: false,
      },
    ]);
  };

  const collegeOptions =
    stateColleges && stateColleges.length
      ? stateColleges.map(college => ({ value: college.id, label: college.name, ...college }))
      : [];

  const selectedCollegeValue = school && {
    value: school.id,
    label: `${school.name}, ${school.location.split(",").join(" ")}`,
  };

  const statesOptions =
    states && states.length ? states.map(state => ({ value: state.id, label: state.name, ...state })) : [];

  const selectedStateValue = state && {
    value: state.id,
    label: state.name,
  };

  return (
    <Form>
      <FormInputs>
        <TextInput
          position
          label="Name"
          labelPosition="left"
          fluid
          placeholder="Checklist Name"
          value={formik.values.name}
          id="name"
          name="name"
          formik={formik}
          onChange={formik.handleChange}
        />
        <TextInput
          position
          label="Slug"
          labelPosition="left"
          fluid
          placeholder="Checklist Slug"
          value={formik.values.slug}
          id="slug"
          name="slug"
          formik={formik}
          onChange={formik.handleChange}
        />
        <TextArea
          label="Notes / Description"
          labelPosition="left-top"
          fluid
          placeholder="Checklist Notes / Description / What is provided by the college or university."
          value={formik.values.description}
          id="description"
          name="description"
          formik={formik}
          onChange={formik.handleChange}
        />
        <Select
          label="State"
          labelPosition="left"
          placeholder="Select State"
          options={statesOptions}
          value={selectedStateValue}
          onChange={handleStateChange}
          id="state"
          name="state"
          formik={formik}
          fluid
        />
        {state && (
          <Select
            label="School"
            labelPosition="left"
            placeholder="Select School"
            options={collegeOptions}
            value={selectedCollegeValue}
            onChange={handleCollegeChange}
            id="school_id"
            name="school_id"
            formik={formik}
            fluid
          />
        )}
      </FormInputs>
      {renderProducts && renderProducts.length ? (
        <ChecklistAddFormProducts
          renderProducts={renderProducts}
          onChangeProduct={onChangeProduct}
          quantityChange={quantityChange}
          onChangeMemo={onChangeMemo}
          onChangeSuggested={onChangeSuggested}
          removeProduct={removeProduct}
          onClearProduct={onClearProduct}
        />
      ) : null}

      {formik.errors.checklist_details && formik.touched.checklist_details && (
        <Span color="red" align="right" size={1.4}>
          {Array.isArray(formik.errors.checklist_details)
            ? formik.errors.checklist_details[formik.errors.checklist_details.length - 1][
                Object.keys(formik.errors.checklist_details[formik.errors.checklist_details.length - 1])
              ]
            : formik.errors.checklist_details}
        </Span>
      )}

      <Row margin="3rem 0" direction="column" align="flex-end">
        <Indent margin="0 0 4rem">
          <ButtonRoundedPlus onClick={addRenderProduct} />
        </Indent>
      </Row>
      <TextArea
        label="Not to bring"
        labelPosition="left-top"
        fluid
        placeholder="Checklist Not to bring list"
        value={formik.values.not_bring}
        id="not_bring"
        name="not_bring"
        formik={formik}
        onChange={formik.handleChange}
      />
      <Row margin="3rem 0" direction="column" align="flex-end">
        <SaveButtons>
          <ButtonSecondaryLink outlined href="/checklists" uppercase disabled={loading}>
            Back to Checklists
          </ButtonSecondaryLink>
          <ButtonPrimary type="submit" onClick={handleSaveChecklist} uppercase disabled={loading}>
            Save Checklist
          </ButtonPrimary>
          <ButtonPrimary type="submit" onClick={handleSaveAndCreate} uppercase disabled={loading}>
            Save and Create
          </ButtonPrimary>
        </SaveButtons>
      </Row>
    </Form>
  );
};

export default ChecklistAddForm;
