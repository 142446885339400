import React from "react";
import { useEffect, forwardRef } from "react";
import { useDispatch } from "react-redux";

import { Wrapper, Main, Content, BackgroundImage } from "./Layout.styled";
import { verifyMe } from "@actions/auth.action";
import UserHeader from "../UserHeader";

const Layout = forwardRef(({ children, pageTitle, initialScrollToTop = true }, ref) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(verifyMe());
  }, []);

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [initialScrollToTop]);

  return (
    <Wrapper>
      <Main>
        <UserHeader />
        <Content ref={ref}>{children}</Content>
        <BackgroundImage />
      </Main>
    </Wrapper>
  );
});

export default Layout;
