import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "@components/Table";
import { ButtonSecondary, ButtonPrimaryLink } from "@components/Button";
import { deleteCategoryById } from "@actions/categories.action";
import Modal from "@components/Modal";
import { Span } from "@styles/typography";
import { Row, Indent } from "@styles/grid";

const EditCategory = ({ categoryId }) => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.categories.loading);

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const handleDeleteCategory = () => async () => {
    try {
      await dispatch(deleteCategoryById(categoryId));
      setIsRemoveModalOpen(false);
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return (
    <Row justify="flex-end" padding="0 2rem 0 0">
      <Indent margin="0 1.5rem 0 0">
        <ButtonPrimaryLink uppercase href={`/categories/${categoryId}`} outlined size="small" weight="bold">
          edit
        </ButtonPrimaryLink>
      </Indent>
      <ButtonSecondary uppercase onClick={() => setIsRemoveModalOpen(true)} size="small" weight="bold">
        Remove
      </ButtonSecondary>

      <Modal isOpen={isRemoveModalOpen} onClose={() => setIsRemoveModalOpen(false)} title="Remove Category">
        <Indent margin="4rem 0">
          <Span size={1.8}>Are you sure you want to delete this category?</Span>
        </Indent>
        <Row justify="flex-end">
          <Indent margin="0 2rem 0">
            <ButtonSecondary outlined onClick={() => setIsRemoveModalOpen(false)} uppercase>
              Close
            </ButtonSecondary>
          </Indent>
          <ButtonSecondary autoFocus onClick={handleDeleteCategory()} uppercase disabled={loading}>
            Remove
          </ButtonSecondary>
        </Row>
      </Modal>
    </Row>
  );
};

const CategoriesTable = () => {
  const { categories, fetching } = useSelector(({ categories }) => categories);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        isFiltered: true,
      },
      {
        Header: "Selectable",
        accessor: "selectable",
        isSorting: true,
        Cell: ({ row }) => <span>{row.original.selectable ? "Yes" : "No"}</span>,
      },
      {
        Header: "Sponsor",
        accessor: "brand.name",
        isSorting: true,
        isFiltered: true,
      },
      {
        id: "colEdit",
        Cell: ({ row }) => <EditCategory categoryId={row.original.id} />,
      },
    ],
    []
  );

  const data = useMemo(() => categories, [categories]);

  return <Table columns={columns} data={data} hasGlobalFilter loading={fetching} />;
};

export default CategoriesTable;
