import React from "react";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import styled from "styled-components";

import Layout from "@components/Layout";
import { ButtonPrimary } from "@components/Button";

import { Container, Row } from "@styles/grid";
import TextInput from "@components/inputs/TextInput";
import { login } from "@actions/auth.action";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5.5rem;
`;

const Form = styled.form`
  max-width: 40rem;
  width: 100%;
`;

const Login = () => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);
  const history = useHistory();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email()
        .required("Email is required"),
      password: Yup.string()
        .min(6)
        .max(255)
        .required("Password is required"),
    }),
    onSubmit: async ({ email, password }) => {
      try {
        const response = await dispatch(login({ email, password }));

        if (response) {
          history.push("/");
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
  });

  return (
    <Layout pageTitle="Login">
      <Container>
        <Content>
          <Form onSubmit={formik.handleSubmit}>
            <TextInput
              type="email"
              label="email"
              placeholder="Please Type your email"
              name="email"
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              formik={formik}
            />
            <TextInput
              type="password"
              label="password"
              placeholder="Please Type your password"
              name="password"
              id="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              formik={formik}
            />
            <Row margin="3rem 0" justify="center">
              <ButtonPrimary type="submit" disabled={loading}>
                {loading ? "Wait..." : "Login"}
              </ButtonPrimary>
            </Row>
          </Form>
        </Content>
      </Container>
    </Layout>
  );
};

export default Login;
