import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useFormik } from "formik";
import { createRole } from "@actions/roles.action";
import { addRoleValidationSchema } from "@utils/validation";

import { Form } from "@styles/form";
import { Row, Indent } from "@styles/grid";
import TextInput from "@components/inputs/TextInput";
import Checkbox from "@components/inputs/Checkbox";
import { ButtonPrimary, ButtonSecondaryLink } from "@components/Button";

const RolesAddForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.products.loading);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      can_create: false,
      can_edit: false,
      can_access_api: false,
      can_access_admin: false,
    },
    validationSchema: addRoleValidationSchema,
    onSubmit: async ({ name, can_create, can_edit, can_access_api, can_access_admin }) => {
      try {
        const newRole = {
          name,
          can_create,
          can_edit,
          can_access_api,
          can_access_admin,
        };

        const savedRole = await dispatch(createRole(newRole));

        if (savedRole.data) {
          return history.push("/roles");
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextInput
        position
        label="Name"
        labelPosition="left"
        fluid
        placeholder="Roles Name"
        name="name"
        id="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        formik={formik}
      />

      <Indent margin="1rem 0">
        <Checkbox
          label="Can create"
          name="can_create"
          id="can_create"
          labelFontSize={1.8}
          value={formik.values.can_create}
          checked={formik.values.can_create}
          onClick={formik.handleChange}
          formik={formik}
        />
      </Indent>

      <Indent margin="1rem 0">
        <Checkbox
          label="Can edit"
          name="can_edit"
          id="can_edit"
          labelFontSize={1.8}
          value={formik.values.can_edit}
          checked={formik.values.can_edit}
          onClick={formik.handleChange}
          formik={formik}
        />
      </Indent>

      <Indent margin="1rem 0">
        <Checkbox
          label="Can access api"
          name="can_access_api"
          id="can_access_api"
          labelFontSize={1.8}
          value={formik.values.can_access_api}
          checked={formik.values.can_access_api}
          onClick={formik.handleChange}
          formik={formik}
        />
      </Indent>

      <Indent margin="1rem 0">
        <Checkbox
          label="Can access admin"
          name="can_access_admin"
          id="can_access_admin"
          labelFontSize={1.8}
          value={formik.values.can_access_admin}
          checked={formik.values.can_access_admin}
          onClick={formik.handleChange}
          formik={formik}
        />
      </Indent>
      <Row margin="3rem 0" justify="flex-end">
        <Indent margin="0 2rem 0">
          <ButtonSecondaryLink uppercase outlined href="/roles">
            back to roles
          </ButtonSecondaryLink>
        </Indent>
        <ButtonPrimary type="submit" uppercase disabled={loading} onClick={formik.handleSubmit}>
          Create role
        </ButtonPrimary>
      </Row>
    </Form>
  );
};

export default RolesAddForm;
