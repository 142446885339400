import { get, post, deleteById } from "@api";
import {
  SET_CHECKLIST_DETAILS,
  SET_SELECTED_CHECKLIST_DETAILS,
  CHANGE_SELECTED_CHECKLIST_DETAIL,
  REMOVE_SELECTED_CHECKLIST_DETAIL,
  SET_CHECKLIST_DETAILS_ERROR,
  CLEAR_CHECKLIST_DETAILS,
  SET_SPONSORSHIP_CHECKLIST_DETAILS,
  SET_CREATED_SPONSORSHIP_CHECKLIST_DETAIL,
  DELETE_SPONSORSHIP_CHECKLIST_DETAIL,
  SET_CHECKLIST_DETAILS_LOADING,
} from "../types/checklistDetails.types";
import { includeIfExist } from "@utils/string";
import { setNotice } from "./notice.action";

export const setChecklistDetailsLoading = () => dispatch =>
  dispatch({
    type: SET_CHECKLIST_DETAILS_LOADING,
  });

export const setSelectedChecklistDetails = selectedChecklistDetails => dispatch =>
  dispatch({
    type: SET_SELECTED_CHECKLIST_DETAILS,
    selectedChecklistDetails,
  });

export const changedSelectedChecklistDetail = changedChecklistDetail => dispatch =>
  dispatch({
    type: CHANGE_SELECTED_CHECKLIST_DETAIL,
    changedChecklistDetail,
  });

export const removeSelectedChecklistDetail = selectedId => dispatch =>
  dispatch({
    type: REMOVE_SELECTED_CHECKLIST_DETAIL,
    selectedId,
  });

export const removeSponsorshipChecklistDetail = sponsoredId => async dispatch => {
  try {
    dispatch(setChecklistDetailsLoading());

    const deletedSponsorshipDetail = await deleteById("checklist-details", sponsoredId);

    if (deletedSponsorshipDetail) {
      dispatch({
        type: DELETE_SPONSORSHIP_CHECKLIST_DETAIL,
        sponsoredId,
      });
      dispatch(
        setNotice({
          message: "Sponsorship" + deletedSponsorshipDetail.message.toLowerCase(),
          type: "success",
        })
      );
    }
  } catch (error) {
    dispatch(setChecklistDetailsError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const setChecklistDetails = checklistDetails => dispatch =>
  dispatch({
    type: SET_CHECKLIST_DETAILS,
    checklistDetails,
  });

export const setChecklistDetailsError = error => dispatch =>
  dispatch({
    type: SET_CHECKLIST_DETAILS_ERROR,
    error,
  });

export const clearChecklistDetails = () => dispatch =>
  dispatch({
    type: CLEAR_CHECKLIST_DETAILS,
  });

export const getChecklistDetailsByChecklistId = (checklistId, include) => async dispatch => {
  try {
    const checklistDetails = await get(`checklists/${checklistId}${includeIfExist("include", include)}`);

    dispatch(setChecklistDetails(checklistDetails.checklist_details));

    return checklistDetails.checklist_details;
  } catch (error) {
    dispatch(setChecklistDetailsError(error.message));
  }
};

export const getSponsoredChecklistDetailsByChecklistId = (checklistId, include) => async dispatch => {
  try {
    const checklistDetails = await get(`checklists/${checklistId}/sponsored${includeIfExist("include", include)}`);

    dispatch(setChecklistDetails(checklistDetails.checklist_details));

    return checklistDetails.checklist_details;
  } catch (error) {
    dispatch(setChecklistDetailsError(error.message));
  }
};

export const getAllSponsorshipProducts = () => async dispatch => {
  try {
    const sponsoredProductsList = await get(
      "checklist-details/sponsorship?include=product,product.category,product.brand"
    );

    if (sponsoredProductsList && sponsoredProductsList.length) {
      dispatch({
        type: SET_SPONSORSHIP_CHECKLIST_DETAILS,
        sponsoredProductsList,
      });
    }
  } catch (error) {
    dispatch(setChecklistDetailsError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const createSponsorshipChecklistDetail = product => async dispatch => {
  try {
    dispatch(setChecklistDetailsLoading());

    const createdSponsorshipDetail = await post("checklist-details/sponsorship", product);
    const { data: newSponsorshipDetail, message } = createdSponsorshipDetail;

    dispatch({
      type: SET_CREATED_SPONSORSHIP_CHECKLIST_DETAIL,
      newSponsorshipDetail,
    });

    dispatch(setNotice({ message: message, type: "success" }));
  } catch (error) {
    dispatch(setChecklistDetailsError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};
