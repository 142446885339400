import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { Row, Indent } from "@styles/grid";
import TextInput from "@components/inputs/TextInput";
import Checkbox from "@components/inputs/Checkbox";
import Select from "@components/inputs/Select";
import { ButtonPrimary, ButtonSecondary, ButtonSecondaryLink } from "@components/Button";
import { Form, FormButtons } from "@styles/form";
import { updateProduct } from "@actions/products.action";
import { getAllCategories } from "@actions/categories.action";
import { getAllBrands } from "@actions/brands.action";
import { nullIfEmpty, emptyIfNull } from "@utils/string";
import { productValidationSchema } from "@utils/validation";

const ProductViewForm = ({ product }) => {
  const [productState, setProductState] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const dispatch = useDispatch();
  const categories = useSelector(state => state.categories.categories.filter(({ selectable }) => !!selectable));
  const brands = useSelector(state => state.brands.brands);
  const loading = useSelector(state => state.products.loading);

  useEffect(() => {
    if (product) {
      setProductState(product);
    }
  }, [product]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: productState ? productState.name : "",
      category_id: productState ? productState.category_id : null,
      brand_id: productState ? productState.brand_id : null,
      amazon_upc: productState ? emptyIfNull(productState.amazon_upc) : "",
      walmart_upc: productState ? emptyIfNull(productState.walmart_upc) : "",
      keywords: productState ? emptyIfNull(productState.keywords) : "",
      value_pack: productState ? Boolean(productState.value_pack) : false,
    },
    validationSchema: productValidationSchema,
    onSubmit: async ({ name, category_id, brand_id, amazon_upc, walmart_upc, keywords, value_pack }) => {
      try {
        const newProduct = {
          name,
          category_id,
          brand_id,
          amazon_upc: nullIfEmpty(amazon_upc),
          walmart_upc: nullIfEmpty(walmart_upc),
          keywords: nullIfEmpty(keywords),
          value_pack,
        };

        const savedProduct = await dispatch(updateProduct(product.id, newProduct));

        if (savedProduct.data) {
          setProductState(savedProduct.data);
          setEditMode(false);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
  });

  useEffect(() => {
    const isBrandsNotEmpty = brands && brands.length;
    const isCategoriesNotEmpty = categories && categories.length;

    if (!isBrandsNotEmpty) {
      dispatch(getAllCategories());
    }
    if (!isCategoriesNotEmpty) {
      dispatch(getAllBrands());
    }
  }, []);

  const handleSelectCategory = category => {
    formik.setFieldValue("category_id", category.value);
    setSelectedCategory(category);
  };

  const handleSelectBrand = brand => {
    formik.setFieldValue("brand_id", brand.value);
    setSelectedBrand(brand);
  };

  const handleCancel = () => {
    setEditMode(prevMode => !prevMode);

    formik.setValues({
      name: productState.name,
      category_id: productState.category_id,
      brand_id: productState.brand_id,
      amazon_upc: emptyIfNull(productState.amazon_upc),
      walmart_upc: emptyIfNull(productState.walmart_upc),
      keywords: emptyIfNull(productState.keywords),
      value_pack: Boolean(productState.value_pack),
    });
  };

  const foundedCategory = productState && categories.find(({ id }) => id === productState.category_id);
  const foundedBrand = productState && brands.find(({ id }) => id === productState.brand_id);

  const categoriesSelectOptions =
    categories && categories.length
      ? categories.map(category => ({
          value: category.id,
          label: category.name,
        }))
      : [];

  const brandsSelectOptions =
    brands && brands.length
      ? [
          { value: null, label: "None" },
          ...brands.map(brand => ({
            value: brand.id,
            label: brand.name,
          })),
        ]
      : [];

  const categorySelectValue = foundedCategory && {
    value: foundedCategory.id,
    label: foundedCategory.name,
  };

  const brandSelectValue = foundedBrand && {
    value: foundedBrand.id,
    label: foundedBrand.name,
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextInput
        label="Name"
        labelPosition="left"
        fluid
        placeholder={editMode ? "Product Name" : ""}
        name="name"
        id="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        formik={formik}
        isreadonly={!editMode}
        readOnly={!editMode}
      />
      {editMode ? (
        <Select
          label="Category"
          labelPosition="left"
          placeholder={editMode ? "Select Category" : ""}
          options={categoriesSelectOptions}
          value={selectedCategory || categorySelectValue}
          onChange={handleSelectCategory}
          fluid
          formik={formik}
          name="category_id"
          id="category_id"
        />
      ) : (
        <TextInput
          label="Category"
          labelPosition="left"
          fluid
          value={categorySelectValue ? categorySelectValue.label : ""}
          isreadonly={!editMode}
          readOnly={!editMode}
        />
      )}
      {editMode ? (
        <Select
          label="Brand"
          labelPosition="left"
          placeholder={editMode ? "Select Brand" : ""}
          options={brandsSelectOptions}
          value={selectedBrand || brandSelectValue}
          onChange={handleSelectBrand}
          fluid
          formik={formik}
          name="brand_id"
          id="brand_id"
        />
      ) : (
        <TextInput
          label="Brand"
          labelPosition="left"
          fluid
          value={brandSelectValue ? brandSelectValue.label : ""}
          isreadonly={!editMode}
          readOnly={!editMode}
        />
      )}
      <TextInput
        label="Amazon UPC"
        labelPosition="left"
        fluid
        placeholder={editMode ? "Enter product Amazon UPC" : ""}
        onChange={formik.handleChange}
        name="amazon_upc"
        id="amazon_upc"
        value={formik.values.amazon_upc}
        formik={formik}
        isreadonly={!editMode}
        readOnly={!editMode}
      />
      <TextInput
        label="Walmart UPC"
        labelPosition="left"
        fluid
        placeholder={editMode ? "Enter product Walmart UPC" : ""}
        onChange={formik.handleChange}
        name="walmart_upc"
        id="walmart_upc"
        value={formik.values.walmart_upc}
        formik={formik}
        isreadonly={!editMode}
        readOnly={!editMode}
      />
      <TextInput
        label="Keywords"
        labelPosition="left"
        fluid
        placeholder={editMode ? "Enter keywords for search" : ""}
        onChange={formik.handleChange}
        name="keywords"
        id="keywords"
        value={formik.values.keywords}
        formik={formik}
        isreadonly={!editMode}
        readOnly={!editMode}
      />
      <Indent margin="0 3rem 2.5rem auto">
        <Checkbox
          label="Value Pack"
          name="value_pack"
          id="value_pack"
          labelFontSize={1.2}
          value={formik.values.value_pack}
          checked={formik.values.value_pack}
          onClick={!editMode ? () => null : formik.handleChange}
          formik={formik}
          readOnly={!editMode}
        />
      </Indent>
      <Row margin="3rem 0" direction="column" align="flex-end">
        <FormButtons>
          {!editMode && (
            <ButtonSecondaryLink outlined uppercase href="/products">
              Back to Products
            </ButtonSecondaryLink>
          )}
          <ButtonSecondary uppercase onClick={handleCancel}>
            {editMode ? "cancel" : "Edit"}
          </ButtonSecondary>{" "}
          {editMode && (
            <ButtonPrimary type="submit" onClick={formik.handleSubmit} uppercase disabled={loading}>
              Save Product
            </ButtonPrimary>
          )}
        </FormButtons>
      </Row>
    </Form>
  );
};

export default ProductViewForm;
