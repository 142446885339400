import React from "react";
import Autosuggest from "react-autosuggest";
import Label from "@components/Label";
import { Wrapper, Suggestion } from "./AutocompleteInput.styled";

const AutocompleteInput = ({
  options,
  fetchRequested,
  clearRequested,
  getSuggestionValue,
  inputProps,
  renderSuggestion = suggestion => <Suggestion>{suggestion.name}</Suggestion>,
  onSuggestionSelected,
  disabled,
  label,
  labelPosition = "top",
  labelFontSize,
  labelWeight,
  uppercase,
  fluid,
  borderColor = "#d1d1d1",
  ...otherProps
}) => {
  return (
    <Label
      title={label}
      position={labelPosition}
      disabled={disabled}
      labelFontSize={labelFontSize}
      labelWeight={labelWeight}
      fluid={fluid}
      uppercase={uppercase}>
      <Wrapper fluid={fluid}>
        <Autosuggest
          suggestions={options}
          onSuggestionsFetchRequested={fetchRequested}
          onSuggestionsClearRequested={clearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          onSuggestionSelected={onSuggestionSelected}
          highlightFirstSuggestion={true}
          theme={{
            input: {
              width: "100%",
              backgroundColor: "white",
              border: `1px solid ${borderColor || "#d1d1d1"}`,
              borderRadius: "5px",
              padding: "1rem",
              fontSize: "1.4rem",
              boxSizing: "border-box",
              resize: "none",
            },
            suggestionsContainer: {
              boxSizing: "border-box",
              width: "100%",
              position: "relative",
              zIndex: 1000,
            },
            suggestionsList: {
              boxSizing: "border-box",
              width: "100%",
              position: "absolute",
              backgroundColor: "white",
              border: "1px solid #d1d1d1",
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: 4,
              padding: 0,
            },
            suggestionFirst: {
              backgroundColor: "#D0E4ED",
            },
            suggestionHighlighted: {
              backgroundColor: "#D0E4ED",
            },
          }}
          {...otherProps}
        />
      </Wrapper>
    </Label>
  );
};

export default AutocompleteInput;
