export const SET_COLLEGES = "SET_COLLEGES";
export const SET_COLLEGES_BY_STATE = "SET_COLLEGES_BY_STATE";
export const SET_SELECTED_COLLEGE = "SET_SELECTED_COLLEGE";
export const CLEAR_SELECTED_COLLEGE = "CLEAR_SELECTED_COLLEGE";
export const SET_COLLEGE_ERROR = "SET_COLLEGE_ERROR";
export const DELETE_SCHOOL = "DELETE_SCHOOL";
export const SET_CREATED_SCHOOL = "SET_CREATED_SCHOOL";
export const SET_SCHOOL_LOADING = "SET_SCHOOL_LOADING";
export const SET_UPDATED_SCHOOL = "SET_UPDATED_SCHOOL";
export const SET_SCHOOL_FETCHING = "SET_SCHOOL_FETCHING";
export const DELETE_LOADING = "DELETE_LOADING";
