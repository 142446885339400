import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";

import states from "./reducers/states.reducer";
import colleges from "./reducers/colleges.reducer";
import checklists from "./reducers/checklist.reducer";
import checklistDetails from "./reducers/checklistDetails.reducer";
import auth from "./reducers/auth.reducer";
import products from "./reducers/product.reducer";
import categories from "./reducers/categories.reducer";
import brands from "./reducers/brands.reducer";
import bundles from "./reducers/bundles.reducer";
import notice from "./reducers/notice.reducer";
import me from "./reducers/me.reducer";
import partners from "./reducers/partners.reducer";
import partnerCategories from "./reducers/partnerCategories.reducer";
import partnerProducts from "./reducers/partnerProducts.reducer";
import roles from "./reducers/roles.reducer";
import schoolsPartners from "./reducers/schoolsPartners.reducer";
import users from "./reducers/users.reducer";
import apiApplications from "./reducers/apiApplications.reducer";
import buckets from "./reducers/buckets.reducer";

const reducers = combineReducers({
  notice,
  auth,
  me,
  states,
  colleges,
  checklists,
  checklistDetails,
  categories,
  brands,
  buckets,
  bundles,
  products,
  partners,
  partnerCategories,
  partnerProducts,
  roles,
  schoolsPartners,
  users,
  apiApplications,
});

function initializeStore(initialState = {}) {
  return createStore(reducers, initialState, composeWithDevTools(applyMiddleware(thunkMiddleware)));
}

export default initializeStore();
