import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import styled from "styled-components";

import Layout from "@components/Layout";
import { Container } from "@styles/grid";
import BucketViewForm from "./BucketViewForm";

import { useDispatch } from "react-redux";
import { getBucketById } from "@actions/buckets.action";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 5.5rem 5.5rem;
`;

const BucketView = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [bucket, setBucket] = useState(null);

  useEffect(() => {
    (async () => {
      setBucket(await dispatch(getBucketById(params.id)));
    })();
  }, []);

  return (
    <Layout pageTitle={`${bucket ? bucket.name : ""} Bucket`}>
      <Container>
        <Content>
          <BucketViewForm bucket={bucket} />
        </Content>
      </Container>
    </Layout>
  );
};

export default BucketView;
