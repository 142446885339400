import { AUTH_LOGOUT } from "../types/auth.types";
import {
  SET_CHECKLISTS,
  SET_CHECKLISTS_BY_COLLEGE,
  SET_SELECTED_CHECKLIST,
  SET_SAVED_CHECKLISTS,
  SET_UPDATED_CHECKLISTS,
  DELETE_CHECKLIST,
  SET_CHECKLIST_ERROR,
  CLEAR_CHECKLIST,
  SET_CHECKLIST_LOADING,
  UNSET_CHECKLIST_LOADING,
  SET_CHECKLIST_FETCHING,
} from "../types/checklist.types";

const initialState = {
  checklists: [],
  checklistsByCollege: [],
  selected: null,
  error: "",
  loading: false,
  fetching: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CHECKLISTS:
      return {
        ...state,
        checklists: [...action.checklists],
        fetching: false,
      };
    case SET_CHECKLISTS_BY_COLLEGE:
      return {
        ...state,
        checklistsByCollege: [...action.checklists],
      };
    case SET_SELECTED_CHECKLIST:
      return {
        ...state,
        selected: action.selectedChecklist,
      };
    case SET_SAVED_CHECKLISTS:
      return {
        ...state,
        checklists: state.checklists && state.checklists.length ? [...state.checklists, action.checklist] : [],
        loading: false,
        error: "",
      };
    case SET_UPDATED_CHECKLISTS: {
      const copyOfChecklists = [...state.checklists];
      const foundedChecklistIndex = copyOfChecklists.findIndex(({ id }) => id === action.updatedChecklist.id);
      copyOfChecklists[foundedChecklistIndex] = action.updatedChecklist;

      return {
        ...state,
        checklists: [...copyOfChecklists],
        loading: false,
        error: "",
      };
    }
    case DELETE_CHECKLIST: {
      const copyOfChecklists = [...state.checklists];
      const foundedChecklistIndex = copyOfChecklists.findIndex(({ id }) => id === action.checklistId);
      copyOfChecklists.splice(foundedChecklistIndex, 1);

      return {
        ...state,
        checklists: [...copyOfChecklists],
        error: "",
        loading: false,
      };
    }
    case SET_CHECKLIST_ERROR:
      return {
        ...state,
        error: Array.isArray(action.error) ? action.error[0] : action.error,
        loading: false,
        fetching: false,
      };
    case SET_CHECKLIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case UNSET_CHECKLIST_LOADING:
      return {
        ...state,
        loading: false,
      };
    case SET_CHECKLIST_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case CLEAR_CHECKLIST:
      return {
        selected: null,
        checklists: [],
        checklistsByCollege: [],
        error: "",
      };
    case AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
