import { AUTH_LOGOUT } from "../types/auth.types";
import { SET_NOTICE, CLEAR_NOTICE } from "../types/notice.types";

const initialState = {
  type: "",
  message: "",
  delay: 4000,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTICE:
      return {
        ...state,
        type: action.notice.type,
        message: action.notice.message,
        delay: action.notice.delay,
      };
    case CLEAR_NOTICE:
      return {
        ...state,
        type: "",
        message: "",
        delay: 4000,
      };
    case AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
