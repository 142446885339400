import React from "react";
import styled from "styled-components";

import Layout from "@components/Layout";
import BrandAddForm from "./BrandAddForm";

import { Container } from "@styles/grid";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 5.5rem 5.5rem;
`;

const BrandsAdd = () => {
  return (
    <Layout pageTitle="Add Brand">
      <Container>
        <Content>
          <BrandAddForm />
        </Content>
      </Container>
    </Layout>
  );
};

export default BrandsAdd;
