import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { PrivateRoute } from "@lib/routes";

import Login from "@pages/login";
import Dashboard from "@pages";
import Brands from "@pages/brands";
import BrandsAdd from "@pages/brands/add";
import BrandsView from "@pages/brands/[id]";
import Bundles from "@pages/bundles";
import BundlesAdd from "@pages/bundles/add";
import BundlesView from "@pages/bundles/[id]";
import Categories from "@pages/categories";
import CategoriesAdd from "@pages/categories/add";
import CategoriesView from "@pages/categories/[id]";
import Checklists from "@pages/checklists";
import ChecklistsAdd from "@pages/checklists/add";
import ChecklistsView from "@pages/checklists/[id]";
import Partners from "@pages/partners";
import PartnersAdd from "@pages/partners/add";
import PartnersView from "@pages/partners/[id]";
import Products from "@pages/products";
import ProductsAdd from "@pages/products/add";
import ProductsView from "@pages/products/[id]";
import PartnerProducts from "@pages/partner-products";
import PartnerProductsAdd from "@pages/partner-products/add";
import PartnerProductsView from "@pages/partner-products/[id]";
import PartnerCategories from "@pages/partner-categories";
import PartnerCategoriesAdd from "@pages/partner-categories/add";
import PartnerCategotiesView from "@pages/partner-categories/[id]";
import Roles from "@pages/roles";
import RolesAdd from "@pages/roles/add";
import RolesView from "@pages/roles/[id]";
import Schools from "@pages/schools";
import SchoolsAdd from "@pages/schools/add";
import SchoolsView from "@pages/schools/[id]";
import SchoolsPartners from "@pages/schools-partners";
import SchoolsPartnersAdd from "@pages/schools-partners/add";
import SchoolsPartnersView from "@pages/schools-partners/[id]";
import Users from "@pages/users";
import UsersAdd from "@pages/users/add";
import UserView from "@pages/users/[id]";
import { USER_ROLES } from "@constants";
import ApiApplications from "@pages/api-aplications";
import ApiApplicationView from "@pages/api-aplications/[id]";
import ApiApplicationAdd from "@pages/api-aplications/add/ApiApplicationAdd";
import Buckets from "@pages/buckets";
import BucketsAdd from "@pages/buckets/add/BucketsAdd";
import BucketView from "@pages/buckets/[id]";
import SponsorshipProductsWrapper from "@pages/sponsorhip-products-list";

const App = () => (
  <Router>
    <Switch>
      <Route exact={true} path="/login" component={Login} />

      <PrivateRoute exact={true} path="/" component={Dashboard} />

      <PrivateRoute exact={true} path="/brands" component={Brands} roles={[USER_ROLES.admin]} />
      <PrivateRoute exact={true} path="/brands/add" component={BrandsAdd} roles={[USER_ROLES.admin]} />
      <PrivateRoute exact={true} path="/brands/:id" component={BrandsView} roles={[USER_ROLES.admin]} />

      <PrivateRoute exact={true} path="/bundles" component={Bundles} roles={[USER_ROLES.admin]} />
      <PrivateRoute exact={true} path="/bundles/add" component={BundlesAdd} roles={[USER_ROLES.admin]} />
      <PrivateRoute exact={true} path="/bundles/:id" component={BundlesView} roles={[USER_ROLES.admin]} />

      <PrivateRoute exact={true} path="/categories" component={Categories} roles={[USER_ROLES.admin]} />
      <PrivateRoute exact={true} path="/categories/add" component={CategoriesAdd} roles={[USER_ROLES.admin]} />
      <PrivateRoute exact={true} path="/categories/:id" component={CategoriesView} roles={[USER_ROLES.admin]} />

      <PrivateRoute exact={true} path="/checklists" component={Checklists} />
      <PrivateRoute
        exact={true}
        path="/checklists/add"
        component={ChecklistsAdd}
        roles={[USER_ROLES.admin, USER_ROLES.college, USER_ROLES.dataminer]}
      />
      <PrivateRoute exact={true} path="/checklists/:id" component={ChecklistsView} />

      <PrivateRoute exact={true} path="/partners" component={Partners} roles={[USER_ROLES.admin]} />
      <PrivateRoute exact={true} path="/partners/add" component={PartnersAdd} roles={[USER_ROLES.admin]} />
      <PrivateRoute exact={true} path="/partners/:id" component={PartnersView} roles={[USER_ROLES.admin]} />

      <PrivateRoute exact={true} path="/partnerproducts" component={PartnerProducts} roles={[USER_ROLES.admin]} />
      <PrivateRoute
        exact={true}
        path="/partnerproducts/add"
        component={PartnerProductsAdd}
        roles={[USER_ROLES.admin]}
      />
      <PrivateRoute
        exact={true}
        path="/partnerproducts/:id"
        component={PartnerProductsView}
        roles={[USER_ROLES.admin]}
      />

      <PrivateRoute exact={true} path="/partnercategories" component={PartnerCategories} roles={[USER_ROLES.admin]} />
      <PrivateRoute
        exact={true}
        path="/partnercategories/add"
        component={PartnerCategoriesAdd}
        roles={[USER_ROLES.admin]}
      />
      <PrivateRoute
        exact={true}
        path="/partnercategories/:id"
        component={PartnerCategotiesView}
        roles={[USER_ROLES.admin]}
      />

      <PrivateRoute exact={true} path="/products" component={Products} roles={[USER_ROLES.admin]} />
      <PrivateRoute exact={true} path="/products/add" component={ProductsAdd} roles={[USER_ROLES.admin]} />
      <PrivateRoute exact={true} path="/products/:id" component={ProductsView} roles={[USER_ROLES.admin]} />

      <PrivateRoute exact={true} path="/schools" component={Schools} roles={[USER_ROLES.admin]} />
      <PrivateRoute exact={true} path="/schools/add" component={SchoolsAdd} roles={[USER_ROLES.admin]} />
      <PrivateRoute exact={true} path="/schools/:id" component={SchoolsView} roles={[USER_ROLES.admin]} />

      <PrivateRoute exact={true} path="/schools-partners" component={SchoolsPartners} roles={[USER_ROLES.admin]} />
      <PrivateRoute
        exact={true}
        path="/schools-partners/add"
        component={SchoolsPartnersAdd}
        roles={[USER_ROLES.admin]}
      />
      <PrivateRoute
        exact={true}
        path="/schools-partners/:id"
        component={SchoolsPartnersView}
        roles={[USER_ROLES.admin]}
      />

      <PrivateRoute exact={true} path="/users" component={Users} roles={[USER_ROLES.admin]} />
      <PrivateRoute exact={true} path="/users/add" component={UsersAdd} roles={[USER_ROLES.admin]} />
      <PrivateRoute exact={true} path="/users/:id" component={UserView} roles={[USER_ROLES.admin]} />

      <PrivateRoute exact={true} path="/roles" component={Roles} roles={[USER_ROLES.admin]} />
      <PrivateRoute exact={true} path="/roles/add" component={RolesAdd} roles={[USER_ROLES.admin]} />
      <PrivateRoute exact={true} path="/roles/:id" component={RolesView} roles={[USER_ROLES.admin]} />

      <PrivateRoute exact={true} path="/api-applications" component={ApiApplications} roles={[USER_ROLES.admin]} />
      <PrivateRoute
        exact={true}
        path="/api-applications/add"
        component={ApiApplicationAdd}
        roles={[USER_ROLES.admin]}
      />
      <PrivateRoute
        exact={true}
        path="/api-applications/:id"
        component={ApiApplicationView}
        roles={[USER_ROLES.admin]}
      />

      <PrivateRoute exact={true} path="/buckets" component={Buckets} roles={[USER_ROLES.admin]} />
      <PrivateRoute exact={true} path="/buckets/add" component={BucketsAdd} roles={[USER_ROLES.admin]} />
      <PrivateRoute exact={true} path="/buckets/:id" component={BucketView} roles={[USER_ROLES.admin]} />

      <PrivateRoute
        exact={true}
        path="/sponsorship-products-list"
        component={SponsorshipProductsWrapper}
        roles={[USER_ROLES.admin]}
      />
    </Switch>
  </Router>
);

export default App;
