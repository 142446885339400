import { AUTH_LOGOUT } from "../types/auth.types";
import {
  REMOVE_DELETED_SCHOOL_PARTNER,
  SET_UPDATED_SCHOOL_PARTNER,
  SET_CREATED_SCHOOL_PARTNER,
  SET_SCHOOLS_PARTNERS_LOADING,
  SET_SCHOOLS_PARTNERS_ERROR,
  SET_SCHOOLS_PARTNERS,
  SET_SCHOOLS_PARTNERS_FETCHING,
} from "../types/schoolPartners.types";

const initialState = {
  all: [],
  loading: false,
  fetching: false,
  error: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SCHOOLS_PARTNERS:
      return {
        ...state,
        all: action.schoolsPartners,
        fetching: false,
      };
    case SET_CREATED_SCHOOL_PARTNER:
      return {
        ...state,
        all: state.all && state.all.length ? [...state.all, action.schoolPartners] : [],
        loading: false,
        error: "",
      };
    case SET_UPDATED_SCHOOL_PARTNER: {
      const copyOfSchoolsPartners = [...state.all];
      const foundedSchoolPartnersIndex = copyOfSchoolsPartners.findIndex(
        ({ id }) => id === action.updatedSchoolPartner.id
      );
      copyOfSchoolsPartners[foundedSchoolPartnersIndex] = action.updatedSchoolPartner;

      return {
        ...state,
        all: [...copyOfSchoolsPartners],
        loading: false,
        error: "",
      };
    }
    case REMOVE_DELETED_SCHOOL_PARTNER: {
      const copyOfSchoolsPartners = [...state.all];
      const foundedSchoolPartnersIndex = copyOfSchoolsPartners.findIndex(({ id }) => id === action.schoolPartnersId);
      copyOfSchoolsPartners.splice(foundedSchoolPartnersIndex, 1);

      return {
        ...state,
        all: [...copyOfSchoolsPartners],
        loading: false,
        error: "",
      };
    }
    case SET_SCHOOLS_PARTNERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_SCHOOLS_PARTNERS_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case SET_SCHOOLS_PARTNERS_ERROR:
      return {
        ...state,
        error: Array.isArray(action.error) ? action.error[0] : action.error,
        loading: false,
      };
    case AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
