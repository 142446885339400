import * as Yup from "yup";

export const brandValidationSchema = Yup.object({
    name: Yup.string()
        .min(2)
        .max(255)
        .nullable()
        .required("Name is required"),
    description: Yup.string()
        .min(0)
        .max(65535)
        .nullable(),
    notes: Yup.string()
        .min(0)
        .max(2000)
        .nullable(),
});

export const categoryValidationSchema = Yup.object({
    name: Yup.string()
        .min(2)
        .max(255)
        .nullable()
        .required("Name is required"),
    tooltip: Yup.string()
        .min(0)
        .max(255)
        .nullable(),
});

export const checklistValidationSchema = Yup.object({
    name: Yup.string()
        .min(2)
        .max(255, "Must be at most 255 characters")
        .nullable()
        .required("Name is required"),
    slug: Yup.string()
        .min(2)
        .max(255, "Must be at most 255 characters")
        .nullable()
        .required("Slug is required"),
    description: Yup.string()
        .min(0)
        .max(65535, "Must be at most 65535 characters")
        .nullable(),
    school_id: Yup.number()
        .integer()
        .nullable()
        .required("School is required"),
    not_bring: Yup.string()
        .min(0)
        .max(65535, "Must be at most 65535 characters")
        .nullable(),
    checklist_details: Yup.array()
        .of(
            Yup.object().shape({
                product_id: Yup.number().required(),
                quantity: Yup.number()
                    .positive("Quantity must be a positive number")
                    .max(1000000000, "Woah, this quantity is too big!")
                    .required("Quantity is required"),
                memo: Yup.string()
                    .min(0)
                    .max(65535, "Memo must me max 65535 characters long")
                    .nullable(),
            })
        )
        .required("Please, select more than zero products."),
    state: Yup.mixed().required("State is required"),
});

export const partnerValidationSchema = Yup.object({
    name: Yup.string()
        .min(2)
        .max(255)
        .required("Name is required"),
    slug: Yup.string()
        .min(2)
        .max(255)
        .required("Slug is required"),
});

export const partnerCategoryValidationSchema = Yup.object({
    partner_id: Yup.number()
        .min(1)
        .required("Partner is required"),
    name: Yup.string()
        .min(2)
        .max(255)
        .required("Partner category name is required"),
    toolTip: Yup.string()
        .min(2)
        .max(255)
        .nullable(),
    brand_id: Yup.number()
        .min(1)
        .nullable(),
    selectable: Yup.boolean(),
});

export const partnerProductValidationSchema = Yup.object({
    product_id: Yup.number()
        .min(1)
        .required("Product is required"),
    partner_id: Yup.number()
        .min(1)
        .required("Partner is required"),
    partner_category_id: Yup.number()
        .min(1)
        .nullable(),
    upc: Yup.string()
        .min(2)
        .max(255)
        .required("UPC is required"),
    order: Yup.number()
        .min(1)
        .required("Order is required"),
    quantity: Yup.number()
        .min(1)
        .required("Quantity is required"),
    name_alias: Yup.string()
        .min(2)
        .max(255)
        .nullable(),
});

export const editPartnerProductValidationSchema = Yup.object({
    partner_category_id: Yup.number()
        .min(1)
        .nullable(),
    upc: Yup.string()
        .min(2)
        .max(255)
        .required("UPC is required"),
    order: Yup.number()
        .min(1)
        .required("Order is required"),
    quantity: Yup.number()
        .min(1)
        .required("Quantity is required"),
    name_alias: Yup.string()
        .min(2)
        .max(255)
        .nullable(),
});

export const productValidationSchema = Yup.object({
    name: Yup.string()
        .min(2)
        .max(255)
        .nullable()
        .required("Name is required"),
    category_id: Yup.number()
        .integer()
        .nullable()
        .required("Category is required"),
    brand_id: Yup.number()
        .integer()
        .nullable(),
    amazon_upc: Yup.string()
        .min(0)
        .max(255)
        .nullable(),
    walmart_upc: Yup.string()
        .min(0)
        .max(255)
        .nullable(),
    keywords: Yup.string()
        .min(0)
        .max(65535)
        .nullable(),
});

export const schoolValidationSchema = Yup.object({
    name: Yup.string()
        .min(2)
        .max(255)
        .nullable()
        .required("Name is required"),
    slug: Yup.string()
        .min(2)
        .max(255)
        .nullable()
        .required("Slug is required"),
    state: Yup.number()
        .integer()
        .nullable()
        .required("State is required"),
    city: Yup.string()
        .min(2)
        .max(255)
        .nullable()
        .required("City is required"),
    rank: Yup.number()
        .min(2)
        .integer()
        .positive()
        .nullable(),
    link: Yup.string()
        .url()
        .nullable()
        .trim(),
    address: Yup.string()
        .max(255)
        .nullable(),
    address2: Yup.string()
        .max(255)
        .nullable(),
    zip: Yup.string()
        .max(11)
        .nullable(),
    short_code: Yup.string()
        .max(8)
        .nullable(),
    ipeds_id: Yup.number().nullable(),
    notes: Yup.string()
        .max(255)
        .nullable(),
});

export const schoolPartnersValidationSchema = Yup.object({
    partner_school_id: Yup.string()
        .min(2)
        .max(255)
        .required("Partner School ID is required"),
    partner: Yup.object()
        .shape({
            id: Yup.number()
                .positive()
                .required(),
            value: Yup.number()
                .positive()
                .required(),
            label: Yup.string().required(),
        })
        .nullable(true)
        .required("Partner is required"),
    school: Yup.object()
        .shape({
            id: Yup.number()
                .positive()
                .required(),
            value: Yup.number()
                .positive()
                .required(),
            label: Yup.string().required(),
        })
        .nullable(true)
        .required("School is required"),
    checklist: Yup.object().nullable(),
});

export const addBundleValidationSchema = Yup.object({
    associatedProduct: Yup.object().required("Associated Product is required"),
    name: Yup.string()
        .min(2)
        .max(255, "Must be at most 255 characters")
        .nullable()
        .required("Name is required"),
    bundle_items: Yup.array()
        .of(Yup.number().required())
        .required("Please, select more than zero products."),
});

export const editBundleValidationSchema = Yup.object({
    associatedProduct: Yup.object().required("Associated Product is required"),
    name: Yup.string()
        .min(2)
        .max(255, "Must be at most 255 characters")
        .nullable()
        .required("Name is required"),
    bundle_items: Yup.array()
        .of(
            Yup.object().shape({
                id: Yup.number().nullable(),
                product_id: Yup.number().required(),
            })
        )
        .required("Please, select more than zero products."),
});

export const addUserValidationSchema = Yup.object({
    name: Yup.string()
        .min(2, "Must contain at least 2 characters")
        .max(25, "Must be at most 255 characters")
        .nullable()
        .required("Name is required"),
    email: Yup.string()
        .email()
        .required("Email is required"),
    password: Yup.string()
        .min(6)
        .max(255),
    role: Yup.object()
        .nullable()
        .required("Role is required"),
});

export const editUserValidationSchema = Yup.object({
    name: Yup.string()
        .min(2, "Must contain at least 2 characters")
        .max(25, "Must be at most 255 characters")
        .nullable()
        .required("Name is required"),
    email: Yup.string()
        .email()
        .required("Email is required"),
    role: Yup.object()
        .nullable()
        .required("Role is required"),
});

export const addRoleValidationSchema = Yup.object({
    name: Yup.string()
        .min(2, "Must contain at least 2 characters")
        .max(25, "Must be at most 25 characters")
        .nullable()
        .required("Name is required"),
});

export const editRoleValidationSchema = Yup.object({
    name: Yup.string()
        .min(2, "Must contain at least 2 characters")
        .max(25, "Must be at most 25 characters")
        .nullable()
        .required("Name is required"),
});

export const addApiApplicationSchema = Yup.object({
    application_name: Yup.string()
        .min(2, "Must contain at least 2 characters")
        .max(255, "Must be at most 255 characters")
        .nullable()
        .required("Name is required"),
});

export const editApiApplicationSchema = Yup.object({
    application_name: Yup.string()
        .min(2, "Must contain at least 2 characters")
        .max(255, "Must be at most 255 characters")
        .nullable()
        .required("Name is required"),
});

export const addBucketValidationSchema = Yup.object({
    name: Yup.string()
        .min(2, "Must contain at least 2 characters")
        .max(38, "Must be at most 38 characters")
        .nullable()
        .required("Name is required"),
    slug: Yup.string()
        .min(2)
        .max(255)
        .nullable()
        .required("Slug is required"),
});

export const editBucketValidationSchema = Yup.object({
    name: Yup.string()
        .min(2, "Must contain at least 2 characters")
        .max(38, "Must be at most 38 characters")
        .nullable()
        .required("Name is required"),
    slug: Yup.string()
        .min(2)
        .max(255)
        .nullable()
        .required("Slug is required"),
});
