import React, { memo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import theme from "@styles";
import { Span } from "@styles/typography";
import { Row, Indent } from "@styles/grid";
import { ButtonRemoveRounded } from "@components/Button";
import AutocompleteInput from "@components/inputs/AutocompleteInput";
import { getProductsByName } from "@actions/products.action";
import { setNotice } from "@actions/notice.action";
import { debounce } from "@utils/func";

const Wrapper = styled.div`
  flex: 1;
  margin: 5rem -6rem 0 auto;
  max-width: 81rem;
  width: 100%;
`;

const ProductWrapper = styled.div`
  position: relative;
  max-width: 79rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 0;
  margin-left: auto;
`;

const ProductPosition = styled.div`
  position: absolute;
  left: -5rem;
  top: 0.5rem;
`;

const getSuggestionProductValue = suggestion => suggestion.name;

const AddProduct = memo(({ product, index, onChangeProduct, removeProduct, onClearProduct, selectedProducts }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productValue, setProductValue] = useState("");
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (product.product_id && selectedProducts.includes(product.product_id)) {
      setSelectedProduct(product);
    }
  }, [selectedProducts, product]);

  const loadProductsByName = async value => {
    const foundedProducts = await dispatch(getProductsByName(value, selectedProducts));

    setProducts(foundedProducts && foundedProducts.length ? [...foundedProducts] : []);
  };

  const debouncedProductFetchRequested = debounce(loadProductsByName, 300);

  const onProductFetchRequested = ({ value }) => {
    debouncedProductFetchRequested(value);
  };

  const onProductClearRequested = () => setProducts([]);

  const handleChangeProduct = (event, { newValue }) => {
    setProductValue(newValue);

    if (selectedProduct && newValue.length !== selectedProduct.product_name.length) {
      onClearProduct(selectedProduct.key);
      setSelectedProduct(null);
    }
  };

  const handleSelectProduct = returnedProduct => {
    const foundedProduct = selectedProducts.findIndex(id => returnedProduct.id === id);

    if (foundedProduct !== -1) {
      setProductValue("");
      setSelectedProduct(null);
      return dispatch(
        setNotice({ type: "error", message: `This product already selected at ${foundedProduct + 1} position` })
      );
    }

    onChangeProduct(returnedProduct, product.key);
  };

  return (
    <>
      <ProductWrapper>
        <ProductPosition>
          #
          <Span size={1.6} weight="bold">
            {index + 1}
          </Span>
        </ProductPosition>
        <Row direction="column">
          <AutocompleteInput
            fluid
            inputProps={{
              placeholder: "Start typing product name",
              value: productValue
                ? productValue
                : selectedProduct && selectedProduct.product_name
                ? selectedProduct.product_name
                : "",
              onChange: handleChangeProduct,
            }}
            getSuggestionValue={getSuggestionProductValue}
            fetchRequested={onProductFetchRequested}
            clearRequested={onProductClearRequested}
            onSuggestionSelected={(event, { suggestion }) => handleSelectProduct(suggestion, product.key)}
            options={products}
            borderColor={selectedProduct && theme.colorGreen}
          />
        </Row>
        <Indent margin="0 0 0 1.5rem">
          <ButtonRemoveRounded size="small" weight="bold" onClick={() => removeProduct(product.key)} />
        </Indent>
      </ProductWrapper>
    </>
  );
});

const BundleAddFormProducts = ({
  renderProducts,
  onChangeProduct,
  removeProduct,
  onClearProduct,
  associatedProduct,
}) => {
  const selectedProducts = renderProducts.filter(({ product_id }) => product_id).map(({ product_id }) => product_id);
  if (associatedProduct) selectedProducts.push(associatedProduct.id);

  return (
    <Wrapper margin="5rem 0" justify="space-between" align="center">
      <Indent margin="0 0 0 2rem">
        <Span size={1.6} weight={500} color="#303032">
          Products
        </Span>
      </Indent>
      {renderProducts.map((product, index) => (
        <AddProduct
          key={product.key}
          index={index}
          product={product}
          onChangeProduct={onChangeProduct}
          removeProduct={removeProduct}
          onClearProduct={onClearProduct}
          selectedProducts={selectedProducts}
        />
      ))}
    </Wrapper>
  );
};

export default BundleAddFormProducts;
