import { useState, useEffect } from "react";

function useXBreakpoint(breakpoint) {
  const [isBreak, setIsBreak] = useState(window.innerWidth < breakpoint ? true : false);

  const handleResize = () => (window.innerWidth < breakpoint ? setIsBreak(true) : setIsBreak(false));

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return isBreak;
}

export default useXBreakpoint;
