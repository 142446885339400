import styled from "styled-components";

import Layout from "@components/Layout";

import { Container } from "@styles/grid";

import UserViewForm from "./UserViewForm";
import { getUserById } from "@actions/users.action";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 5.5rem 5.5rem;
`;

const UserView = () => {
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    (async () => {
      setUser(await dispatch(getUserById(params.id)));
    })();
  }, [params.id]);

  return (
    <Layout pageTitle={user ? `${user.name}` : "User"}>
      <Container>
        <Content>
          <UserViewForm user={user} />
        </Content>
      </Container>
    </Layout>
  );
};

export default UserView;
