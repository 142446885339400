import React from "react";
import styled from "styled-components";

import Layout from "@components/Layout";

import { Container } from "@styles/grid";
import ApiApplicationAddForm from "./ApiApplicationAddForm";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 5.5rem 5.5rem;
`;

const ApiApplicationAdd = () => (
  <Layout pageTitle="Add User">
    <Container>
      <Content>
        <ApiApplicationAddForm />
      </Content>
    </Container>
  </Layout>
);

export default ApiApplicationAdd;
