import { AUTH_LOGOUT } from "../types/auth.types";
import {
  SET_BUNDLES,
  SET_BUNDLES_ERROR,
  SET_BUNDLES_LOADING,
  SET_CREATED_BUNDLE,
  SET_UPDATED_BUNDLE,
  REMOVE_DELETED_BUNDLE,
  SET_BUNDLES_FETCHING,
} from "../types/bundles.types";

const initialState = {
  all: [],
  loading: false,
  fetching: false,
  error: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BUNDLES:
      return {
        ...state,
        all: action.bundles,
        fetching: false,
      };
    case SET_CREATED_BUNDLE:
      return {
        ...state,
        all: state.all && state.all.length ? [...state.all, action.bundle] : [],
        loading: false,
        error: "",
      };
    case SET_UPDATED_BUNDLE: {
      const copyOfBundles = [...state.all];
      const foundedBundleIndex = copyOfBundles.findIndex(({ id }) => id === action.updatedBundle.id);
      copyOfBundles[foundedBundleIndex] = action.updatedBundle;

      return {
        ...state,
        all: [...copyOfBundles],
        loading: false,
        error: "",
      };
    }
    case REMOVE_DELETED_BUNDLE: {
      const copyOfBundles = [...state.all];
      const foundedBundleIndex = copyOfBundles.findIndex(({ id }) => id === action.bundleId);
      copyOfBundles.splice(foundedBundleIndex, 1);

      return {
        ...state,
        all: [...copyOfBundles],
        loading: false,
        error: "",
      };
    }
    case SET_BUNDLES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_BUNDLES_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case SET_BUNDLES_ERROR:
      return {
        ...state,
        error: Array.isArray(action.error) ? action.error[0] : action.error,
        loading: false,
      };
    case AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
