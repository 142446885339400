import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import styled from "styled-components";

import Layout from "@components/Layout";

import { Container } from "@styles/grid";

import BrandViewForm from "./BrandViewForm";
import { getBrandById } from "@actions/brands.action";
import { useDispatch } from "react-redux";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 5.5rem 5.5rem;
`;

const BrandView = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [brand, setBrand] = useState(null);

  useEffect(() => {
    (async () => {
      setBrand(await dispatch(getBrandById(params.id)));
    })();
  }, []);

  return (
    <Layout pageTitle={`${brand ? brand.name : ""} Brand`}>
      <Container>
        <Content>
          <BrandViewForm brand={brand} />
        </Content>
      </Container>
    </Layout>
  );
};

export default BrandView;
