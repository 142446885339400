import { get, post, put, deleteById } from "@api";
import {
  REMOVE_DELETED_ROLE,
  SET_CREATED_ROLE,
  SET_ROLES,
  SET_ROLES_FETCHING,
  SET_ROLES_LOADING,
  SET_ROLE_ERROR,
  SET_UPDATED_ROLE,
} from "../types/roles.types";

import { setNotice } from "./notice.action";

export const setRolesLoading = () => dispatch =>
  dispatch({
    type: SET_ROLES_LOADING,
  });

export const setRolesFetching = () => dispatch =>
  dispatch({
    type: SET_ROLES_FETCHING,
  });

export const setRoles = roles => dispatch =>
  dispatch({
    type: SET_ROLES,
    roles,
  });

export const setCreatedRole = role => dispatch =>
  dispatch({
    type: SET_CREATED_ROLE,
    role,
  });

export const setUpdatedRole = updatedRole => dispatch =>
  dispatch({
    type: SET_UPDATED_ROLE,
    updatedRole,
  });

export const removeDeletedRole = roleId => dispatch =>
  dispatch({
    type: REMOVE_DELETED_ROLE,
    roleId,
  });

export const setRolesError = error => dispatch =>
  dispatch({
    type: SET_ROLE_ERROR,
    error,
  });

export const getAllRoles = (include = "") => async dispatch => {
  try {
    dispatch(setRolesFetching());
    const response = await get(`/roles?include=${include}`);
    const roles = response.map(role => ({
      id: role.id,
      name: role.name,
      can_create: role.can_create ? "Yes" : "No",
      can_edit: role.can_edit ? "Yes" : "No",
      can_access_api: role.can_access_api ? "Yes" : "No",
      can_access_admin: role.can_access_admin ? "Yes" : "No",
    }));

    dispatch(setRoles(roles));

    return roles;
  } catch (error) {
    dispatch(setRolesError(error.message));
  }
};

export const getRoleById = (roleId, include = "") => async dispatch => {
  try {
    const role = await get(`/roles/${roleId}?include=${include}`);

    return role;
  } catch (error) {
    dispatch(setRolesError(error.message));
  }
};

export const createRole = role => async dispatch => {
  try {
    dispatch(setRolesLoading());

    const createdRole = await post("roles", role);

    dispatch(setCreatedRole(createdRole.data));
    dispatch(setNotice({ message: createdRole.message, type: "success" }));

    return createdRole;
  } catch (error) {
    dispatch(setRolesError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const updateRole = (roleId, role) => async dispatch => {
  try {
    dispatch(setRolesLoading());

    const updatedRole = await put(`roles/${roleId}`, role);

    dispatch(setUpdatedRole(updatedRole.data));
    dispatch(setNotice({ message: updatedRole.message, type: "success" }));

    return updatedRole;
  } catch (error) {
    dispatch(setRolesError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const deleteRoleById = roleId => async dispatch => {
  try {
    dispatch(setRolesLoading());

    const deletedRole = await deleteById("roles", roleId);

    dispatch(removeDeletedRole(roleId));
    dispatch(setNotice({ message: deletedRole.message, type: "success" }));

    return deletedRole;
  } catch (error) {
    dispatch(setRolesError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const getRolesByName = (name, selected) => async () => {
  try {
    const selectedRoles = selected && selected.length ? `&selected=${selected.join(",")}` : "";

    const roles = await get(`roles/search/q?name=${name}${selectedRoles}`);

    return roles;
  } catch (error) {
    console.error(error.message);
  }
};
