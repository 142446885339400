import React, { memo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import theme from "@styles";
import { Row } from "@styles/grid";
import AutocompleteInput from "@components/inputs/AutocompleteInput";
import { getProductsByName } from "@actions/products.action";
import { debounce } from "@utils/func";

const Wrapper = styled.div`
  width: 100%;
`;

const getSuggestionProductValue = suggestion => suggestion.name;

const SuggestedProducts = memo(({ product, onSelectProduct, readOnly, sponsored = false }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productValue, setProductValue] = useState("");
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (product?.id) {
      setSelectedProduct(product);
    } else {
      setSelectedProduct(null);
    }
  }, [product]);

  const loadProductsByName = async value => {
    const foundedProducts = await dispatch(getProductsByName(value));
    setProducts(foundedProducts && foundedProducts.length ? [...foundedProducts] : []);
  };

  const debouncedProductFetchRequested = debounce(loadProductsByName, 300);

  const onProductFetchRequested = ({ value }) => {
    debouncedProductFetchRequested(value);
  };

  const onProductClearRequested = () => {
    setProductValue("");
    setProducts([]);
  };

  const handleChangeProduct = (event, { newValue }) => {
    onSelectProduct(null);
    setProductValue(newValue);

    if (selectedProduct && newValue.length !== selectedProduct.name.length) {
      setSelectedProduct(null);
    }
  };

  return (
    <Wrapper>
      <Row direction="column">
        <AutocompleteInput
          label={sponsored ? "" : "Associated Product"}
          labelPosition="left"
          inputProps={{
            placeholder: "Start typing product name",
            value: productValue ? productValue : selectedProduct && selectedProduct.name ? selectedProduct.name : "",
            onChange: handleChangeProduct,
            disabled: readOnly,
          }}
          getSuggestionValue={getSuggestionProductValue}
          fetchRequested={onProductFetchRequested}
          clearRequested={onProductClearRequested}
          onSuggestionSelected={(event, { suggestion }) => onSelectProduct(suggestion)}
          options={products}
          borderColor={readOnly ? "transparent" : selectedProduct ? theme.colorGreen : null}
        />
      </Row>
    </Wrapper>
  );
});

export default SuggestedProducts;
