import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { Row, Indent } from "@styles/grid";
import Select from "@components/inputs/Select";
import TextInput from "@components/inputs/TextInput";
import Textarea from "@components/inputs/Textarea";
import Checkbox from "@components/inputs/Checkbox";
import { ButtonPrimary, ButtonSecondaryLink } from "@components/Button";
import { Form } from "@styles/form";
import { createPartnerCategory } from "@actions/partnerCategories.action";
import { getAllBrands } from "@actions/brands.action";
import { getAllPartners } from "@actions/partners.action";
import { nullIfEmpty } from "@utils/string";
import { partnerCategoryValidationSchema } from "@utils/validation";

const PartnerCategoryAddForm = () => {
    const [selectedSponsor, setSelectedSponsor] = useState(null);
    const [selectedPartner, setSelectedPartner] = useState(null);
    const [partners, setPartners] = useState([]);
    const dispatch = useDispatch();
    const loading = useSelector(state => state.categories.loading);
    const brands = useSelector(state => state.brands.brands);

    useEffect(() => {
        const getPartners = async () => {
            const isPartnersNotEmpty = partners && partners.length;

            if (!isPartnersNotEmpty) {
                const data = await dispatch(getAllPartners());
                setPartners(data);
            }
        };

        const getBrands = async () => {
            const isBrandsNotEmpty = brands && brands.length;

            if (!isBrandsNotEmpty) {
                await dispatch(getAllBrands());
            }
        };

        getPartners();
        getBrands();
    }, []);

    const handleSelectSponsor = brand => {
        formik.setFieldValue("brand_id", brand.value);
        setSelectedSponsor(brand);
    };

    const handleSelectPartner = partner => {
        formik.setFieldValue("partner_id", partner.value);
        setSelectedPartner(partner);
    };

    const brandsSelectOptions =
        brands && brands.length
            ? [
                { value: null, label: "None" },
                ...brands.map(brand => ({
                    value: brand.id,
                    label: brand.name,
                })),
            ]
            : [];

    const partnersSelectOptions =
        partners && partners.length
            ? [
                { value: null, label: "None" },
                ...partners.map(partner => ({
                    value: partner.id,
                    label: partner.name,
                })),
            ]
            : [];


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "",
            tooltip: "",
            brand_id: selectedSponsor?.value ? selectedSponsor.value : 0,
            selectable: false,
            partner_id: selectedPartner?.value ? selectedPartner.value : 0,
        },
        validationSchema: partnerCategoryValidationSchema,
        onSubmit: async ({ name, tooltip, brand_id, selectable, partner_id }) => {
            try {
                const newPartnerCategory = {
                    name: name,
                    tooltip: nullIfEmpty(tooltip),
                    brand_id: nullIfEmpty(brand_id),
                    selectable: selectable ? true : false,
                    partner_id: nullIfEmpty(partner_id),
                };

                const savedPartnerCategory = await dispatch(createPartnerCategory(newPartnerCategory));

                if (savedPartnerCategory.data) {
                    return (window.location.href = "/partnercategories");
                }
            } catch (error) {
                throw new Error(error.message);
            }
        },
    });


    return (
        <Form onSubmit={formik.handleSubmit}>
            <Select
                label="Partner"
                labelPosition="left"
                placeholder="Select Partner"
                options={partnersSelectOptions}
                value={selectedPartner}
                onChange={handleSelectPartner}
                fluid
                formik={formik}
                name="partner_id"
                id="partner_id"
            />
            <Select
                label="Sponsor"
                labelPosition="left"
                placeholder="Select Sponsor"
                options={brandsSelectOptions}
                value={selectedSponsor}
                onChange={handleSelectSponsor}
                fluid
                formik={formik}
                name="brand_id"
                id="brand_id"
            />
            <TextInput
                label="Name"
                labelPosition="left"
                fluid
                placeholder="Partner Category Name"
                name="name"
                id="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                formik={formik}
            />
            <Textarea
                label="Tooltip"
                labelPosition="left-top"
                fluid
                placeholder="Partner Category Tooltip"
                name="tooltip"
                id="tooltip"
                value={formik.values.tooltip}
                onChange={formik.handleChange}
                formik={formik}
            />
            <Indent margin="0 0 0 auto">
                <Checkbox
                    label="Selectable"
                    name="selectable"
                    id="selectable"
                    labelFontSize={1.2}
                    value={formik.values.selectable}
                    checked={formik.values.selectable}
                    onClick={formik.handleChange}
                    formik={formik}
                />
            </Indent>
            <Row margin="3rem 0" justify="flex-end">
                <Indent margin="0 2rem 0">
                    <ButtonSecondaryLink outlined uppercase href="/partnercategories">
                        Back to partner categories
                    </ButtonSecondaryLink>
                </Indent>
                <ButtonPrimary type="submit" uppercase disabled={loading} onClick={formik.handleSubmit}>
                    Create Partner Category
                </ButtonPrimary>
            </Row>
        </Form>
    );
};

export default PartnerCategoryAddForm;
