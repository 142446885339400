import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "@components/Table";
import { ButtonSecondary, ButtonPrimaryLink } from "@components/Button";
import { deletePartnerCategoryById } from "@actions/partnerCategories.action";
import Modal from "@components/Modal";
import { Span } from "@styles/typography";
import { Row, Indent } from "@styles/grid";

const EditPartnerCategory = ({ partnerCategoryId }) => {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.partnerCategories.loading);

    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

    const handleDeletePartnerCategory = () => async () => {
        try {
            await dispatch(deletePartnerCategoryById(partnerCategoryId));
            setIsRemoveModalOpen(false);
        } catch (error) {
            throw new Error(error.message);
        }
    };

    return (
        <Row justify="flex-end" padding="0 2rem 0 0">
            <Indent margin="0 1.5rem 0 0">
                <ButtonPrimaryLink
                    uppercase
                    href={`/partnercategories/${partnerCategoryId}`}
                    outlined
                    size="small"
                    weight="bold">
                    edit
                </ButtonPrimaryLink>
            </Indent>
            <ButtonSecondary uppercase onClick={() => setIsRemoveModalOpen(true)} size="small" weight="bold">
                Remove
            </ButtonSecondary>

            <Modal isOpen={isRemoveModalOpen} onClose={() => setIsRemoveModalOpen(false)} title="Remove Partner Category">
                <Indent margin="4rem 0">
                    <Span size={1.8}>Are you sure you want to delete this partner category?</Span>
                </Indent>
                <Row justify="flex-end">
                    <Indent margin="0 2rem 0">
                        <ButtonSecondary outlined onClick={() => setIsRemoveModalOpen(false)} uppercase>
                            Close
                        </ButtonSecondary>
                    </Indent>
                    <ButtonSecondary autoFocus onClick={handleDeletePartnerCategory()} uppercase disabled={loading}>
                        Remove
                    </ButtonSecondary>
                </Row>
            </Modal>
        </Row>
    );
};

const PartnerCategoriesTable = () => {
    const { all: partnerCategories, fetching } = useSelector(({ partnerCategories }) => partnerCategories);

    const columns = useMemo(
        () => [
            {
                Header: "Partner_Name",
                accessor: "partner.name",
                isSorting: true,
                isFiltered: true,
            },
            {
                Header: "Name",
                accessor: "name",
                isFiltered: true,
            },
            {
                Header: "Selectable",
                accessor: "selectable",
                isSorting: true,
                Cell: ({ row }) => <span>{row.original.selectable ? "Yes" : "No"}</span>,
            },
            {
                Header: "Sponsor",
                accessor: "brand.name",
                isSorting: true,
                isFiltered: true,
            },
            {
                id: "colEdit",
                Cell: ({ row }) => <EditPartnerCategory partnerCategoryId={row.original.id} />,
            },
        ],
        []
    );

    const data = useMemo(() => partnerCategories, [partnerCategories]);

    return <Table columns={columns} data={data} hasGlobalFilter loading={fetching} />;
};

export default PartnerCategoriesTable;
