import styled from "styled-components";

import Layout from "@components/Layout";

import { Container } from "@styles/grid";

import CategoryViewForm from "./CategoryViewForm";
import { getCategoryById } from "@actions/categories.action";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 5.5rem 5.5rem;
`;

const CategoryView = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [category, setCategory] = useState(null);

  useEffect(() => {
    const getCategory = async () => {
      const categoryResponse = await dispatch(getCategoryById(params.id));
      setCategory(categoryResponse);
    };

    getCategory();
  }, [params.id]);

  return (
    <Layout pageTitle={`${category ? category.name : ""} Category`}>
      <Container>
        <Content>
          <CategoryViewForm category={category} />
        </Content>
      </Container>
    </Layout>
  );
};

export default CategoryView;
