import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { Row, Indent } from "@styles/grid";
import { Span } from "@styles/typography";
import TextInput from "@components/inputs/TextInput";
import Select from "@components/inputs/Select";
import { ButtonPrimary, ButtonSecondaryLink } from "@components/Button";
import { Form } from "@styles/form";
import { createUser } from "@actions/users.action";
import { getStates } from "@actions/states.action";
import { getAllRoles } from "@actions/roles.action";
import { getCollegesByState } from "@actions/colleges.action";
import { addUserValidationSchema } from "@utils/validation";
import { capitalize } from "@utils/string";
import generateUUID from "@lib/generateUUID";
import { USER_ROLES } from "@constants";

const initialValues = {
  name: "",
  email: "",
  role: null,
  password: "",
  schools: [
    {
      key: generateUUID(),
      value: "",
      label: "",
    },
  ],
};

const UsersAddForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading } = useSelector(({ users }) => users);
  const { roles } = useSelector(({ roles }) => roles);
  const { states } = useSelector(({ states }) => states);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: addUserValidationSchema,
    onSubmit: async ({ name, email, role, password, schools }) => {
      try {
        const newUser = {
          name,
          email,
          role: Number(role.value),
          password,
          schools: Array.from(new Set(schools.filter(({ school }) => school).map(({ school }) => school.value))),
        };

        if (role.label.toLowerCase() !== USER_ROLES.college) {
          delete newUser.schools;
        }

        const savedUser = await dispatch(createUser(newUser));

        if (savedUser.data) {
          return history.push("/users");
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
  });

  useEffect(() => {
    (async () => {
      if (!roles || !roles.length) {
        await dispatch(getAllRoles());
      }
      if (!states || !states.length) {
        await dispatch(getStates());
      }
    })();
  }, [roles, states]);

  const handleSelectRole = role => {
    formik.setFieldValue("role", role);
  };

  const rolesSelectOptions =
    roles && roles.length
      ? roles.map(role => ({
          value: Number(role.id),
          label: capitalize(role.name),
        }))
      : [];

  const handleSelectState = async ({ value, label }, idx) => {
    try {
      const collegesByState = await dispatch(getCollegesByState(value));

      const newFormikSchools = [...formik.values.schools];
      newFormikSchools[idx] = {
        ...newFormikSchools[idx],
        state: {
          value,
          label,
        },
        school: null,
        schools: collegesByState.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      };
      formik.setFieldValue("schools", newFormikSchools);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectSchool = async (school, idx) => {
    try {
      const newFormikSchools = [...formik.values.schools];
      newFormikSchools[idx] = {
        ...newFormikSchools[idx],
        school: school,
      };
      const newSchool = [
        ...newFormikSchools.filter(({ school }) => school),
        {
          key: generateUUID(),
          state: null,
          school: null,
          schools: [],
        },
      ];

      formik.setFieldValue("schools", newSchool);
    } catch (error) {
      console.error(error);
    }
  };

  const statesOptions =
    states && states.length
      ? states.map(({ id, name }) => ({
          label: name,
          value: id,
        }))
      : [];

  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextInput
        label="Name"
        labelPosition="left"
        fluid
        placeholder="User Name"
        name="name"
        id="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        formik={formik}
      />
      <TextInput
        label="Email"
        labelPosition="left"
        fluid
        placeholder="User email"
        name="email"
        id="email"
        type="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        formik={formik}
      />
      <TextInput
        label="Password"
        labelPosition="left"
        fluid
        placeholder="User password"
        name="password"
        id="password"
        type="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        formik={formik}
      />
      <Select
        label="Role"
        labelPosition="left"
        placeholder="Select Role"
        options={rolesSelectOptions}
        value={formik.values.role}
        onChange={handleSelectRole}
        fluid
        formik={formik}
        name="role"
        id="role"
      />
      {formik.values.role && formik.values.role.label.toLowerCase() === USER_ROLES.college
        ? formik.values.schools.map((school, idx) => (
            <Row direction="column" margin="3rem 0 0" key={school.key}>
              <Indent margin="4rem 0 0">
                <Span size={2} weight="bold">
                  {idx + 1} School
                </Span>
              </Indent>
              <Row margin="0 0 1rem">
                <Select
                  label={`School State`}
                  labelPosition="left"
                  placeholder="Select State"
                  options={statesOptions}
                  value={school.state}
                  onChange={state => handleSelectState(state, idx)}
                  name="state"
                  formik={formik}
                  fluid
                />
              </Row>
              {school.state && (
                <Select
                  label={`School`}
                  labelPosition="left"
                  placeholder="Select School"
                  options={school.schools}
                  value={school.school}
                  onChange={school => handleSelectSchool(school, idx)}
                  name="school_id"
                  formik={formik}
                  fluid
                />
              )}
            </Row>
          ))
        : null}
      <Row margin="3rem 0" justify="flex-end">
        <Indent margin="0 2rem 0">
          <ButtonSecondaryLink outlined uppercase href="/users">
            Back To Users
          </ButtonSecondaryLink>
        </Indent>
        <ButtonPrimary type="submit" uppercase disabled={loading} onClick={formik.handleSubmit}>
          Create User
        </ButtonPrimary>
      </Row>
    </Form>
  );
};

export default UsersAddForm;
