import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

instance.interceptors.request.use(config => {
  const token = localStorage.getItem("token");

  const tokenString = "Bearer " + token;

  if (token && config.headers.common.Authorization !== tokenString) {
    config.headers.common.Authorization = tokenString;
  }

  return config;
});

instance.interceptors.response.use(
  response => response.data,
  error => {
    const response = error.response;

    if (response) {
      const status = response.status;

      if (typeof window !== "undefined" && window.location.pathname !== "/login") {
        if (status === 401 || status === 403) {
          localStorage.removeItem("token");
          window.location.href = "/login?sessionTimeout=1";
        }
      }

      return Promise.reject(response.data);
    }

    return Promise.reject({
      statusCode: 500,
      message: "Unexpected server error. Please try again.",
    });
  }
);

export default instance;
