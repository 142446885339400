import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { Row, Indent } from "@styles/grid";
import TextInput from "@components/inputs/TextInput";
import TextArea from "@components/inputs/Textarea";
import Select from "@components/inputs/Select";
import { ButtonPrimary, ButtonSecondaryLink } from "@components/Button";
import { Form } from "@styles/form";
import { createSchool } from "@actions/colleges.action";
import { getStates } from "@actions/states.action";
import { nullIfEmpty } from "@utils/string";
import slugify from "slugify";
import { schoolValidationSchema } from "@utils/validation";

const SchoolAddForm = () => {
  const [selectedState, setSelectedState] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const states = useSelector(state => state.states.states);
  const loading = useSelector(state => state.colleges.loading);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      slug: "",
      state: null,
      city: "",
      rank: "",
      link: "",
      address: "",
      address2: "",
      zip: "",
      notes: "",
      short_code: "",
      ipeds_id: "",
    },
    validationSchema: schoolValidationSchema,
    onSubmit: async ({ name, slug, state, city, rank, link, address, address2, zip, notes, short_code, ipeds_id }) => {
      try {
        const newSchool = {
          name,
          slug,
          state_id: state,
          city,
          location: `${city}, ${selectedState.label}`,
          rank: nullIfEmpty(rank),
          link: nullIfEmpty(link),
          address: nullIfEmpty(address),
          address2: nullIfEmpty(address2),
          zip: nullIfEmpty(zip),
          notes: nullIfEmpty(notes),
          short_code: nullIfEmpty(short_code),
          ipeds_id: nullIfEmpty(ipeds_id),
        };

        const savedSchool = await dispatch(createSchool(newSchool));

        if (savedSchool.data) {
          return history.push("/schools");
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
  });

  useEffect(() => {
    if (formik.values.name) {
      formik.setFieldValue("slug", slugify(formik.values.name, { lower: true }));
    }
  }, [formik.values.name]);

  useEffect(() => {
    (async () => {
      if (!states || !states.length) {
        dispatch(getStates());
      }
    })();
  }, [states]);

  const handleSelectState = state => {
    formik.setFieldValue("state", state.value);
    setSelectedState(state);
  };

  const statesSelectOptions =
    states && states.length
      ? states.map(state => ({
          value: state.id,
          label: state.code,
        }))
      : [];

  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextInput
        label="Name"
        labelPosition="left"
        fluid
        placeholder="School Name"
        name="name"
        id="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        formik={formik}
      />
      <TextInput
        label="Slug"
        labelPosition="left"
        fluid
        placeholder="School Slug"
        name="slug"
        id="slug"
        value={formik.values.slug}
        onChange={formik.handleChange}
        formik={formik}
      />
      <Select
        label="State"
        labelPosition="left"
        placeholder="Select State"
        options={statesSelectOptions}
        value={selectedState}
        onChange={handleSelectState}
        fluid
        formik={formik}
        name="state"
        id="state"
      />
      <TextInput
        label="City"
        labelPosition="left"
        fluid
        placeholder="Enter school city"
        onChange={formik.handleChange}
        name="city"
        id="city"
        value={formik.values.city}
        formik={formik}
      />
      <TextInput
        type="number"
        label="Rank"
        labelPosition="left"
        fluid
        placeholder="Enter school rank"
        onChange={formik.handleChange}
        name="rank"
        id="rank"
        value={formik.values.rank}
        formik={formik}
      />
      <TextInput
        label="Link"
        labelPosition="left"
        fluid
        placeholder="Enter school website link"
        onChange={formik.handleChange}
        name="link"
        id="link"
        value={formik.values.link}
        formik={formik}
      />
      <TextInput
        label="Address"
        labelPosition="left"
        fluid
        placeholder="Enter school address"
        onChange={formik.handleChange}
        name="address"
        id="address"
        value={formik.values.address}
        formik={formik}
      />
      <TextInput
        label="Address 2"
        labelPosition="left"
        fluid
        placeholder="Enter school address 2"
        onChange={formik.handleChange}
        name="address2"
        id="address2"
        value={formik.values.address2}
        formik={formik}
      />
      <TextInput
        label="ZIP"
        labelPosition="left"
        fluid
        placeholder="Enter school zip"
        onChange={formik.handleChange}
        name="zip"
        id="zip"
        value={formik.values.zip}
        formik={formik}
      />
      <TextInput
        label="Short Code"
        labelPosition="left"
        fluid
        placeholder="Enter School Short Code"
        onChange={formik.handleChange}
        name="short_code"
        id="short_code"
        value={formik.values.short_code}
        formik={formik}
      />
      <TextInput
        type="number"
        label="IPEDS ID"
        labelPosition="left"
        fluid
        placeholder="Enter IPEDS id"
        onChange={formik.handleChange}
        name="ipeds_id"
        id="ipeds_id"
        value={formik.values.ipeds_id}
        formik={formik}
      />
      <TextArea
        label="Notes"
        labelPosition="left-top"
        fluid
        placeholder="Enter school notes"
        onChange={formik.handleChange}
        name="notes"
        id="notes"
        value={formik.values.notes}
        formik={formik}
      />
      <Row margin="3rem 0" justify="flex-end">
        <Indent margin="0 2rem 0">
          <ButtonSecondaryLink outlined uppercase href="/schools">
            Back To Schools
          </ButtonSecondaryLink>
        </Indent>
        <ButtonPrimary type="submit" uppercase disabled={loading} onClick={formik.handleSubmit}>
          Create School
        </ButtonPrimary>
      </Row>
    </Form>
  );
};

export default SchoolAddForm;
