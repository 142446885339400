import { AUTH_LOGOUT } from "../types/auth.types";
import {
  SET_CHECKLIST_DETAILS,
  SET_SELECTED_CHECKLIST_DETAILS,
  CHANGE_SELECTED_CHECKLIST_DETAIL,
  REMOVE_SELECTED_CHECKLIST_DETAIL,
  SET_CHECKLIST_DETAILS_ERROR,
  CLEAR_CHECKLIST_DETAILS,
  SET_SPONSORSHIP_CHECKLIST_DETAILS,
  DELETE_SPONSORSHIP_CHECKLIST_DETAIL,
  SET_CREATED_SPONSORSHIP_CHECKLIST_DETAIL,
  SET_CHECKLIST_DETAILS_LOADING,
} from "../types/checklistDetails.types";

const initialState = {
  checklistDetails: null,
  selected: [],
  sponsored: [],
  loading: false,
  error: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CHECKLIST_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_CHECKLIST_DETAILS:
      return {
        ...state,
        checklistDetails: action.checklistDetails,
      };
    case SET_SPONSORSHIP_CHECKLIST_DETAILS:
      return {
        ...state,
        sponsored: [...action.sponsoredProductsList],
      };
    case SET_CREATED_SPONSORSHIP_CHECKLIST_DETAIL:
      return {
        ...state,
        sponsored: [...state.sponsored, action.newSponsorshipDetail],
        loading: false,
      };
    case SET_SELECTED_CHECKLIST_DETAILS:
      return {
        ...state,
        selected: action.selectedChecklistDetails.map(detail => {
          const foundedSelectedChecklistDetail = state.selected.find(({ id }) => id === +detail.id);

          return foundedSelectedChecklistDetail || detail;
        }),
      };
    case CHANGE_SELECTED_CHECKLIST_DETAIL: {
      let copyOfSelected = [...state.selected];
      const foundedIndex = copyOfSelected.findIndex(({ id }) => id === action.changedChecklistDetail.id);
      copyOfSelected[foundedIndex] = action.changedChecklistDetail;

      return {
        ...state,
        selected: [...copyOfSelected],
      };
    }
    case REMOVE_SELECTED_CHECKLIST_DETAIL: {
      let copyOfSelected = [...state.selected];
      const foundedIndex = copyOfSelected.findIndex(({ id }) => id === action.selectedId);
      copyOfSelected.splice(foundedIndex, 1);

      return {
        ...state,
        selected: [...copyOfSelected],
      };
    }
    case DELETE_SPONSORSHIP_CHECKLIST_DETAIL: {
      return {
        ...state,
        sponsored: [...state.sponsored.filter(({ id }) => id !== action.sponsoredId)],
        loading: false,
      };
    }
    case SET_CHECKLIST_DETAILS_ERROR:
      return {
        ...state,
        error: Array.isArray(action.error) ? action.error[0] : action.error,
        loading: false,
      };
    case CLEAR_CHECKLIST_DETAILS:
      return {
        checklistDetails: null,
        selected: [],
        error: "",
      };
    case AUTH_LOGOUT:
      return initialState;

    default:
      return state;
  }
};
