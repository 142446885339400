import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styled from "styled-components";

import Layout from "@components/Layout";
import { ButtonSecondaryLink, ButtonRoundedPlus, ButtonPrimary } from "@components/Button";
import SuggestedProducts from "@components/ProductSuggested";
import { Container, Indent } from "@styles/grid";
import { TitleH1 } from "@styles/typography";

import { getAllSponsorshipProducts } from "@actions/checklistDetails.action";
import { setNotice } from "@actions/notice.action";

import SponsorshipProductsList from "./ProductsList";
import { createSponsorshipChecklistDetail } from "../../services/store/actions/checklistDetails.action";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 5.5rem 5.5rem;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  & button {
    margin-bottom: 3rem;
  }
`;

const InputRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const SponsorshipProductsWrapper = () => {
  const { sponsored, loading } = useSelector(({ checklistDetails }) => checklistDetails);
  const [renderDetails, setRenderDetails] = useState(null);
  const [addProduct, setAddProduct] = useState(false);
  const [newProduct, setNewProduct] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const sponsorshipProductsListNotEmpty = sponsored && sponsored.length;

      if (!sponsorshipProductsListNotEmpty) {
        await dispatch(getAllSponsorshipProducts());
        setRenderDetails(null);
      } else {
        setRenderDetails([...sponsored]);
      }
    })();
  }, [sponsored]);

  const addRenderProduct = () => {
    setAddProduct(true);
  };

  const onSelectProduct = product => {
    if (product) {
      setNewProduct(product);
    } else {
      setNewProduct(null);
    }
  };

  const addNewProduct = async () => {
    if (newProduct) {
      if (newProduct.brand_id) {
        const findSameProduct = sponsored.find(({ product_id }) => product_id === newProduct.id);

        if (findSameProduct) {
          dispatch(setNotice({ message: "Product has already been added to the list!", type: "warning" }));
        } else {
          dispatch(
            createSponsorshipChecklistDetail({
              ...newProduct,
              value_pack: !!newProduct.value_pack,
            })
          );
          setNewProduct(null);
        }
      } else {
        dispatch(
          setNotice({
            message: "The product is not branded. If you want to add this product, assign a brand to it.",
            type: "info",
          })
        );
      }
    } else {
      dispatch(setNotice({ message: "It is impossible to add. Please select a product first!", type: "error" }));
    }
  };

  return (
    <Layout pageTitle="Sponsorship Products List">
      <Container>
        <Content>
          <TitleH1>Sponsorhip Products list for each Checklist</TitleH1>
          {renderDetails && renderDetails.length && <SponsorshipProductsList renderProducts={renderDetails} />}

          {addProduct ? (
            <InputRow>
              <Indent margin="1rem 2rem 0 0">
                <SuggestedProducts
                  product={newProduct}
                  onSelectProduct={onSelectProduct}
                  readOnly={false}
                  sponsored={true}
                />
              </Indent>

              <Indent margin="1rem 1rem 2rem">
                <ButtonPrimary type="submit" uppercase="true" disabled={loading} onClick={() => addNewProduct()}>
                  {loading ? "Wait..." : "Add product to list"}
                </ButtonPrimary>
              </Indent>

              <Indent margin="1rem 0">
                <ButtonSecondaryLink
                  to="/sponsorship-products-list"
                  outlined="true"
                  uppercase="true"
                  onClick={() => setAddProduct(false)}>
                  close
                </ButtonSecondaryLink>
              </Indent>
            </InputRow>
          ) : null}
          <Buttons>
            <ButtonRoundedPlus onClick={addRenderProduct} />
            <ButtonSecondaryLink outlined="true" href="/" uppercase="true">
              Back to Dashboard
            </ButtonSecondaryLink>
          </Buttons>
        </Content>
      </Container>
    </Layout>
  );
};

export default SponsorshipProductsWrapper;
