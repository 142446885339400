import { get, post, put, deleteById } from "@api";
import {
  SET_COLLEGES,
  SET_CREATED_SCHOOL,
  SET_UPDATED_SCHOOL,
  SET_SCHOOL_LOADING,
  SET_COLLEGES_BY_STATE,
  DELETE_SCHOOL,
  SET_SELECTED_COLLEGE,
  SET_COLLEGE_ERROR,
  SET_SCHOOL_FETCHING,
  DELETE_LOADING,
} from "../types/colleges.types";
import { clearChecklist } from "./checklist.action";
import { clearChecklistDetails } from "./checklistDetails.action";
import { setNotice } from "./notice.action";
import { includeIfExist } from "@utils/string";
import { SchoolWithIpeds } from "@utils/SchoolIpeds";

export const setSchoolLoading = () => dispatch =>
  dispatch({
    type: SET_SCHOOL_LOADING,
  });

export const deleteSchoolLoading = () => dispatch =>
  dispatch({
    type: DELETE_LOADING,
  });

export const setSchoolFetching = () => dispatch =>
  dispatch({
    type: SET_SCHOOL_FETCHING,
  });

export const setAllColleges = colleges => dispatch =>
  dispatch({
    type: SET_COLLEGES,
    colleges,
  });

export const setCreatedSchool = school => dispatch =>
  dispatch({
    type: SET_CREATED_SCHOOL,
    school,
  });

export const setUpdatedSchool = updatedSchool => dispatch =>
  dispatch({
    type: SET_UPDATED_SCHOOL,
    updatedSchool,
  });

export const setCollegesByState = colleges => dispatch =>
  dispatch({
    type: SET_COLLEGES_BY_STATE,
    colleges,
  });

export const deleteSchool = schoolId => dispatch =>
  dispatch({
    type: DELETE_SCHOOL,
    schoolId,
  });

export const setSelectedCollege = college => dispatch => {
  dispatch(clearChecklist());
  dispatch(clearChecklistDetails());
  return dispatch({
    type: SET_SELECTED_COLLEGE,
    college,
  });
};

export const setCollegeError = error => dispatch =>
  dispatch({
    type: SET_COLLEGE_ERROR,
    error,
  });

export const getAllColleges = () => async dispatch => {
  try {
    dispatch(setSchoolFetching());

    const colleges = await get(`schools`);

    dispatch(setAllColleges(colleges));

    return colleges;
  } catch (error) {
    dispatch(setCollegeError(error.message));
  }
};

export const getSchoolById = (id, include) => async dispatch => {
  try {
    const school = await get(`schools/${id}${includeIfExist("include", include)}`);

    return school;
  } catch (error) {
    dispatch(setCollegeError(error.message));
  }
};

export const getSchoolBySlug = (slug, include) => async dispatch => {
  try {
    const school = await get(`schools/slug/${slug}${includeIfExist("include", include)}`);

    return school;
  } catch (error) {
    dispatch(setCollegeError(error.message));
  }
};

export const updateSchool = (schoolId, school) => async dispatch => {
  try {
    dispatch(setSchoolLoading());

    const updatedSchool = await put(`schools/${schoolId}`, school);

    dispatch(setUpdatedSchool(updatedSchool.data));
    dispatch(setNotice({ message: updatedSchool.message, type: "success" }));
    return updatedSchool;
  } catch (error) {
    dispatch(setCollegeError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const getCollegesByState = (stateId, include) => async (dispatch, getState) => {
  const me = getState().me;
  const { collegesByState } = getState().colleges;
  if (me.role === "college") {
    const filteredSchools = [...collegesByState.filter(({ state_id }) => state_id === stateId)];
    return filteredSchools;
  } else {
    try {
      const colleges = await get(`schools/state/${stateId}${includeIfExist("include", include)}`);
      dispatch(setCollegesByState(colleges));

      return colleges;
    } catch (error) {
      dispatch(setCollegeError(error.message));
    }
  }
};

export const getCollegesByName = name => async dispatch => {
  try {
    const colleges = await get(`schools/search/q?name=${name}`);

    return colleges;
  } catch (error) {
    dispatch(setCollegeError(error.message));
  }
};

export const createSchool = school => async dispatch => {
  try {
    dispatch(setSchoolLoading());
    const savedSchool = await post(`schools`, school);

    dispatch(setCreatedSchool(savedSchool.data));
    dispatch(setNotice({ message: savedSchool.message, type: "success" }));
    return savedSchool;
  } catch (error) {
    dispatch(setCollegeError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const deleteSchoolById = schoolId => async dispatch => {
  try {
    dispatch(setSchoolLoading());

    const deletedSchool = await deleteById(`schools`, schoolId);

    dispatch(deleteSchool(schoolId));
    dispatch(setNotice({ message: deletedSchool.message, type: "success" }));
    return deletedSchool;
  } catch (error) {
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const updateSchoolsIpeds = (offset = 0, limit = 100) => async dispatch => {
  const copyAllSchools = [...SchoolWithIpeds];
  const needUpdate = copyAllSchools.splice(offset, limit);

  try {
    dispatch(setSchoolFetching());
    const update = await put("schools/schools_ipeds/update", needUpdate);
    if (update.data) {
      dispatch(setNotice({ message: update.message, type: "success" }));
      dispatch(deleteSchoolLoading());
    }
  } catch (error) {
    dispatch(deleteSchoolLoading());
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};
