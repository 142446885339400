import { AUTH_LOGOUT } from "../types/auth.types";
import {
  REMOVE_DELETED_USER,
  SET_CREATED_USER,
  SET_USERS,
  SET_USER_ERROR,
  SET_USERS_LOADING,
  SET_UPDATED_USER,
  SET_USERS_FETCHING,
  DELETE_USERS_FETCHING,
} from "../types/user.types";

const initialState = {
  all: [],
  roles: [],
  loading: false,
  fetching: false,
  error: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        all: action.users,
        fetching: false,
      };
    case SET_CREATED_USER:
      return {
        ...state,
        all: state.all && state.all.length ? [...state.all, action.user] : [],
        loading: false,
        error: "",
      };
    case SET_UPDATED_USER: {
      const copyOfUsers = [...state.all];
      const foundedUserIndex = copyOfUsers.findIndex(({ id }) => id === action.updatedUser.id);
      copyOfUsers[foundedUserIndex] = action.updatedUser;

      return {
        ...state,
        all: [...copyOfUsers],
        loading: false,
        error: "",
      };
    }
    case REMOVE_DELETED_USER: {
      const copyOfUsers = [...state.all];
      const foundedUserIndex = copyOfUsers.findIndex(({ id }) => id === action.userId);
      copyOfUsers.splice(foundedUserIndex, 1);

      return {
        ...state,
        all: [...copyOfUsers],
        loading: false,
        error: "",
      };
    }
    case SET_USERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_USERS_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case DELETE_USERS_FETCHING:
      return {
        ...state,
        fetching: false,
      };
    case SET_USER_ERROR:
      return {
        ...state,
        error: Array.isArray(action.error) ? action.error[0] : action.error,
        loading: false,
      };
    case AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
