import styled from "styled-components";

import { ButtonPrimaryLink, ButtonSecondaryLink } from "@components/Button";
import Layout from "@components/Layout";

import { Row } from "@styles/grid";

import SchoolsTable from "./SchoolsTable";
import { getAllColleges } from "@actions/colleges.action";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect } from "react";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem auto 5.5rem;
  max-width: 125rem;
  width: 100%;
  padding: 0 2.5rem;
`;

const Schools = () => {
  const { colleges } = useSelector(({ colleges }) => colleges);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (!colleges || !colleges.length) {
        await dispatch(getAllColleges());
      }
    })();
  }, [colleges]);

  return (
    <Layout pageTitle="Schools">
      <Content>
        <Row justify="space-between" margin="2rem 0">
          <ButtonSecondaryLink outlined uppercase href="/">
            Back to Dashboard
          </ButtonSecondaryLink>
          <ButtonPrimaryLink uppercase href="/schools/add">
            Add school
          </ButtonPrimaryLink>
        </Row>
        <SchoolsTable />
      </Content>
    </Layout>
  );
};

export default Schools;
