import { get, post, put, deleteById } from "@api";
import {
  SET_CATEGORIES,
  SET_CATEGORIES_ERROR,
  SET_CATEGORIES_LOADING,
  SET_UPDATED_CATEGORY,
  SET_CREATED_CATEGORY,
  REMOVE_DELETED_CATEGORY,
  SET_CATEGORIES_FETCHING,
} from "../types/categories.types";
import { setNotice } from "@actions/notice.action";

export const setCategoriesLoading = () => dispatch =>
  dispatch({
    type: SET_CATEGORIES_LOADING,
  });

export const setCategoriesFetching = () => dispatch =>
  dispatch({
    type: SET_CATEGORIES_FETCHING,
  });

export const setCategories = categories => dispatch =>
  dispatch({
    type: SET_CATEGORIES,
    categories,
  });

export const setCreatedCategory = category => dispatch =>
  dispatch({
    type: SET_CREATED_CATEGORY,
    category,
  });

export const setUpdatedCategory = updatedCategory => dispatch =>
  dispatch({
    type: SET_UPDATED_CATEGORY,
    updatedCategory,
  });

export const removeDeletedCategory = categoryId => dispatch =>
  dispatch({
    type: REMOVE_DELETED_CATEGORY,
    categoryId,
  });

export const setCategoriesError = error => dispatch =>
  dispatch({
    type: SET_CATEGORIES_ERROR,
    error,
  });

export const getAllCategories = (include = "") => async dispatch => {
  try {
    dispatch(setCategoriesFetching());
    const categories = await get(`/categories?include=${include}`);
    dispatch(setCategories(categories));

    return categories;
  } catch (error) {
    dispatch(setCategoriesError(error.message));
  }
};

export const getCategoryById = (categoryId, include = "") => async dispatch => {
  try {
    const category = await get(`/categories/${categoryId}?include=${include}`);

    return category;
  } catch (error) {
    dispatch(setCategoriesError(error.message));
  }
};

export const createCategory = category => async dispatch => {
  try {
    dispatch(setCategoriesLoading());

    const createdCategory = await post("categories", category);

    dispatch(setCreatedCategory(createdCategory.data));
    dispatch(setNotice({ message: createdCategory.message, type: "success" }));

    return createdCategory;
  } catch (error) {
    dispatch(setCategoriesError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const updateCategory = (categoryId, category) => async dispatch => {
  try {
    dispatch(setCategoriesLoading());

    const updatedCategory = await put(`categories/${categoryId}`, category);

    dispatch(setUpdatedCategory(updatedCategory.data));
    dispatch(setNotice({ message: updatedCategory.message, type: "success" }));

    return updatedCategory;
  } catch (error) {
    dispatch(setCategoriesError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const deleteCategoryById = categoryId => async dispatch => {
  try {
    dispatch(setCategoriesLoading());

    const deletedCategory = await deleteById("categories", categoryId);

    dispatch(removeDeletedCategory(categoryId));
    dispatch(setNotice({ message: deletedCategory.message, type: "success" }));

    return deletedCategory;
  } catch (error) {
    dispatch(setCategoriesError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};
