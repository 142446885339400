import React from "react";
import { Wrapper, StyledLoader } from "./Loader.styled";

const Loader = () => (
  <Wrapper>
    <StyledLoader>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </StyledLoader>
  </Wrapper>
);

export default Loader;
