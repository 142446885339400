import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import theme, { colorsSchema } from "@styles";

const ButtonStyles = css`
  display: inline-block;
  width: ${({ fluid }) => (fluid ? "100%" : "min-content")};
  padding: ${({ padding = "0.9rem 2.1rem 0.8rem" }) => padding};
  font-size: ${({ size = "normal" }) => (size === "normal" ? "1.5rem" : size === "small" ? "1rem" : "1.5rem")};
  font-weight: ${({ weight = "400" }) => weight};
  text-transform: ${({ uppercase = false }) => uppercase && "uppercase"};
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  transition: all 0.2s ease;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const PrimaryButtonStyles = css`
  ${ButtonStyles}
  background-color: ${({
    outlined,
    theme: {
      primaryBtn: { background },
    },
  }) => `${outlined ? "transparent" : background}`};
  border: ${({
    outlined,
    theme: {
      primaryBtn: { background },
    },
  }) => `1px solid ${outlined ? background : "transparent"}`};
  color: ${({
    outlined,
    theme: {
      primaryBtn: { font, background },
    },
  }) => `${outlined ? background : font}`};

  &:not(:disabled):hover {
    opacity: 0.8;
  }
`;

const SecondaryButtonStyles = css`
  ${ButtonStyles}
  background-color: ${({
    outlined,
    theme: {
      secondaryBtn: { background },
    },
  }) => `${outlined ? "transparent" : background}`};
  border: ${({
    outlined,
    theme: {
      secondaryBtn: { background },
    },
  }) => `1px solid ${outlined ? background : "transparent"}`};
  color: ${({
    outlined,
    theme: {
      secondaryBtn: { font, background },
    },
  }) => `${outlined ? background : font}`};

  &:not(:disabled):hover {
    opacity: 0.8;
  }
`;

const DefaultButtonStyles = css`
  ${ButtonStyles}
  background-color: white;
  border: 1px solid ${theme.colorGrey};
  color: ${({ theme: { fontColor } }) => fontColor};

  &:not(:disabled):hover {
    background-color: ${theme.colorGrey100};
  }
`;

export const PrimaryButton = styled.button`
  ${PrimaryButtonStyles}
`;

export const PrimaryButtonLink = styled(Link)`
  ${PrimaryButtonStyles}
`;

export const PrimaryButtonLinkExternal = styled.a`
  ${PrimaryButtonStyles}
  text-decoration: none;
`;

export const SecondaryButton = styled.button`
  ${SecondaryButtonStyles}
`;

export const SecondaryButtonLink = styled(Link)`
  ${SecondaryButtonStyles}
`;

export const SecondaryButtonLinkExternal = styled.a`
  ${SecondaryButtonStyles}
  text-decoration: none;
`;

export const DefaultButton = styled.button`
  ${DefaultButtonStyles}
`;

export const DefaultButtonLink = styled(Link)`
  ${DefaultButtonStyles}
`;

export const DefaultButtonLinkExternal = styled.a`
  ${DefaultButtonStyles}
  text-decoration: none;
`;

export const ButtonRounded = styled.button`
  background-color: ${theme.colorDarkGrey};
  border: 0;
  position: relative;
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 3px;
    width: 1.5rem;
    background-color: white;
  }
`;

export const ButtonRoundedPlus = styled.button`
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  background-color: ${theme.colorYellowDark};
  position: relative;
  border: 0;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    opacity: 0.8;
  }

  &:after {
    content: " ";
    position: absolute;
    display: block;
    background-color: #fff;
    height: 0.5rem;
    transform: translateY(-50%);
    top: 50%;
    left: 1rem;
    right: 1rem;
  }

  &:before {
    content: " ";
    position: absolute;
    display: block;
    background-color: #fff;
    width: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    top: 1rem;
    bottom: 1rem;
  }
`;
