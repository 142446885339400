import { AUTH_LOGOUT } from "../types/auth.types";
import { SET_ME } from "../types/me.types";
import { USER_ROLES } from "@constants";

const initialState = {
  id: null,
  email: null,
  role: null,
  name: null,
  schools: null,
  orgAccess: null, // allow user to access checklists in admin panel at an organizational level
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ME:
      return {
        ...state,
        id: action.me.id,
        email: action.me.email,
        role: action.me.role,
        name: action.me.name,
        schools: action.me.schools,
        orgAccess:
          action.me.role === USER_ROLES.admin ||
          action.me.role === USER_ROLES.dataminer ||
          action.me.role === USER_ROLES.college,
      };
    case AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
