import { get, post, put, deleteById } from "@api";
import {
    SET_PARTNER_CATEGORIES,
    SET_PARTNER_CATEGORIES_BY_PARTNER_ID,
    SET_PARTNER_CATEGORY_ERROR,
    SET_PARTNER_CATEGORIES_LOADING,
    SET_CREATED_PARTNER_CATEGORY,
    SET_UPDATED_PARTNER_CATEGORY,
    REMOVE_DELETED_PARTNER_CATEGORY,
    SET_PARTNER_CATEGORIES_FETCHING,
} from "../types/partnerCategories.types";
import { setNotice } from "@actions/notice.action";
import { wait } from "@testing-library/react";

export const setPartnerCategoriesLoading = () => dispatch =>
    dispatch({
        type: SET_PARTNER_CATEGORIES_LOADING,
    });

export const setPartnerCategoriesFetching = () => dispatch =>
    dispatch({
        type: SET_PARTNER_CATEGORIES_FETCHING,
    });

export const setPartnerCategories = partnerCategories => dispatch =>
    dispatch({
        type: SET_PARTNER_CATEGORIES,
        partnerCategories,
    });

export const setPartnerCategoriesByPartnerId = partnerCategoriesByPartnerId => dispatch =>
    dispatch({
        type: SET_PARTNER_CATEGORIES_BY_PARTNER_ID,
        partnerCategoriesByPartnerId,
    });

export const setCreatedPartnerCategory = partnerCategory => dispatch =>
    dispatch({
        type: SET_CREATED_PARTNER_CATEGORY,
        partnerCategory,
    });

export const setUpdatedPartnerCategory = updatedPartnerCategory => dispatch =>
    dispatch({
        type: SET_UPDATED_PARTNER_CATEGORY,
        updatedPartnerCategory,
    });

export const deletedPartnerCategory = partnerCategoryId => dispatch =>
    dispatch({
        type: REMOVE_DELETED_PARTNER_CATEGORY,
        partnerCategoryId,
    });

export const setPartnerCategoriesError = error => dispatch =>
    dispatch({
        type: SET_PARTNER_CATEGORY_ERROR,
        error,
    });

export const getAllPartnerCategories = async dispatch => {
    try {
        dispatch(setPartnerCategoriesFetching());

        const partnerCategories = await get(`/partner-categories?include=partner%2Cbrand`);

        dispatch(setPartnerCategories(partnerCategories));

        return partnerCategories;
    } catch (error) {
        dispatch(setPartnerCategoriesError(error.message));
    }
};

export const getPartnerCategoryById = partnerCategoryId => async dispatch => {
    try {
        const partnerCategory = await get(`/partner-categories/${partnerCategoryId}`);

        return {
            ...partnerCategory,
        };
    } catch (error) {
        dispatch(setPartnerCategoriesError(error.message));
    }
};

export const getPartnerCategoryByPartnerId = partnerId => async dispatch => {
    try {
        const partnerCategoriesByPartnerId = await get(`/partner-categories/partner/${partnerId}`);

        dispatch(setPartnerCategoriesByPartnerId(partnerCategoriesByPartnerId))

        return partnerCategoriesByPartnerId;
    } catch (error) {
        dispatch(setPartnerCategoriesError(error.message));
    }
}

export const createPartnerCategory = partnerCategory => async dispatch => {
    try {
        dispatch(setPartnerCategoriesLoading());

        const createdPartnerCategory = await post("/partner-categories", partnerCategory);

        dispatch(setCreatedPartnerCategory(createdPartnerCategory.data));
        dispatch(setNotice({ message: createdPartnerCategory.message, type: "success" }));

        return createdPartnerCategory;
    } catch (error) {
        dispatch(setPartnerCategoriesError(error.message));
        dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
    }
};

export const updatedPartnerCategory = (partnerCategoryId, partnerCategory) => async dispatch => {
    try {
        dispatch(setPartnerCategoriesLoading());

        const updatedPartnerCategory = await put(`/partner-categories/${partnerCategoryId}`, partnerCategory);
        dispatch(setUpdatedPartnerCategory(updatedPartnerCategory.data));
        dispatch(setNotice({ message: updatedPartnerCategory.message, type: "success" }));

        return updatedPartnerCategory;
    } catch (error) {
        dispatch(setPartnerCategoriesError(error.message));
        dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
    }
};

export const deletePartnerCategoryById = partnerCategoryId => async dispatch => {
    try {
        dispatch(setPartnerCategoriesLoading());

        const removeDeletedPartnerCategory = await deleteById("/partner-categories", partnerCategoryId);

        dispatch(deletedPartnerCategory(partnerCategoryId));
        dispatch(setNotice({ message: deletedPartnerCategory.message, type: "success" }));

        return removeDeletedPartnerCategory;
    } catch (error) {
        if (error.message === "Internal server error") {
            error.message = "Partner categories cannot be deleted if they are associated with a partner product"
        }
        dispatch(setPartnerCategoriesError(error.message));
        dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
    }
};
