import styled from "styled-components";
import theme from "@styles";

export const Checkmark = styled.i`
  background-color: white;
  position: relative;
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 3px;
  border: 1.5px solid ${theme.colorBorder};
  transition: all 0.3 ease;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }

  ${({ marked }) =>
    marked &&
    `&:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    height: 1.2rem;
    width: 1.2rem;
    border: 1px solid green;
    }`}
`;

export const CheckboxInput = styled.input`
  position: relative;
  opacity: 0;
  height: 0;
  width: 0;
  cursor: pointer;

  &:checked ~ ${Checkmark}:after {
    display: block;
  }

  &:checked ~ ${Checkmark} {
    background-color: ${({ colorDisabled }) => (!colorDisabled ? theme.colorBlue : theme.colorGrey)};
    border: 1.5px solid ${({ colorDisabled }) => (!colorDisabled ? theme.colorBlue : theme.colorGrey)};
  }

  &:disabled ~ ${Checkmark} {
    background-color: transparent;
    border: 1.5px solid ${theme.colorBlue};
  }
`;
