import { AUTH_LOGOUT } from "../types/auth.types";
import { SET_SELECTED_STATE, SET_STATES, SET_STATES_LOADING, SET_STATE_ERROR } from "../types/states.types";

const initialState = {
  states: [],
  selected: null,
  loading: false,
  error: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_STATES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_STATES:
      return {
        ...state,
        states: action.states,
        loading: false,
      };
    case SET_SELECTED_STATE:
      return {
        ...state,
        selected: action.state,
      };
    case SET_STATE_ERROR:
      return {
        ...state,
        error: Array.isArray(action.error) ? action.error[0] : action.error,
        loading: false,
      };
    case AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
