/**
 * @public
 * @param {Component} buttonComponent
 * @param {string} href
 * @param {ReactChildren} children
 * @param {boolean} disable
 * @param {boolean} loading
 * @param {boolean} isLinkExternal
 * @param {string} padding
 * @param {enum} size
 * @param {string} weight
 * @param {boolean} uppercase
 * @param {boolean} outlined
 */
import React from "react";

import * as Styled from "./Button.styled";

const Button = ({
  buttonComponent: ButtonComponent = Styled.PrimaryButton,
  href = "",
  isLinkExternal = false,
  loading = false,
  disabled = false,
  children,
  type = "button",
  ...buttonProps
}) => {
  if (href && !isLinkExternal) {
    return (
      <ButtonComponent to={href} type={type} {...buttonProps}>
        {children}
      </ButtonComponent>
    );
  }

  if (isLinkExternal) {
    return (
      <ButtonComponent
        href={href}
        disabled={loading || disabled}
        target="_blank"
        rel="noopener noreferrer"
        type={type}
        {...buttonProps}>
        {children}
      </ButtonComponent>
    );
  }

  return (
    <ButtonComponent disabled={loading || disabled} type={type} {...buttonProps}>
      {children}
    </ButtonComponent>
  );
};

export const ButtonPrimary = ({ children, ...props }) => (
  <Button buttonComponent={Styled.PrimaryButton} {...props}>
    {children}
  </Button>
);

export const ButtonPrimaryLink = ({ children, ...props }) => (
  <Button buttonComponent={Styled.PrimaryButtonLink} {...props}>
    {children}
  </Button>
);

export const ButtonPrimaryLinkExternal = ({ children, ...props }) => (
  <Button buttonComponent={Styled.PrimaryButtonLinkExternal} isLinkExternal {...props}>
    {children}
  </Button>
);
export const ButtonSecondary = ({ children, ...props }) => (
  <Button buttonComponent={Styled.SecondaryButton} {...props}>
    {children}
  </Button>
);

export const ButtonSecondaryLink = ({ children, ...props }) => (
  <Button buttonComponent={Styled.SecondaryButtonLink} {...props}>
    {children}
  </Button>
);

export const ButtonSecondaryLinkExternal = ({ children, ...props }) => (
  <Button buttonComponent={Styled.SecondaryButtonLinkExternal} isLinkExternal {...props}>
    {children}
  </Button>
);
export const ButtonDefault = ({ children, ...props }) => (
  <Button buttonComponent={Styled.DefaultButton} {...props}>
    {children}
  </Button>
);

export const ButtonDefaultLink = ({ children, ...props }) => (
  <Button buttonComponent={Styled.DefaultButtonLink} {...props}>
    {children}
  </Button>
);

export const ButtonDefaultLinkExternal = ({ children, ...props }) => (
  <Button buttonComponent={Styled.DefaultButtonLinkExternal} isLinkExternal {...props}>
    {children}
  </Button>
);

export const ButtonRemoveRounded = ({ children, ...props }) => (
  <Button buttonComponent={Styled.ButtonRounded} {...props}>
    {children}
  </Button>
);

export const ButtonRoundedPlus = ({ children, ...props }) => (
  <Button buttonComponent={Styled.ButtonRoundedPlus} {...props}>
    {children}
  </Button>
);

export default Button;
