import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { Row, Indent } from "@styles/grid";
import TextInput from "@components/inputs/TextInput";
import { ButtonPrimary, ButtonSecondaryLink } from "@components/Button";
import Select from "@components/inputs/Select";
import { Form } from "@styles/form";
import { createSchoolPartners } from "@actions/schoolPartners.action";
import { getChecklistsByCollege } from "@actions/checklist.action";
import { schoolPartnersValidationSchema } from "@utils/validation";
import { getAllPartners } from "@actions/partners.action";
import { getAllColleges } from "@actions/colleges.action";
import { setChecklistsByCollege } from "@actions/checklist.action";

const SchoolPartnersAddForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.schoolsPartners.loading);
  const partners = useSelector(state => state.partners.all);
  const colleges = useSelector(state => state.colleges.colleges);
  const collegeChecklists = useSelector(state => state.checklists.checklistsByCollege);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      partner_school_id: "",
      partner: null,
      school: null,
      checklist: null,
    },
    validationSchema: schoolPartnersValidationSchema,
    onSubmit: async ({ partner_school_id, partner, school, checklist }) => {
      try {
        const newSchoolPartners = {
          partner_school_id,
          partner_id: partner.id,
          school_id: school.id,
          checklist_id: checklist.id,
        };

        const savedSchoolPartners = await dispatch(createSchoolPartners(newSchoolPartners));

        if (savedSchoolPartners.data) {
          return history.push("/schools-partners");
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
  });

  useEffect(() => {
    (async () => {
      const isCollegesNotEmpty = colleges && colleges.length;
      const isPartnersNotEmpty = partners && partners.length;

      await Promise.all([
        !isCollegesNotEmpty && dispatch(getAllColleges()),
        !isPartnersNotEmpty && dispatch(getAllPartners()),
      ]);

      dispatch(setChecklistsByCollege([]));
    })();
  }, []);

  const getChecklistsBySchoolId = async schoolId => {
    try {
      await dispatch(getChecklistsByCollege(schoolId));
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const handleSelect = (fieldName, selected) => {
    formik.setFieldValue(fieldName, selected);
  };

  const handleSchoolSelect = school => {
    handleSelect("school", school);
    handleSelect("checklist", null);
    getChecklistsBySchoolId(school.id);
  };

  const partnersSelectOptions =
    partners && partners.length
      ? partners.map(partner => ({
          id: partner.id,
          value: partner.id,
          label: partner.name,
        }))
      : [];

  const collegesSelectOptions =
    colleges && colleges.length
      ? colleges.map(college => ({
          id: college.id,
          value: college.id,
          label: college.name,
        }))
      : [];

  const collegeChecklistsSelectOptions =
    collegeChecklists && collegeChecklists.length
      ? [
          { id: null, value: null, label: "None" },
          ...collegeChecklists.map(collegeChecklist => ({
            id: collegeChecklist.id,
            value: collegeChecklist.id,
            label: collegeChecklist.name,
          })),
        ]
      : [];

  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextInput
        label="Partner School ID"
        labelPosition="left"
        fluid
        placeholder="Partner School ID"
        name="partner_school_id"
        id="partner_school_id"
        value={formik.values.partner_school_id}
        onChange={formik.handleChange}
        formik={formik}
      />
      <Select
        label="Partner"
        labelPosition="left"
        placeholder="Select Partner"
        options={partnersSelectOptions}
        value={formik.values.partner}
        onChange={partner => handleSelect("partner", partner)}
        fluid
        formik={formik}
        name="partner"
        id="partner"
      />
      <Select
        label="School"
        labelPosition="left"
        placeholder="Select School"
        options={collegesSelectOptions}
        value={formik.values.school}
        onChange={handleSchoolSelect}
        fluid
        formik={formik}
        name="school"
        id="school"
      />
      <Select
        label="Checklist"
        labelPosition="left"
        placeholder="Select Checklist"
        options={collegeChecklistsSelectOptions}
        value={formik.values.checklist}
        onChange={checklist => handleSelect("checklist", checklist)}
        fluid
        formik={formik}
        name="checklist"
        id="checklist"
        disabled={!collegeChecklistsSelectOptions || !collegeChecklistsSelectOptions.length}
      />
      <Row margin="3rem 0" justify="flex-end">
        <Indent margin="0 2rem 0">
          <ButtonSecondaryLink outlined uppercase href="/schools-partners">
            Back to schools partners
          </ButtonSecondaryLink>
        </Indent>
        <ButtonPrimary type="submit" uppercase disabled={loading} onClick={formik.handleSubmit}>
          Create school Partners
        </ButtonPrimary>
      </Row>
    </Form>
  );
};

export default SchoolPartnersAddForm;
