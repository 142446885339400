import { get, post, put, deleteById } from "@api";
import {
  REMOVE_DELETED_SCHOOL_PARTNER,
  SET_CREATED_SCHOOL_PARTNER,
  SET_SCHOOLS_PARTNERS,
  SET_SCHOOLS_PARTNERS_ERROR,
  SET_SCHOOLS_PARTNERS_LOADING,
  SET_UPDATED_SCHOOL_PARTNER,
  SET_SCHOOLS_PARTNERS_FETCHING,
} from "../types/schoolPartners.types";
import { setNotice } from "@actions/notice.action";

export const setSchoolsPartnersLoading = () => dispatch =>
  dispatch({
    type: SET_SCHOOLS_PARTNERS_LOADING,
  });

export const setSchoolsPartnersFetch = () => dispatch =>
  dispatch({
    type: SET_SCHOOLS_PARTNERS_FETCHING,
  });

export const setSchoolsPartners = schoolsPartners => dispatch =>
  dispatch({
    type: SET_SCHOOLS_PARTNERS,
    schoolsPartners,
  });

export const setCreatedSchoolPartner = schoolPartners => dispatch =>
  dispatch({
    type: SET_CREATED_SCHOOL_PARTNER,
    schoolPartners,
  });

export const setUpdatedSchoolPartner = updatedSchoolPartner => dispatch =>
  dispatch({
    type: SET_UPDATED_SCHOOL_PARTNER,
    updatedSchoolPartner,
  });

export const removeDeletedSchoolPartner = schoolPartnersId => dispatch =>
  dispatch({
    type: REMOVE_DELETED_SCHOOL_PARTNER,
    schoolPartnersId,
  });

export const setSchoolsPartnersError = error => dispatch =>
  dispatch({
    type: SET_SCHOOLS_PARTNERS_ERROR,
    error,
  });

export const getAllSchoolsPartners = (include = "") => async dispatch => {
  try {
    dispatch(setSchoolsPartnersFetch());

    const schoolsPartners = await get(`/schools-partners?include=${include}`);

    dispatch(setSchoolsPartners(schoolsPartners));

    return schoolsPartners;
  } catch (error) {
    dispatch(setSchoolsPartnersError(error.message));
  }
};

export const getSchoolPartnersById = (schoolPartnerId, include = "") => async dispatch => {
  try {
    const schoolPartner = await get(`/schools-partners/${schoolPartnerId}?include=${include}`);

    return schoolPartner;
  } catch (error) {
    dispatch(setSchoolsPartnersError(error.message));
  }
};

export const createSchoolPartners = schoolPartner => async dispatch => {
  try {
    dispatch(setSchoolsPartnersLoading());

    const createdSchoolPartner = await post("schools-partners", schoolPartner);

    dispatch(setCreatedSchoolPartner(createdSchoolPartner.data));
    dispatch(setNotice({ message: createdSchoolPartner.message, type: "success" }));

    return createdSchoolPartner;
  } catch (error) {
    dispatch(setSchoolsPartnersError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const updateSchoolPartners = (schoolPartnerId, schoolPartner) => async dispatch => {
  try {
    dispatch(setSchoolsPartnersLoading());

    const updatedSchoolPartner = await put(`schools-partners/${schoolPartnerId}`, schoolPartner);
    dispatch(setUpdatedSchoolPartner(updatedSchoolPartner.data));
    dispatch(setNotice({ message: updatedSchoolPartner.message, type: "success" }));

    return updatedSchoolPartner;
  } catch (error) {
    dispatch(setSchoolsPartnersError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};

export const deleteSchoolPartnersById = schoolPartnerId => async dispatch => {
  try {
    dispatch(setSchoolsPartnersLoading());

    const deletedSchoolPartner = await deleteById("schools-partners", schoolPartnerId);

    dispatch(removeDeletedSchoolPartner(schoolPartnerId));
    dispatch(setNotice({ message: deletedSchoolPartner.message, type: "success" }));

    return deletedSchoolPartner;
  } catch (error) {
    dispatch(setSchoolsPartnersError(error.message));
    dispatch(setNotice({ message: Array.isArray(error.message) ? error.message[0] : error.message, type: "error" }));
  }
};
