import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import styled from "styled-components";

import Layout from "@components/Layout";

import { Container } from "@styles/grid";

import ChecklistViewForm from "./ChecklistViewForm";
import { getChecklistById } from "@actions/checklist.action";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 5.5rem 5.5rem;
`;

const AdminChecklistView = () => {
  const [checklist, setChecklist] = useState(null);
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const checklistResponse = await dispatch(
        getChecklistById(params.id, "checklist_details,checklist_details.product,school,school.state")
      );
      setChecklist(checklistResponse);
    })();
  }, [params.id]);

  return (
    <Layout pageTitle={checklist ? checklist.name : ""}>
      <Container>
        <Content>
          <ChecklistViewForm checklist={checklist} />
        </Content>
      </Container>
    </Layout>
  );
};

export default AdminChecklistView;
