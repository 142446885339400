export const SET_CHECKLISTS = "SET_CHECKLISTS";
export const SET_CHECKLISTS_BY_COLLEGE = "SET_CHECKLISTS_BY_COLLEGE";
export const SET_SELECTED_CHECKLIST = "SET_SELECTED_CHECKLIST";
export const SET_CHECKLIST_ERROR = "SET_CHECKLIST_ERROR";
export const CLEAR_CHECKLIST = "CLEAR_CHECKLIST";
export const SET_CHECKLIST_LOADING = "SET_CHECKLIST_LOADING";
export const UNSET_CHECKLIST_LOADING = "UNSET_CHECKLIST_LOADING";
export const DELETE_CHECKLIST = "DELETE_CHECKLIST";
export const SET_SAVED_CHECKLISTS = "SET_SAVED_CHECKLISTS";
export const SET_UPDATED_CHECKLISTS = "SET_UPDATED_CHECKLISTS";
export const SET_CHECKLIST_FETCHING = "SET_CHECKLIST_FETCHING";
