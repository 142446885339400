import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { Row } from "@styles/grid";
import TextInput from "@components/inputs/TextInput";
import Select from "@components/inputs/Select";
import { ButtonPrimary, ButtonSecondary, ButtonSecondaryLink } from "@components/Button";
import { Form, FormButtons } from "@styles/form";
import { emptyIfNull } from "@utils/string";
import { updateSchoolPartners } from "@actions/schoolPartners.action";
import { getChecklistsByCollege } from "@actions/checklist.action";
import { schoolPartnersValidationSchema } from "@utils/validation";
import { getAllPartners } from "@actions/partners.action";
import { getAllColleges } from "@actions/colleges.action";

const setFormikStateObject = ({ id, name, label }) => ({
  id: id,
  value: id,
  label: name || label,
});

const SchoolPartnersViewForm = ({ schoolPartners }) => {
  const [schoolPartnersState, setSchoolPartnersState] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const loading = useSelector(state => state.schoolsPartners.loading);
  const partners = useSelector(state => state.partners.all);
  const colleges = useSelector(state => state.colleges.colleges);
  const collegeChecklists = useSelector(state => state.checklists.checklistsByCollege);
  const dispatch = useDispatch();

  useEffect(() => {
    if (schoolPartners) {
      setSchoolPartnersState(schoolPartners);
    }
  }, [schoolPartners]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      partner_school_id: schoolPartnersState ? emptyIfNull(schoolPartnersState.partner_school_id) : "",
      partner: schoolPartnersState?.partner ? setFormikStateObject(schoolPartnersState.partner) : null,
      school: schoolPartnersState?.school ? setFormikStateObject(schoolPartnersState.school) : null,
      checklist: schoolPartnersState?.checklist ? setFormikStateObject(schoolPartnersState.checklist) : null,
    },
    validationSchema: schoolPartnersValidationSchema,
    onSubmit: async ({ partner_school_id, partner, school, checklist }) => {
      try {
        const newSchoolPartners = {
          partner_school_id,
          partner_id: partner.id,
          school_id: school.id,
          checklist_id: checklist.id,
        };

        const savedSchoolPartners = await dispatch(updateSchoolPartners(schoolPartners.id, newSchoolPartners));

        if (savedSchoolPartners.data) {
          setSchoolPartnersState({ ...savedSchoolPartners.data, partner, school, checklist });
          setEditMode(false);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
  });

  useEffect(() => {
    if (schoolPartners) {
      (async () => {
        const isCollegesNotEmpty = colleges && colleges.length;
        const isPartnersNotEmpty = partners && partners.length;

        await Promise.all([
          !isCollegesNotEmpty && dispatch(getAllColleges()),
          !isPartnersNotEmpty && dispatch(getAllPartners()),
          editMode && dispatch(getChecklistsByCollege(schoolPartners.school_id)),
        ]);
      })();
    }
  }, [schoolPartners, editMode]);

  const handleCancel = () => {
    setEditMode(prevMode => !prevMode);

    formik.setValues({
      partner_school_id: schoolPartnersState ? emptyIfNull(schoolPartnersState.partner_school_id) : "",
      partner: schoolPartnersState?.partner ? setFormikStateObject(schoolPartnersState.partner) : null,
      school: schoolPartnersState?.school ? setFormikStateObject(schoolPartnersState.school) : null,
      checklist: schoolPartnersState?.checklist ? setFormikStateObject(schoolPartnersState.checklist) : null,
    });
  };

  const getChecklistsBySchoolId = async schoolId => {
    try {
      await dispatch(getChecklistsByCollege(schoolId));
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const handleSelect = (fieldName, selected) => {
    formik.setFieldValue(fieldName, selected);
  };

  const handleSchoolSelect = school => {
    handleSelect("school", school);
    handleSelect("checklist", null);
    getChecklistsBySchoolId(school.id);
  };

  const partnersSelectOptions =
    partners && partners.length
      ? partners.map(partner => ({
          id: partner.id,
          value: partner.id,
          label: partner.name,
        }))
      : [];

  const collegesSelectOptions =
    colleges && colleges.length
      ? colleges.map(college => ({
          id: college.id,
          value: college.id,
          label: college.name,
        }))
      : [];

  const collegeChecklistsSelectOptions =
    collegeChecklists && collegeChecklists.length
      ? [
          { id: null, value: null, label: "None" },
          ...collegeChecklists.map(collegeChecklist => ({
            id: collegeChecklist.id,
            value: collegeChecklist.id,
            label: collegeChecklist.name,
          })),
        ]
      : [];

  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextInput
        label="Partner School ID"
        labelPosition="left"
        fluid
        placeholder={editMode ? "Partner School ID" : ""}
        name="partner_school_id"
        id="partner_school_id"
        value={formik.values.partner_school_id}
        onChange={formik.handleChange}
        formik={formik}
        isreadonly={!editMode}
        readOnly={!editMode}
      />
      {editMode ? (
        <Select
          label="Partner"
          labelPosition="left"
          placeholder="Select Partner"
          options={partnersSelectOptions}
          value={formik.values.partner}
          onChange={partner => handleSelect("partner", partner)}
          fluid
          formik={formik}
          name="partner"
          id="partner"
        />
      ) : (
        <TextInput
          label="Partner"
          labelPosition="left"
          fluid
          value={formik.values.partner?.label}
          isreadonly={!editMode}
          readOnly={!editMode}
        />
      )}
      {editMode ? (
        <Select
          label="School"
          labelPosition="left"
          placeholder="Select School"
          options={collegesSelectOptions}
          value={formik.values.school}
          onChange={handleSchoolSelect}
          fluid
          formik={formik}
          name="school"
          id="school"
        />
      ) : (
        <TextInput
          label="School"
          labelPosition="left"
          fluid
          value={formik.values.school?.label}
          isreadonly={!editMode}
          readOnly={!editMode}
        />
      )}
      {editMode ? (
        <Select
          label="Checklist"
          labelPosition="left"
          placeholder="Select Checklist"
          options={collegeChecklistsSelectOptions}
          value={formik.values.checklist}
          onChange={checklist => handleSelect("checklist", checklist)}
          fluid
          formik={formik}
          name="checklist"
          id="checklist"
          disabled={!collegeChecklistsSelectOptions || !collegeChecklistsSelectOptions.length}
        />
      ) : (
        <TextInput
          label="Checklist"
          labelPosition="left"
          fluid
          value={formik.values.checklist?.label}
          isreadonly={!editMode}
          readOnly={!editMode}
        />
      )}
      <Row margin="3rem 0" direction="column" align="flex-end">
        <FormButtons>
          {!editMode && (
            <ButtonSecondaryLink type="button" outlined uppercase href="/schools-partners">
              Back to Schools Partners
            </ButtonSecondaryLink>
          )}
          <ButtonSecondary type="button" uppercase onClick={handleCancel}>
            {editMode ? "cancel" : "Edit"}
          </ButtonSecondary>
          {editMode && (
            <ButtonPrimary type="submit" onClick={formik.handleSubmit} uppercase disabled={loading}>
              Save School Partners
            </ButtonPrimary>
          )}
        </FormButtons>
      </Row>
    </Form>
  );
};

export default SchoolPartnersViewForm;
