import React from "react";
import { Redirect, Route } from "react-router-dom";

export const PrivateRoute = ({ component: Component, roles = [], ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const user = JSON.parse(localStorage.getItem("user"));
      const token = localStorage.getItem("token");
      const userHasPrivileges =
        user && user.role && roles && roles.length ? user && roles.includes(user.role) : !roles.length ? true : false;

      if (userHasPrivileges && token) {
        return <Component {...props} />;
      } else if (!userHasPrivileges && token) {
        return (
          <Redirect
            to={{
              pathname: "",
              state: { from: props.location },
            }}
          />
        );
      }

      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);
