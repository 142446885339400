import styled from "styled-components";

export const Backdrop = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
`;

export const ModalWrapper = styled.div`
  background-color: white;
  position: absolute;
  border-radius: 5px;
  padding: 0 2rem 2rem;
`;

export const TitleBar = styled.div`
  position: relative;
  justify-content: space-between;
  display: flex;
  border-bottom: 1px solid gainsboro;
  margin-bottom: 1.5rem;
  margin-left: -2rem;
  margin-right: -2rem;
  padding: 1rem;
`;

export const Close = styled.button`
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: 0;
  right: 0%;
  font-weight: bold;
  font-size: 2rem;
  border: 0;
  background: transparent;
  cursor: pointer;
`;
