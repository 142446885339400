import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const TitleH1 = styled.h1`
  font-size: 2.7rem;
  color: ${({ color, theme: { fontColor } }) => color || fontColor};
  text-transform: ${({ uppercase = false }) => uppercase && "uppercase"};
  text-align: ${({ align = "left" }) => align};
  letter-spacing: ${({ spacing = 0.21 }) => `${spacing}px`};
  margin: ${({ margin = 0 }) => margin};
  font-weight: ${({ weight = "400" }) => weight};
`;

export const TitleH2 = styled.h2`
  font-size: 2.4rem;
  color: ${({ color, theme: { fontColor } }) => color || fontColor};
  text-transform: ${({ uppercase = false }) => uppercase && "uppercase"};
  text-align: ${({ align = "left" }) => align};
  letter-spacing: ${({ spacing = 0.21 }) => `${spacing}px`};
  margin: ${({ margin = 0 }) => margin};
`;

export const TitleH3 = styled.h3`
  font-size: 2rem;
  color: ${({ color, theme: { fontColor } }) => color || fontColor};
  text-transform: ${({ uppercase = false }) => uppercase && "uppercase"};
  text-align: ${({ align = "left" }) => align};
  letter-spacing: ${({ spacing = 0.21 }) => `${spacing}px`};
  margin: ${({ margin = 0 }) => margin};
`;

export const Span = styled.span`
  font-size: ${({ size = 1.2 }) => `${size}rem`};
  color: ${({ color, theme: { fontColor } }) => color || fontColor};
  text-transform: ${({ uppercase = false }) => uppercase && "uppercase"};
  text-align: ${({ align = "left" }) => align};
  letter-spacing: ${({ spacing = 0.21 }) => `${spacing}px`};
  margin: ${({ margin = 0 }) => margin};
  font-weight: ${({ weight = "400" }) => weight};
  line-height: ${({ lineHeight }) => lineHeight || "unset"};
`;
export const Paragraph = styled.p`
  font-size: ${({ size = 1.2 }) => `${size}rem`};
  color: ${({ color, theme: { fontColor } }) => color || fontColor};
  text-transform: ${({ uppercase = false }) => uppercase && "uppercase"};
  text-align: ${({ align = "left" }) => align};
  letter-spacing: ${({ spacing = 0.21 }) => `${spacing}px`};
  margin: ${({ margin = 0 }) => margin};
  line-height: ${({ lineHeight }) => lineHeight || "unset"};
  font-weight: ${({ weight = "400" }) => weight};
  line-height: ${({ lineHeight }) => lineHeight || "unset"};
`;

export const TextLink = styled(Link)`
  font-size: ${({ size = 1.2 }) => `${size}rem`};
  color: ${({ color, theme: { fontColor } }) => color || fontColor};
  text-transform: ${({ uppercase = false }) => uppercase && "uppercase"};
  text-align: ${({ align = "left" }) => align};
  letter-spacing: ${({ spacing = 0.21 }) => `${spacing}px`};
  margin: ${({ margin = 0 }) => margin};
  line-height: ${({ lineHeight }) => lineHeight || "unset"};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const TextLinkExternal = styled.a`
  font-size: ${({ size = 1.2 }) => `${size}rem`};
  color: ${({ color, theme: { fontColor } }) => color || fontColor};
  text-transform: ${({ uppercase = false }) => uppercase && "uppercase"};
  text-align: ${({ align = "left" }) => align};
  letter-spacing: ${({ spacing = 0.21 }) => `${spacing}px`};
  margin: ${({ margin = 0 }) => margin};
  text-decoration: ${({ underline = false }) => underline && "underline"};
  line-height: ${({ lineHeight }) => lineHeight || "unset"};

  &:hover {
    text-decoration: underline;
  }
`;

export const ErrorMessage = styled.div`
  margin: ${({ position }) => (position === "top" ? "0.7rem 0 0 0" : "-2rem 0 3rem 0")};
  text-align: right;
  color: red;
  font-size: 1.4rem;
`;

const StylelessHeaders = css`
  margin: unset;
  font-size: unset;
  font-weight: unset;
`;

export const StylelessH1 = styled.h1`
  ${StylelessHeaders}
`;

export const StylelessH2 = styled.h2`
  ${StylelessHeaders}
`;

export const StylelessH3 = styled.h3`
  ${StylelessHeaders}
`;
