import React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Layout from "@components/Layout";
import { ButtonPrimaryLink, ButtonSecondaryLink } from "@components/Button";
import { Row } from "@styles/grid";
import PartnerCategoriesTable from "./PartnerCategoriesTable";
import { getAllPartnerCategories } from "@actions/partnerCategories.action";
import { useSelector, useDispatch } from "react-redux";

const PartnerCategories = () => {
    const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: 3rem auto 5.5rem;
    max-width: 125rem;
    width: 100%;
    padding: 0 2.5rem;
  `;

    const { all: partnerCategories } = useSelector(({ partnerCategories }) => partnerCategories);
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            if (!partnerCategories || !partnerCategories.length) {
                await dispatch(getAllPartnerCategories);
            }
        })();
    }, [partnerCategories]);

    return (
        <Layout title="partnercategories">
            <Content>
                <Row justify="space-between" margin="2rem 0">
                    <ButtonSecondaryLink outlined uppercase href="/">
                        Back to Dashboard
                    </ButtonSecondaryLink>
                    <ButtonPrimaryLink uppercase href="/partnercategories/add">
                        Add Partner Category
                    </ButtonPrimaryLink>
                </Row>
                <PartnerCategoriesTable />
            </Content>
        </Layout>
    );
};

export default PartnerCategories;
