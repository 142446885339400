import styled from "styled-components";

import { ButtonPrimaryLink, ButtonSecondaryLink } from "@components/Button";
import Layout from "@components/Layout";

import { Row } from "@styles/grid";

import BrandsTable from "./SchoolsPartnersTable";
import { getAllSchoolsPartners } from "@actions/schoolPartners.action";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem auto 5.5rem;
  max-width: 125rem;
  width: 100%;
  padding: 0 2.5rem;
`;

const SchoolsPartners = () => {
  const { all: schoolsPartners } = useSelector(({ schoolsPartners }) => schoolsPartners);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (!schoolsPartners || !schoolsPartners.length) {
        await dispatch(getAllSchoolsPartners("school,partner"));
      }
    })();
  }, [schoolsPartners]);

  return (
    <Layout pageTitle="Schools Partners">
      <Content>
        <Row justify="space-between" margin="2rem 0">
          <ButtonSecondaryLink outlined uppercase href="/">
            Back to Dashboard
          </ButtonSecondaryLink>
          <ButtonPrimaryLink uppercase href="/schools-partners/add">
            Add School Partners
          </ButtonPrimaryLink>
        </Row>
        <BrandsTable />
      </Content>
    </Layout>
  );
};

export default SchoolsPartners;
