import styled from "styled-components";

import Layout from "@components/Layout";

import { Container } from "@styles/grid";

import PartnerViewForm from "./PartnerViewForm";
import { getPartnerById } from "@actions/partners.action";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 5.5rem 5.5rem;
`;

const PartnerView = () => {
  const [partner, setPartner] = useState(null);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    (async () => {
      setPartner(await dispatch(getPartnerById(params.id, "iframe")));
    })();
  }, [params.id]);

  return (
    <Layout pageTitle={`${partner ? partner.name : ""} Partner`}>
      <Container>
        <Content>
          <PartnerViewForm partner={partner} />
        </Content>
      </Container>
    </Layout>
  );
};

export default PartnerView;
