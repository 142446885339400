import styled from "styled-components";
import theme from "@styles";
import { CheckboxInput, Checkmark } from "@components/inputs/Checkbox/Checkbox.styled";

export const LabelTitle = styled.div`
  height: 18px;
  line-height: 18px;
  font-weight: ${({ weight = 500 }) => weight};
  margin: 1rem 0 0.8rem 1.5rem;
  font-size: ${({ size = 1.6 }) => `${size}rem`};
  text-transform: ${({ uppercase = false }) => uppercase && "uppercase"};
  color: ${({ theme: { fontColor } }) => fontColor};
  max-width: 20rem;
  width: ${({ position }) => (position === "left" || position === "left-top" ? "100%" : null)};
`;

export const Container = styled.label`
  flex: ${({ fluid }) => fluid && 1};
  letter-spacing: -0.02em;
  font-size: 1.4rem;
  outline: none;
  transition: all 0.3 ease;

  ${({ position = "top" }) => {
    switch (position) {
      case "right":
        return `display: inline-flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          align-items: center;

          ${LabelTitle} {
            margin: 0;
            padding-left: 12px;
            height: 100%;
            display: flex;
            align-items: center;
          }`;
      case "left":
        return `
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;

          ${LabelTitle} {
            margin: 0;
            padding-right: 12px;
            height: 100%;
            display: flex;
            align-items: center;
          }
      `;
      case "left-top":
        return `
          display: inline-flex;
          justify-content: flex-start;

          ${LabelTitle} {
            margin: 0;
            padding-right: 12px;
            padding-top: 1rem;
            height: 100%;
            display: flex;
          }
      `;
      default:
        return null;
    }
  }}

  &:hover {
    ${CheckboxInput} ~ ${Checkmark} {
      border: 1.5px solid ${theme.colorDarkGrey};
    }

    ${CheckboxInput}:disabled ~ ${Checkmark} {
      border: 1.5px solid ${theme.colorBorder};
    }

    ${CheckboxInput}:checked ~ ${Checkmark} {
      background-color: ${theme.colorBlue700};
      border: 1.5px solid ${theme.colorBlue700};
    }
  }

  ${Checkmark}:after {
    position: absolute;
    left: 6px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;
