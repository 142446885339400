import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useFormik } from "formik";
import { createBucket } from "@actions/buckets.action";
import { addBucketValidationSchema } from "@utils/validation";

import { Form } from "@styles/form";
import { Row, Indent } from "@styles/grid";
import TextInput from "@components/inputs/TextInput";
import Checkbox from "@components/inputs/Checkbox";
import { ButtonPrimary, ButtonSecondaryLink } from "@components/Button";
import slugify from "slugify";

const BucketsAddForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.buckets.loading);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      slug: "",
      initial: true,
      enabled: true,
    },
    validationSchema: addBucketValidationSchema,
    onSubmit: async ({ name, slug, initial, enabled }) => {
      const nameToLowerCase = name.toLowerCase();

      try {
        const newBucket = {
          name: nameToLowerCase,
          slug,
          initial,
          enabled,
        };

        const savedBucket = await dispatch(createBucket(newBucket));

        if (savedBucket.data) {
          return history.push("/buckets");
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
  });

  useEffect(() => {
    if (formik.values.name) {
      formik.setFieldValue("slug", slugify(formik.values.name, { lower: true }));
    }
  }, [formik.values.name]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextInput
        position
        label="Name"
        labelPosition="left"
        fluid
        placeholder="Buckets Name"
        name="name"
        id="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        formik={formik}
      />

      <TextInput
        label="Slug"
        labelPosition="left"
        fluid
        placeholder="Bucket Slug"
        name="slug"
        id="slug"
        value={formik.values.slug}
        onChange={formik.handleChange}
        formik={formik}
      />
      <Indent margin="1rem 0">
        <Checkbox
          label="Initial bucket"
          name="initial"
          id="initial"
          labelFontSize={1.8}
          value={formik.values.initial}
          checked={formik.values.initial}
          formik={formik}
          colorDisabled
        />
      </Indent>

      <Indent margin="1rem 0">
        <Checkbox
          label="Enabled / Disabled"
          name="enabled"
          id="enabled"
          labelFontSize={1.8}
          value={formik.values.enabled}
          checked={formik.values.enabled}
          onClick={formik.handleChange}
          formik={formik}
        />
      </Indent>

      <Row margin="3rem 0" justify="flex-end">
        <Indent margin="0 2rem 0">
          <ButtonSecondaryLink uppercase outlined href="/buckets">
            back to buckets
          </ButtonSecondaryLink>
        </Indent>
        <ButtonPrimary type="submit" uppercase disabled={loading} onClick={formik.handleSubmit}>
          Create bucket
        </ButtonPrimary>
      </Row>
    </Form>
  );
};

export default BucketsAddForm;
