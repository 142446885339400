import React from "react";
import styled from "styled-components";

import Layout from "@components/Layout";

import { Container } from "@styles/grid";
import RolesAddForm from "./RolesAddForm";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 5.5rem 5.5rem;
`;

const RolesAdd = () => {
  return (
    <Layout pageTitle="Add Role">
      <Container>
        <Content>
          <RolesAddForm />
        </Content>
      </Container>
    </Layout>
  );
};

export default RolesAdd;
