import { themeSchema } from "@styles";
import { getDefaultButtonColors } from "@utils/styleTheme";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import store from "@services/store";
import GlobalStyle from "@styles/global";
import { ThemeProvider } from "styled-components";
import Notice from "@components/Notice";
import { setUserData } from "@actions/auth.action";

const theme = {
  fontColor: themeSchema.fontColor,
  primaryBgColor: themeSchema.primaryBgColor,
  primaryBtn: getDefaultButtonColors(themeSchema.primaryBtn),
  secondaryBtn: getDefaultButtonColors(themeSchema.secondaryBtn),
  sectionAccent: themeSchema.sectionAccent,
  sectionDarkBg: themeSchema.sectionDarkBg,
  sectionLightBg: themeSchema.sectionLightBg,
  listAccentColor: themeSchema.listAccentColor,
  withCCLBrand: 1,
};

store.dispatch(setUserData());

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <App />
        <Notice />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
