import {
  SET_PRODUCTS,
  SET_PRODUCT_ERROR,
  SET_CREATED_PRODUCT,
  SET_PRODUCTS_LOADING,
  SET_UPDATED_PRODUCT,
  REMOVE_DELETED_PRODUCT,
} from "../types/product.types";
import { SET_PARTNERS_FETCHING } from "../types/partners.types";
import { AUTH_LOGOUT } from "../types/auth.types";

const initialState = {
  products: [],
  error: "",
  loading: false,
  fetching: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.products,
        fetching: false,
      };
    case SET_CREATED_PRODUCT:
      return {
        ...state,
        products: state.products && state.products.length ? [...state.products, action.product] : [],
        loading: false,
        error: "",
      };
    case SET_UPDATED_PRODUCT: {
      const copyOfProducts = [...state.products];
      const foundedProductsIndex = copyOfProducts.findIndex(({ id }) => id === action.updatedProduct.id);
      copyOfProducts[foundedProductsIndex] = action.updatedProduct;

      return {
        ...state,
        products: [...copyOfProducts],
        loading: false,
        error: "",
      };
    }
    case REMOVE_DELETED_PRODUCT: {
      const copyOfProducts = [...state.products];
      const foundedProductsIndex = copyOfProducts.findIndex(({ id }) => id === action.productId);
      copyOfProducts.splice(foundedProductsIndex, 1);

      return {
        ...state,
        products: [...copyOfProducts],
        error: "",
        loading: false,
      };
    }
    case SET_PRODUCTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_PARTNERS_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case SET_PRODUCT_ERROR:
      return {
        ...state,
        error: Array.isArray(action.error) ? action.error[0] : action.error,
        loading: false,
      };
    case AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
