import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Row, Indent } from "@styles/grid";
import { Span } from "@styles/typography";
import TextInput from "@components/inputs/TextInput";
import { ButtonPrimary, ButtonSecondaryLink, ButtonSecondary, ButtonRoundedPlus } from "@components/Button";
import { Form, FormButtons, FormInputs } from "@styles/form";
import BundleViewFormProducts from "./BundleViewFormProducts";
import generateUUID from "@lib/generateUUID";
import { updateBundle } from "@actions/bundles.action";
import { getProductById } from "@actions/products.action";
import { setNotice } from "@actions/notice.action";
import { editBundleValidationSchema } from "@utils/validation";
import SuggestedProducts from "@components/ProductSuggested";

const BundleViewForm = ({ bundle }) => {
  const history = useHistory();
  const params = useParams();
  const [editMode, setEditMode] = useState(false);
  const [savedBundle, setSavedBundle] = useState(null);
  const [savedAssociatedProduct, setSavedAssociatedProduct] = useState(null);
  const [renderProducts, setRenderProducts] = useState({
    key: generateUUID(),
    product_id: null,
    product_name: null,
  });
  const dispatch = useDispatch();
  const loading = useSelector(state => state.bundles.loading);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      associatedProduct: null,
      name: "",
      bundle_items: [],
    },
    validationSchema: editBundleValidationSchema,
    onSubmit: async ({ associatedProduct, name, bundle_items }) => {
      const newBundle = {
        id: associatedProduct.id,
        name,
        bundle_items,
      };

      const updatedBundleResponse = await dispatch(updateBundle(savedBundle.id, newBundle));

      setSavedBundle(updatedBundleResponse.data);
      setEditMode(false);
      dispatch(setNotice({ message: updatedBundleResponse.message, type: "success" }));
      history.push(`/bundles/${updatedBundleResponse.data.id}`);
    },
  });

  const setInitialState = () => {
    const savedRenderProducts = savedBundle.bundle_items.map(({ id, product }) => ({
      key: generateUUID(),
      id,
      product_id: product.id,
      product_name: product.name,
    }));

    formik.setValues({
      associatedProduct: savedAssociatedProduct,
      name: savedBundle.name,
      bundle_items: savedRenderProducts.map(({ id, product_id }) => ({
        id,
        product_id,
      })),
    });

    setRenderProducts([...savedRenderProducts]);
  };

  useEffect(() => {
    if (bundle) {
      setSavedBundle(bundle);
    }
  }, [bundle]);

  useEffect(() => {
    const getAssociatedProduct = async () => {
      setSavedAssociatedProduct(await dispatch(getProductById(params.id)));
    };

    getAssociatedProduct();
  }, []);

  useEffect(() => {
    if (savedBundle) {
      setInitialState();
    }
  }, [editMode, savedBundle]);

  const changeRenderProducts = (fields, key) =>
    renderProducts.map(renderProduct => {
      if (renderProduct.key !== key) {
        return { ...renderProduct };
      }

      return {
        ...renderProduct,
        ...fields,
      };
    });

  const setProductsToFormikValue = products => {
    formik.setFieldValue(
      "bundle_items",
      products.filter(({ product_id }) => product_id).map(({ id, product_id }) => ({ id, product_id }))
    );

    setRenderProducts([...products]);
  };

  const onChangeProduct = (product, suggestionKey) => {
    const foundedProduct = changeRenderProducts(
      {
        product_id: product.id,
      },
      suggestionKey
    );

    setProductsToFormikValue(foundedProduct);
  };

  const onClearProduct = suggestionKey => {
    const foundedProduct = changeRenderProducts(
      {
        product_id: null,
      },
      suggestionKey
    );

    setProductsToFormikValue(foundedProduct);
  };

  const removeProduct = suggestionKey => {
    if (renderProducts.length > 1) {
      const copyOfRenderProducts = [...renderProducts];
      const foundedIndex = copyOfRenderProducts.findIndex(({ key }) => key === suggestionKey);
      copyOfRenderProducts.splice(foundedIndex, 1);

      setProductsToFormikValue(copyOfRenderProducts);
    }
  };

  const addRenderProduct = () => {
    setRenderProducts(prevRenderProducts => [
      ...prevRenderProducts,
      {
        key: generateUUID(),
        id: null,
        product_id: null,
      },
    ]);
  };

  const onSelectProduct = product => {
    formik.setFieldValue("associatedProduct", product);
  };

  const handleCancel = () => {
    setEditMode(prevMode => !prevMode);

    formik.setValues({
      associatedProduct: savedAssociatedProduct,
      name: savedBundle.name,
      bundle_items: savedBundle.bundle_items.map(({ id, product_id }) => ({ id, product_id })),
    });
  };

  if (bundle) {
    return (
      <Form handleSubmit={formik.handleSubmit}>
        <FormInputs>
          <Indent margin="0 0 2rem">
            <SuggestedProducts
              product={formik.values.associatedProduct}
              onSelectProduct={onSelectProduct}
              readOnly={!editMode}
            />
          </Indent>
          <TextInput
            position
            label="Name"
            labelPosition="left"
            fluid
            placeholder="Bundle Name"
            value={formik.values.name}
            id="name"
            name="name"
            formik={formik}
            onChange={formik.handleChange}
            isreadonly={!editMode}
            readOnly={!editMode}
          />
        </FormInputs>
        {renderProducts && renderProducts.length ? (
          <BundleViewFormProducts
            renderProducts={renderProducts}
            onChangeProduct={onChangeProduct}
            removeProduct={removeProduct}
            onClearProduct={onClearProduct}
            associatedProduct={formik.values.associatedProduct}
            editMode={editMode}
          />
        ) : null}

        {formik.errors.bundle_items && formik.touched.bundle_items && (
          <Span color="red" align="right" size={1.4}>
            {Array.isArray(formik.errors.bundle_items)
              ? formik.errors.bundle_items[formik.errors.bundle_items.length - 1][
                  Object.keys(formik.errors.bundle_items[formik.errors.bundle_items.length - 1])
                ]
              : formik.errors.bundle_items}
          </Span>
        )}
        {editMode && (
          <Row margin="3rem 0 4rem" direction="column" align="flex-end">
            <ButtonRoundedPlus onClick={addRenderProduct} />
          </Row>
        )}
        <Row margin="3rem 0" direction="column" align="flex-end">
          <FormButtons>
            {!editMode && (
              <ButtonSecondaryLink outlined uppercase href="/bundles">
                Back to bundles
              </ButtonSecondaryLink>
            )}
            <ButtonSecondary uppercase onClick={handleCancel}>
              {editMode ? "cancel" : "Edit"}
            </ButtonSecondary>
            {editMode && (
              <ButtonPrimary type="submit" onClick={formik.handleSubmit} uppercase disabled={loading}>
                {loading ? "Wait..." : "Save Bundle"}
              </ButtonPrimary>
            )}
          </FormButtons>
        </Row>
      </Form>
    );
  }

  return null;
};

export default BundleViewForm;
