import React from "react";
import styled from "styled-components";

import Layout from "@components/Layout";
import CategoryAddForm from "./CategoryAddForm";

import { Container } from "@styles/grid";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 5.5rem 5.5rem;
`;

const CategoriesAdd = () => {
  return (
    <Layout pageTitle="Add Category">
      <Container>
        <Content>
          <CategoryAddForm />
        </Content>
      </Container>
    </Layout>
  );
};

export default CategoriesAdd;
