import styled from "styled-components";

import Layout from "@components/Layout";

import { Container } from "@styles/grid";

import BundleViewForm from "./BundleViewForm";
import { getBundleById } from "@actions/bundles.action";
import { getProductById } from "@actions/products.action";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 5.5rem 5.5rem;
`;

const BundleView = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [bundle, setBundle] = useState(null);
  const [associatedProduct, setAssociatedProduct] = useState(null);

  useEffect(() => {
    (async () => {
      const getBundle = dispatch(getBundleById(params.id, "bundle_items,bundle_items.product"));
      const getAssociatedProduct = dispatch(getProductById(params.id));

      const [bundleResponse, associatedProductResponse] = await Promise.all([getBundle, getAssociatedProduct]);

      setBundle(bundleResponse);
      setAssociatedProduct(associatedProductResponse);
    })();
  }, []);

  return (
    <Layout pageTitle={`${bundle ? bundle.name : ""} Bundle`}>
      <Container>
        <Content>
          <BundleViewForm bundle={bundle} associatedProduct={associatedProduct} />
        </Content>
      </Container>
    </Layout>
  );
};

export default BundleView;
