import styled from "styled-components";

import Layout from "@components/Layout";

import { Container } from "@styles/grid";

import SchoolViewForm from "./SchoolViewForm";
import { getSchoolById } from "@actions/colleges.action";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 5.5rem 5.5rem;
`;

const SchoolView = () => {
  const [school, setSchool] = useState(null);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    (async () => {
      setSchool(await dispatch(getSchoolById(params.id)));
    })();
  }, [params.id]);

  return (
    <Layout pageTitle={school ? school.name : "School"}>
      <Container>
        <Content>
          <SchoolViewForm school={school} />
        </Content>
      </Container>
    </Layout>
  );
};

export default SchoolView;
