import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { Row, Indent } from "@styles/grid";
import TextInput from "@components/inputs/TextInput";
import Checkbox from "@components/inputs/Checkbox";
import { ButtonPrimary, ButtonSecondary, ButtonSecondaryLink } from "@components/Button";
import { Form, FormButtons } from "@styles/form";
import { editRoleValidationSchema } from "@utils/validation";
import { updateRole } from "@actions/roles.action";
import { useHistory } from "react-router-dom";

const RoleViewForm = ({ role }) => {
  const [roleState, setRoleState] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.roles.loading);

  useEffect(() => {
    if (role) {
      setRoleState(role);
    }
  }, [role]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: roleState ? roleState.name : "",
      can_create: roleState ? Boolean(roleState.can_create) : false,
      can_edit: roleState ? Boolean(roleState.can_edit) : false,
      can_access_api: roleState ? Boolean(roleState.can_access_api) : false,
      can_access_admin: roleState ? Boolean(roleState.can_access_admin) : false,
    },
    validationSchema: editRoleValidationSchema,
    onSubmit: async ({ name, can_create, can_edit, can_access_api, can_access_admin }) => {
      try {
        const newRole = {
          name,
          can_create,
          can_edit,
          can_access_api,
          can_access_admin,
        };

        const savedRole = await dispatch(updateRole(role.id, newRole));

        if (savedRole.data) {
          setRoleState(savedRole.data);
          setEditMode(false);
          history.push("/roles");
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
  });

  const handleCancel = () => {
    setEditMode(prevMode => !prevMode);

    formik.setValues({
      name: roleState.name,
      can_create: Boolean(roleState.can_create),
      can_edit: Boolean(roleState.can_edit),
      can_access_api: Boolean(roleState.can_access_api),
      can_access_admin: Boolean(roleState.can_access_admin),
    });
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextInput
        position
        label="Name"
        labelPosition="left"
        fluid
        placeholder={editMode ? "Roles Name" : ""}
        name="name"
        id="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        formik={formik}
        isreadonly={!editMode}
        readOnly={!editMode}
      />

      <Indent margin="1rem 0">
        <Checkbox
          label="Can create"
          name="can_create"
          id="can_create"
          labelFontSize={1.8}
          value={formik.values.can_create}
          checked={formik.values.can_create}
          onClick={!editMode ? () => null : formik.handleChange}
          formik={formik}
          readOnly={!editMode}
        />
      </Indent>

      <Indent margin="1rem 0">
        <Checkbox
          label="Can edit"
          name="can_edit"
          id="can_edit"
          labelFontSize={1.8}
          value={formik.values.can_edit}
          checked={formik.values.can_edit}
          onClick={!editMode ? () => null : formik.handleChange}
          formik={formik}
          readOnly={!editMode}
        />
      </Indent>

      <Indent margin="1rem 0">
        <Checkbox
          label="Can access api"
          name="can_access_api"
          id="can_access_api"
          labelFontSize={1.8}
          value={formik.values.can_access_api}
          checked={formik.values.can_access_api}
          onClick={!editMode ? () => null : formik.handleChange}
          formik={formik}
          readOnly={!editMode}
        />
      </Indent>

      <Indent margin="1rem 0">
        <Checkbox
          label="Can access admin"
          name="can_access_admin"
          id="can_access_admin"
          labelFontSize={1.8}
          value={formik.values.can_access_admin}
          checked={formik.values.can_access_admin}
          onClick={!editMode ? () => null : formik.handleChange}
          formik={formik}
          readOnly={!editMode}
        />
      </Indent>

      <Row margin="3rem 0" direction="column" align="flex-end">
        <FormButtons>
          {!editMode && (
            <ButtonSecondaryLink outlined uppercase href="/roles">
              Back to Roles
            </ButtonSecondaryLink>
          )}
          <ButtonSecondary uppercase onClick={handleCancel}>
            {editMode ? "cancel" : "Edit"}
          </ButtonSecondary>{" "}
          {editMode && (
            <ButtonPrimary type="submit" onClick={formik.handleSubmit} uppercase disabled={loading}>
              Save Role
            </ButtonPrimary>
          )}
        </FormButtons>
      </Row>
    </Form>
  );
};

export default RoleViewForm;
