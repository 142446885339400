import { AUTH_LOGOUT } from "../types/auth.types";
import {
  REMOVE_DELETED_PARTNER,
  SET_CREATED_PARTNER,
  SET_PARTNERS,
  SET_PARTNERS_ERROR,
  SET_PARTNERS_LOADING,
  SET_UPDATED_PARTNER,
  SET_PARTNERS_FETCHING,
} from "../types/partners.types";

const initialState = {
  all: [],
  loading: false,
  fetching: false,
  error: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PARTNERS:
      return {
        ...state,
        all: action.partners,
        fetching: false,
      };
    case SET_CREATED_PARTNER:
      return {
        ...state,
        all: state.all && state.all.length ? [...state.all, action.partner] : [],
        loading: false,
        error: "",
      };
    case SET_UPDATED_PARTNER: {
      const copyOfPartners = [...state.all];
      const foundedPartnerIndex = copyOfPartners.findIndex(({ id }) => id === action.updatedPartner.id);
      copyOfPartners[foundedPartnerIndex] = action.updatedPartner;

      return {
        ...state,
        all: [...copyOfPartners],
        loading: false,
        error: "",
      };
    }
    case REMOVE_DELETED_PARTNER: {
      const copyOfPartners = [...state.all];
      const foundedPartnerIndex = copyOfPartners.findIndex(({ id }) => id === action.partnerId);
      copyOfPartners.splice(foundedPartnerIndex, 1);

      return {
        ...state,
        all: [...copyOfPartners],
        loading: false,
        error: "",
      };
    }
    case SET_PARTNERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_PARTNERS_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case SET_PARTNERS_ERROR:
      return {
        ...state,
        error: Array.isArray(action.error) ? action.error[0] : action.error,
        loading: false,
      };
    case AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
