import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { Row } from "@styles/grid";
import TextInput from "@components/inputs/TextInput";
import Textarea from "@components/inputs/Textarea";
import { ButtonPrimary, ButtonSecondary, ButtonSecondaryLink } from "@components/Button";
import { Form, FormButtons } from "@styles/form";
import { updateBrand } from "@actions/brands.action";
import ImageUploader from "@components/ImageUploader";
import { post } from "@api";
import { nullIfEmpty, emptyIfNull } from "@utils/string";
import { brandValidationSchema } from "@utils/validation";

const BrandViewForm = ({ brand }) => {
  const [imageLoading, setImageLoading] = useState(false);
  const [brandState, setBrandState] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const loading = useSelector(state => state.brands.loading);

  useEffect(() => {
    if (brand) {
      setBrandState(brand);
    }
  }, [brand]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: brandState ? emptyIfNull(brandState.name) : "",
      description: brandState ? emptyIfNull(brandState.description) : "",
      notes: brandState ? emptyIfNull(brandState.notes) : "",
      image: brandState ? emptyIfNull(brandState.image) : "",
    },
    validationSchema: brandValidationSchema,
    onSubmit: async ({ name, description, notes, image }) => {
      try {
        const newBrand = {
          name,
          description: nullIfEmpty(description),
          notes: nullIfEmpty(notes),
          image: nullIfEmpty(image),
        };

        const savedBrand = await dispatch(updateBrand(brand.id, newBrand));

        if (savedBrand.data) {
          setBrandState(savedBrand.data);
          setEditMode(false);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
  });

  const uploadImageOnChange = async image => {
    try {
      setImageLoading(true);

      const fData = new FormData();
      fData.append("image", image);

      const { imagePath } = await post("static/images/sponsors", fData);

      formik.setFieldValue("image", imagePath);
      setImageLoading(false);
    } catch (error) {
      setImageLoading(false);
      console.error(error);
    }
  };

  const handleRemoveImage = () => {
    formik.setFieldValue("image", "");
    formRef.current.reset();
  };

  const handleCancel = () => {
    setEditMode(prevMode => !prevMode);

    formik.setValues({
      name: brandState ? emptyIfNull(brandState.name) : "",
      description: brandState ? emptyIfNull(brandState.description) : "",
      notes: brandState ? emptyIfNull(brandState.notes) : "",
      image: brandState ? emptyIfNull(brandState.image) : "",
    });
  };

  if (brand) {
    return (
      <Form ref={formRef} onSubmit={formik.handleSubmit}>
        <TextInput
          label="Name"
          labelPosition="left"
          fluid
          placeholder={editMode ? "Brand Name" : ""}
          name="name"
          id="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          formik={formik}
          isreadonly={!editMode}
          readOnly={!editMode}
        />
        <Textarea
          label="Description"
          labelPosition="left-top"
          fluid
          placeholder={editMode ? "Brand Description" : ""}
          name="description"
          id="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          formik={formik}
          isreadonly={!editMode}
          readOnly={!editMode}
        />
        <Textarea
          label="Notes"
          labelPosition="left-top"
          fluid
          placeholder={editMode ? "Brand Notes" : ""}
          name="notes"
          id="notes"
          value={formik.values.notes}
          onChange={formik.handleChange}
          formik={formik}
          isreadonly={!editMode}
          readOnly={!editMode}
        />
        <Row direction="column" align="center">
          <ImageUploader
            title="Select Brand Image"
            name="image"
            id="image"
            imageSrc={formik.values.image}
            loading={imageLoading}
            onChange={uploadImageOnChange}
            onRemove={handleRemoveImage}
            maxSize={2}
            editMode={editMode}
          />
        </Row>
        <Row margin="3rem 0" direction="column" align="flex-end">
          <FormButtons>
            {!editMode && (
              <ButtonSecondaryLink type="button" outlined uppercase href="/brands">
                Back to Brands
              </ButtonSecondaryLink>
            )}
            <ButtonSecondary type="button" uppercase onClick={handleCancel}>
              {editMode ? "cancel" : "Edit"}
            </ButtonSecondary>
            {editMode && (
              <ButtonPrimary type="submit" onClick={formik.handleSubmit} uppercase disabled={loading}>
                Save Brand
              </ButtonPrimary>
            )}
          </FormButtons>
        </Row>
      </Form>
    );
  }

  return null;
};

export default BrandViewForm;
