import React, { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "@components/Table";
import { ButtonSecondary, ButtonPrimaryLink } from "@components/Button";
import { deleteChecklistById, getChecklists } from "@actions/checklist.action";
import Modal from "@components/Modal";
import { Span } from "@styles/typography";
import { Row, Indent } from "@styles/grid";
import GuardComponent from "@components/GuardComponent";
import { USER_ROLES } from "@constants";
import { setNotice } from "@actions/notice.action";
import { useHistory } from "react-router-dom";

const EditChecklist = ({ checklistId }) => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.checklists.loading);
  const me = useSelector(({ me }) => me);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const handleDeleteChecklist = () => async () => {
    try {
      await dispatch(deleteChecklistById(checklistId));
      setIsRemoveModalOpen(false);
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return (
    <Row justify="flex-end" padding="0 2rem 0 0">
      <Indent margin="0 1.5rem 0 0">
        <ButtonPrimaryLink uppercase href={`/checklists/${checklistId}`} outlined size="small" weight="bold">
          {me.orgAccess ? "edit" : "view"}
        </ButtonPrimaryLink>
      </Indent>
      <GuardComponent roles={[USER_ROLES.admin, USER_ROLES.college]}>
        <ButtonSecondary uppercase onClick={() => setIsRemoveModalOpen(true)} size="small" weight="bold">
          Remove
        </ButtonSecondary>
      </GuardComponent>

      <Modal isOpen={isRemoveModalOpen} onClose={() => setIsRemoveModalOpen(false)} title="Remove Checklist">
        <Indent margin="4rem 0">
          <Span size={1.8}>Are you sure you want to delete this checklist?</Span>
        </Indent>
        <Row justify="flex-end">
          <Indent margin="0 2rem 0">
            <ButtonSecondary outlined onClick={() => setIsRemoveModalOpen(false)} uppercase>
              Close
            </ButtonSecondary>
          </Indent>
          <ButtonSecondary autoFocus onClick={handleDeleteChecklist(checklistId)} uppercase disabled={loading}>
            Remove
          </ButtonSecondary>
        </Row>
      </Modal>
    </Row>
  );
};

const AdminChecklistsTable = () => {
  const history = useHistory();
  const { fetching, checklists } = useSelector(({ checklists }) => checklists);
  const me = useSelector(({ me }) => me);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (!checklists || !checklists.length) {
        if (me.role === USER_ROLES.college && me.schools && me.schools.length) {
          const userSchoolIds = `user_school_ids=${me.schools.join()}`;
          const params = ["include=school"];
          params.push(userSchoolIds);

          return await dispatch(getChecklists(params));
        } else if (me.role === USER_ROLES.admin || me.role === USER_ROLES.dataminer) {
          return await dispatch(getChecklists());
        } else {
          history.push("/");
          return dispatch(
            setNotice({
              message: "Sorry. You are not affiliated with any school or college",
              type: "info",
              delay: 7000,
            })
          );
        }
      }
    })();
  }, [checklists]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        isFiltered: true,
        isSorting: true,
      },
      {
        Header: "School",
        accessor: "school.name",
        isFiltered: true,
        isSorting: true,
      },
      {
        Header: "Location",
        accessor: "school.location",
        isFiltered: true,
        isSorting: true,
      },
      {
        Header: "Views",
        accessor: "views",
        isSorting: true,
      },
      {
        width: 100,
        id: "colEdit",
        Cell: ({ row }) => <EditChecklist checklistId={row.original.id} />,
      },
    ],
    []
  );

  const data = useMemo(() => checklists, [checklists]);

  return <Table columns={columns} data={data} hasGlobalFilter loading={fetching} />;
};

export default AdminChecklistsTable;
