import React from "react";
import { LabelTitle, Container } from "./Label.styled";

const Label = ({ children, title, fluid, position, tabIndex, onKeyPress, labelFontSize, labelWeight, uppercase }) => {
  const text = title && (
    <LabelTitle position={position} size={labelFontSize} weight={labelWeight} uppercase={uppercase}>
      {title}
    </LabelTitle>
  );

  return (
    <Container tabIndex={tabIndex} fluid={fluid} position={position} onKeyPress={onKeyPress} role="presentation">
      {text}
      {children}
    </Container>
  );
};

export default Label;
