import { AUTH_LOGOUT } from "../types/auth.types";
import {
  SET_BRANDS,
  SET_BRANDS_ERROR,
  SET_BRANDS_LOADING,
  SET_CREATED_BRAND,
  SET_UPDATED_BRAND,
  REMOVE_DELETED_BRAND,
  SET_BRANDS_FETCHING,
} from "../types/brands.types";

const initialState = {
  brands: [],
  loading: false,
  fetching: false,
  error: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BRANDS:
      return {
        ...state,
        brands: action.brands,
        fetching: false,
      };
    case SET_CREATED_BRAND:
      return {
        ...state,
        brands: state.brands && state.brands.length ? [...state.brands, action.brand] : [],
        loading: false,
        error: "",
      };
    case SET_UPDATED_BRAND: {
      const copyOfBrands = [...state.brands];
      const foundedBrandIndex = copyOfBrands.findIndex(({ id }) => id === action.updatedBrand.id);
      copyOfBrands[foundedBrandIndex] = action.updatedBrand;

      return {
        ...state,
        brands: [...copyOfBrands],
        loading: false,
        error: "",
      };
    }
    case REMOVE_DELETED_BRAND: {
      const copyOfBrands = [...state.brands];
      const foundedBrandIndex = copyOfBrands.findIndex(({ id }) => id === action.brandId);
      copyOfBrands.splice(foundedBrandIndex, 1);

      return {
        ...state,
        brands: [...copyOfBrands],
        loading: false,
        error: "",
      };
    }
    case SET_BRANDS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_BRANDS_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case SET_BRANDS_ERROR:
      return {
        ...state,
        error: Array.isArray(action.error) ? action.error[0] : action.error,
        loading: false,
      };
    case AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
