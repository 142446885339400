import styled from "styled-components";

import { ButtonPrimaryLink, ButtonSecondaryLink } from "@components/Button";
import Layout from "@components/Layout";

import { Row } from "@styles/grid";

import CategoriesTable from "./CategoriesTable";
import { getAllCategories } from "@actions/categories.action";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem auto 5.5rem;
  max-width: 125rem;
  width: 100%;
  padding: 0 2.5rem;
`;

const Categories = () => {
  const dispatch = useDispatch();
  const { categories } = useSelector(({ categories }) => categories);

  useEffect(() => {
    const getCategories = async () => {
      const isCategoriesNotEmpty = categories && categories.length;

      if (!isCategoriesNotEmpty) {
        await dispatch(getAllCategories("brand"));
      }
    };

    getCategories();
  }, [categories]);

  return (
    <Layout pageTitle="Categories">
      <Content>
        <Row justify="space-between" margin="2rem 0">
          <ButtonSecondaryLink outlined uppercase href="/">
            Back to Dashboard
          </ButtonSecondaryLink>
          <ButtonPrimaryLink uppercase href="/categories/add">
            Add category
          </ButtonPrimaryLink>
        </Row>
        <CategoriesTable />
      </Content>
    </Layout>
  );
};

export default Categories;
