import React, { useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { Span } from "@styles/typography";

import { Backdrop, ModalWrapper, TitleBar, Close } from "./Modal.styled";
import { createPortal } from "react-dom";

const Modal = ({ isOpen, onClose, title, children }) => {
  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "unset";
  }, [isOpen]);

  if (!isOpen) return null;

  const modalMarkUp = (
    <CSSTransition in={isOpen} classNames="modal" timeout={300} mountOnEnter unmountOnExit>
      <Backdrop className="backdrop">
        <ModalWrapper>
          <TitleBar>
            {title && (
              <Span size={1.6} weight="bold" uppercase>
                {title}
              </Span>
            )}
            <Close onClick={onClose}>✕</Close>
          </TitleBar>
          {children}
        </ModalWrapper>
      </Backdrop>
    </CSSTransition>
  );

  return createPortal(modalMarkUp, document.getElementById("modal-portal"));
};

export default Modal;
