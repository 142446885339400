import { AUTH_LOGOUT } from "../types/auth.types";
import {
    REMOVE_DELETED_PARTNER_CATEGORY,
    SET_CREATED_PARTNER_CATEGORY,
    SET_PARTNER_CATEGORIES,
    SET_PARTNER_CATEGORIES_BY_PARTNER_ID,
    SET_PARTNER_CATEGORY_ERROR,
    SET_PARTNER_CATEGORIES_LOADING,
    SET_UPDATED_PARTNER_CATEGORY,
    SET_PARTNER_CATEGORIES_FETCHING,
} from "../types/partnerCategories.types";

const initialState = {
    all: [],
    selectedPartnerCategories: [],
    loading: false,
    fetching: false,
    error: "",
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_PARTNER_CATEGORIES:
            return {
                ...state,
                all: action.partnerCategories,
                fetching: false,
            };
        case SET_PARTNER_CATEGORIES_BY_PARTNER_ID:
            return {
                ...state,
                selectedPartnerCategories: action.partnerCategoriesByPartnerId,
                fetching: false,
            }
        case SET_CREATED_PARTNER_CATEGORY:
            return {
                ...state,
                all: state.all && state.all.length ? [...state.all, action.partnerCategories] : [],
                loading: false,
                error: "",
            };
        case SET_UPDATED_PARTNER_CATEGORY: {
            const copyOfPartnerCategories = [...state.all];
            const foundedPartnerCategoryIndex = copyOfPartnerCategories.findIndex(
                ({ id }) => id === action.updatedPartnerCategory.id
            );
            copyOfPartnerCategories[foundedPartnerCategoryIndex] = action.updatedPartnerCategory;

            return {
                ...state,
                all: [...copyOfPartnerCategories],
                loading: false,
                error: "",
            };
        }
        case REMOVE_DELETED_PARTNER_CATEGORY: {
            const copyOfPartnerCategories = [...state.all];
            const foundedPartnerCategoryIndex = copyOfPartnerCategories.findIndex(
                ({ id }) => id === action.partnerCategoryId
            );
            copyOfPartnerCategories.splice(foundedPartnerCategoryIndex, 1);

            return {
                ...state,
                all: [...copyOfPartnerCategories],
                loading: false,
                error: "",
            };
        }
        case SET_PARTNER_CATEGORIES_LOADING:
            return {
                ...state,
                loading: true,
            };
        case SET_PARTNER_CATEGORIES_FETCHING:
            return {
                ...state,
                fetching: true,
            };
        case SET_PARTNER_CATEGORY_ERROR:
            return {
                ...state,
                error: Array.isArray(action.error) ? action.error[0] : action.error,
                loading: false,
            };
        case AUTH_LOGOUT:
            return initialState;
        default:
            return state;
    }
};
