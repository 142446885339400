import theme, { themeSchema } from "@styles";

export const getButtonColorsFromParams = styles => {
  const splitButtonStyles = styles.split(",");
  const isStylesAreUnfilled = splitButtonStyles.length === 1;

  return {
    font: !isStylesAreUnfilled ? `#${splitButtonStyles[0]}` : theme.colorWhite,
    background: !isStylesAreUnfilled ? `#${splitButtonStyles[1]}` : `#${splitButtonStyles[0]}`,
  };
};

export const getDefaultButtonColors = background => {
  return {
    font: theme.colorWhite,
    background: background,
  };
};

export const getStylesTheme = ({
  fontColor,
  primaryBgColor,
  primaryBtn,
  secondaryBtn,
  sectionAccent,
  sectionDarkBg,
  sectionLightBg,
  listAccentColor,
  withCCLBrand,
}) => ({
  fontColor: fontColor ? `#${fontColor}` : themeSchema.fontColor,
  primaryBgColor: primaryBgColor ? `#${primaryBgColor}` : themeSchema.primaryBgColor,
  primaryBtn: primaryBtn ? getButtonColorsFromParams(primaryBtn) : getDefaultButtonColors(themeSchema.primaryBtn),
  secondaryBtn: secondaryBtn
    ? getButtonColorsFromParams(secondaryBtn)
    : getDefaultButtonColors(themeSchema.secondaryBtn),
  sectionAccent: sectionAccent ? `#${sectionAccent}` : themeSchema.sectionAccent,
  sectionDarkBg: sectionDarkBg ? `#${sectionDarkBg}` : themeSchema.sectionDarkBg,
  sectionLightBg: sectionLightBg ? `#${sectionLightBg}` : themeSchema.sectionLightBg,
  listAccentColor: listAccentColor ? `#${listAccentColor}` : themeSchema.listAccentColor,
  withCCLBrand,
});

export const getStyleThemeUrlParams = styleTheme =>
  Object.entries(styleTheme).reduce((acc, current, index) => {
    if (current[1] === undefined) {
      return acc;
    }

    if (typeof current[1] === "object") {
      const currentParamsValues = Object.values(current[1]);

      return `${acc}${index !== 0 ? "&" : ""}${current[0]}=${currentParamsValues[0]},${currentParamsValues[1]}`;
    }

    return `${acc}${index !== 0 ? "&" : ""}${current[0]}=${current[1]}`;
  }, "");

export const setSearchStylesIfExist = styleTheme => {
  const styleParamsExist = Object.values(styleTheme).some(paramValue => paramValue);

  return styleParamsExist ? `?${getStyleThemeUrlParams(styleTheme)}` : "";
};
