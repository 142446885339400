export const SET_CHECKLIST_DETAILS = "SET_CHECKLIST_DETAILS";
export const SET_CHECKLIST_DETAILS_ERROR = "SET_CHECKLIST_DETAILS_ERROR";
export const SET_SELECTED_CHECKLIST_DETAILS = "SET_SELECTED_CHECKLIST_DETAILS";
export const CHANGE_SELECTED_CHECKLIST_DETAIL = "CHANGE_SELECTED_CHECKLIST_DETAIL";
export const REMOVE_SELECTED_CHECKLIST_DETAIL = "REMOVE_SELECTED_CHECKLIST_DETAIL";
export const CLEAR_CHECKLIST_DETAILS = "CLEAR_CHECKLIST_DETAILS";
export const SET_SPONSORSHIP_CHECKLIST_DETAILS = "SET_SPONSORSHIP_CHECKLIST_DETAILS";
export const SET_CREATED_SPONSORSHIP_CHECKLIST_DETAIL = "SET_CREATED_SPONSORSHIP_CHECKLIST_DETAIL";
export const DELETE_SPONSORSHIP_CHECKLIST_DETAIL = "DELETE_SPONSORSHIP_CHECKLIST_DETAIL";
export const SET_CHECKLIST_DETAILS_LOADING = "SET_CHECKLIST_DETAILS_LOADING";
