import { AUTH_LOGOUT } from "../types/auth.types";
import {
  REMOVE_DELETED_PARTNER_PRODUCT,
  SET_CREATED_PARTNER_PRODUCT,
  SET_PARTNER_PRODUCTS,
  SET_PARTNER_PRODUCT_ERROR,
  SET_PARTNER_PRODUCTS_LOADING,
  SET_UPDATED_PARTNER_PRODUCT,
  SET_PARTNER_PRODUCTS_FETCHING,
} from "../types/partnerProducts.types";

const initialState = {
  all: [],
  loading: false,
  fetching: false,
  error: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PARTNER_PRODUCTS:
      return {
        ...state,
        all: action.partnerProducts,
        fetching: false,
      };
    case SET_CREATED_PARTNER_PRODUCT:
      return {
        ...state,
        all: state.all && state.all.length ? [...state.all, action.partnerProducts] : [],
        loading: false,
        error: "",
      };
    case SET_UPDATED_PARTNER_PRODUCT: {
      const copyOfPartnerProducts = [...state.all];
      const foundedPartnerProductIndex = copyOfPartnerProducts.findIndex(
        ({ id }) => id === action.updatedPartnerProduct.id
      );
      copyOfPartnerProducts[foundedPartnerProductIndex] = action.updatedPartnerProduct;

      return {
        ...state,
        all: [...copyOfPartnerProducts],
        loading: false,
        error: "",
      };
    }
    case REMOVE_DELETED_PARTNER_PRODUCT: {
      const copyOfPartnerProducts = [...state.all];
      const foundedPartnerProductIndex = copyOfPartnerProducts.findIndex(({ id }) => id === action.partnerProductId);
      copyOfPartnerProducts.splice(foundedPartnerProductIndex, 1);

      return {
        ...state,
        all: [...copyOfPartnerProducts],
        loading: false,
        error: "",
      };
    }
    case SET_PARTNER_PRODUCTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_PARTNER_PRODUCTS_FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case SET_PARTNER_PRODUCT_ERROR:
      return {
        ...state,
        error: Array.isArray(action.error) ? action.error[0] : action.error,
        loading: false,
      };
    case AUTH_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
