import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import styled from "styled-components";
import Layout from "@components/Layout";
import { Container } from "@styles/grid";
import { useDispatch } from "react-redux";
import ApiApplicationViewForm from "./ApiApplicationViewForm";
import { getApiApplicationById } from "@actions/apiApplications.action";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 5.5rem 5.5rem;
`;

const ApiApplicationView = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [apiApplication, setApiApplication] = useState(null);

  useEffect(() => {
    (async () => {
      setApiApplication(await dispatch(getApiApplicationById(params.id)));
    })();
  }, []);

  return (
    <Layout pageTitle={`${apiApplication ? apiApplication.name : ""} Api Application`}>
      <Container>
        <Content>
          <ApiApplicationViewForm apiApplication={apiApplication} />
        </Content>
      </Container>
    </Layout>
  );
};

export default ApiApplicationView;
