import styled from "styled-components";
import theme, { media } from "@styles";

export const Wrapper = styled.div`
  position: fixed;
  max-width: 35rem;
  width: 100%;
  bottom: 20px;
  right: 20px;
  padding: 2.5rem 2rem;
  border-radius: 8px;
  z-index: 1001;
  background-color: ${({ type }) => {
    switch (type) {
      case "info":
        return theme.colorBlue100;
      case "success":
        return theme.colorGreen100;
      case "warning":
        return theme.colorYellow;
      case "error":
        return theme.colorRed100;
      default:
        return theme.colorBlue100;
    }
  }};
  border: ${({ type }) => {
    switch (type) {
      case "info":
        return `1px solid ${theme.colorBlue}`;
      case "success":
        return `1px solid ${theme.colorGreen}`;
      case "warning":
        return `1px solid ${theme.colorYellowDark}`;
      case "error":
        return `1px solid ${theme.colorRed}`;
      default:
        return `1px solid ${theme.colorBlue}`;
    }
  }};
  color: ${({ type }) => {
    switch (type) {
      case "info":
        return theme.colorBlue;
      case "success":
        return theme.colorGreen;
      case "warning":
        return theme.colorYellowDark;
      case "error":
        return theme.colorRed;
      default:
        return theme.colorBlue;
    }
  }};

  ${media.phone`
    max-width: 29rem;
  `}

  &.notice-enter {
    transform: translateY(150%);
  }
  &.notice-enter-active {
    transition: transform 300ms;
    transform: translateY(0);
  }
  &.notice-exit {
    transform: translateY(0);
  }
  &.notice-exit-active {
    transition: transform 300ms;
    transform: translateY(150%);
  }
`;

export const Close = styled.button`
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 0;
  right: 0%;
  font-weight: bold;
  font-size: 1.5rem;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  color: ${({ type }) => {
    switch (type) {
      case "info":
        return theme.colorBlue;
      case "success":
        return theme.colorGreen;
      case "warning":
        return theme.colorYellowDark;
      case "error":
        return theme.colorRed;
      default:
        return theme.colorBlue;
    }
  }};
`;

export const Content = styled.div`
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 0.23px;
  font-weight: 600;
`;
