import styled from "styled-components";

import Layout from "@components/Layout";

import { Container } from "@styles/grid";

import PartnerProductViewForm from "./PartnerProductViewForm";
import { getPartnerProductById } from "@actions/partnerProducts.action";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 5.5rem 5.5rem;
`;

const PartnerProductView = () => {
  const [partnerProduct, setPartnerProduct] = useState(null);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    (async () => {
      setPartnerProduct(await dispatch(getPartnerProductById(params.id)));
    })();
  }, [params.id]);

  return (
    <Layout pageTitle={`${partnerProduct ? partnerProduct.id : ""} Partner Product`}>
      <Container>
        <Content>
          <PartnerProductViewForm partnerProduct={partnerProduct} />
        </Content>
      </Container>
    </Layout>
  );
};

export default PartnerProductView;
