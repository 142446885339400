import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { Row, Indent } from "@styles/grid";
import Select from "@components/inputs/Select";
import TextInput from "@components/inputs/TextInput";
import Textarea from "@components/inputs/Textarea";
import Checkbox from "@components/inputs/Checkbox";
import { ButtonPrimary, ButtonSecondary, ButtonSecondaryLink } from "@components/Button";
import { Form, FormButtons } from "@styles/form";
import { updateCategory } from "@actions/categories.action";
import { getAllBrands } from "@actions/brands.action";
import { nullIfEmpty, emptyIfNull } from "@utils/string";
import { categoryValidationSchema } from "@utils/validation";

const CategoryViewForm = ({ category }) => {
  const [categoryState, setCategoryState] = useState(null);
  const [selectedSponsor, setSelectedSponsor] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const dispatch = useDispatch();
  const loading = useSelector(state => state.categories.loading);
  const brands = useSelector(state => state.brands.brands);

  useEffect(() => {
    if (category) {
      setCategoryState(category);
    }
  }, [category]);

  useEffect(() => {
    (async () => {
      const isBrandsNotEmpty = brands && brands.length;

      if (!isBrandsNotEmpty) {
        await dispatch(getAllBrands());
      }
    })();
  }, [brands]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: categoryState ? emptyIfNull(categoryState.name) : "",
      tooltip: categoryState ? emptyIfNull(categoryState.tooltip) : "",
      selectable: categoryState ? categoryState.selectable : false,
      brand_id: categoryState ? categoryState.brand_id : null,
    },
    validationSchema: categoryValidationSchema,
    onSubmit: async ({ name, selectable, tooltip, brand_id }) => {
      try {
        const newCategory = {
          name,
          selectable,
          tooltip: nullIfEmpty(tooltip),
          brand_id,
        };

        const savedCategory = await dispatch(updateCategory(category.id, newCategory));

        if (savedCategory.data) {
          setCategoryState(savedCategory.data);
          setEditMode(false);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    },
  });

  const handleSelectSponsor = brand => {
    formik.setFieldValue("brand_id", brand.value);
    setSelectedSponsor(brand);
  };

  const handleCancel = () => {
    setEditMode(prevMode => !prevMode);

    formik.setValues({
      name: categoryState ? emptyIfNull(categoryState.name) : "",
      tooltip: categoryState ? emptyIfNull(categoryState.tooltip) : "",
      selectable: categoryState ? !!categoryState.selectable : false,
      brand_id: categoryState ? categoryState.brand_id : null,
    });
  };

  const brandsSelectOptions =
    brands && brands.length
      ? [
          { value: null, label: "None" },
          ...brands.map(brand => ({
            value: brand.id,
            label: brand.name,
          })),
        ]
      : [];
  const foundedSponsor = categoryState && brands.find(({ id }) => id === categoryState.brand_id);
  const sponsorSelectValue = foundedSponsor && {
    value: foundedSponsor.id,
    label: foundedSponsor.name,
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <TextInput
        label="Name"
        labelPosition="left"
        fluid
        placeholder={editMode ? "Category Name" : ""}
        name="name"
        id="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        formik={formik}
        isreadonly={!editMode}
        readOnly={!editMode}
      />
      {editMode ? (
        <Select
          label="Sponsor"
          labelPosition="left"
          placeholder="Select Sponsor"
          options={brandsSelectOptions}
          value={selectedSponsor || sponsorSelectValue}
          onChange={handleSelectSponsor}
          fluid
          formik={formik}
          name="brand_id"
          id="brand_id"
        />
      ) : (
        <TextInput
          label="Sponsor"
          labelPosition="left"
          fluid
          value={sponsorSelectValue ? sponsorSelectValue.label : ""}
          isreadonly={!editMode}
          readOnly={!editMode}
        />
      )}
      <Textarea
        label="Tooltip"
        labelPosition="left-top"
        fluid
        placeholder={editMode ? "Category Tooltip" : ""}
        name="tooltip"
        id="tooltip"
        value={formik.values.tooltip}
        onChange={formik.handleChange}
        formik={formik}
        isreadonly={!editMode}
        readOnly={!editMode}
      />
      <Indent margin="0 0 0 auto">
        <Checkbox
          label="Selectable"
          name="selectable"
          id="selectable"
          labelFontSize={1.2}
          value={formik.values.selectable}
          checked={formik.values.selectable}
          onClick={!editMode ? () => null : formik.handleChange}
          formik={formik}
          readOnly={!editMode}
        />
      </Indent>
      <Row margin="3rem 0" direction="column" align="flex-end">
        <FormButtons>
          {!editMode && (
            <ButtonSecondaryLink type="button" outlined uppercase href="/categories">
              Back to Categories
            </ButtonSecondaryLink>
          )}
          <ButtonSecondary type="button" uppercase onClick={handleCancel}>
            {editMode ? "cancel" : "Edit"}
          </ButtonSecondary>
          {editMode && (
            <ButtonPrimary type="submit" onClick={formik.handleSubmit} uppercase disabled={loading}>
              Save Category
            </ButtonPrimary>
          )}
        </FormButtons>
      </Row>
    </Form>
  );
};

export default CategoryViewForm;
