import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Row, Indent } from "@styles/grid";
import { Span } from "@styles/typography";
import TextInput from "@components/inputs/TextInput";
import { ButtonPrimary, ButtonSecondaryLink, ButtonRoundedPlus } from "@components/Button";
import { Form, SaveButtons, FormInputs } from "@styles/form";
import BundleAddFormProducts from "./BundleAddFormProducts";
import generateUUID from "@lib/generateUUID";
import { createBundle } from "@actions/bundles.action";
import { addBundleValidationSchema } from "@utils/validation";
import SuggestedProducts from "@components/ProductSuggested";

const generateRenderProducts = (length = 1) =>
  Array.from(Array(length)).map(() => ({
    key: generateUUID(),
    product_id: null,
    product_name: null,
  }));

const PLACEHOLDER_PRODUCTS_LENGTH = 5;

const BundleAddForm = () => {
  const [renderProducts, setRenderProducts] = useState(generateRenderProducts(PLACEHOLDER_PRODUCTS_LENGTH));
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.bundles.loading);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues: {
      associatedProduct: null,
      name: "",
      bundle_items: [],
    },
    validationSchema: addBundleValidationSchema,
    onSubmit: () => {},
  });

  const saveBundleToDatabase = async () => {
    try {
      await formik.submitForm();
      const validationErrors = await formik.validateForm(formik.values);

      if (Object.keys(validationErrors).length) {
        return;
      }

      const { associatedProduct, name, bundle_items } = formik.values;
      const bundle = {
        id: associatedProduct.id,
        name,
        bundle_items,
      };

      const createdBundle = await dispatch(createBundle(bundle));

      if (createdBundle) {
        history.push("/bundles");
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const changeRenderProducts = (fields, key) =>
    renderProducts.map(renderProduct => {
      if (renderProduct.key !== key) {
        return { ...renderProduct };
      }

      return {
        ...renderProduct,
        ...fields,
      };
    });

  const setProductsToFormikValue = products => {
    formik.setFieldValue(
      "bundle_items",
      products.filter(({ product_id }) => product_id).map(({ product_id }) => product_id)
    );

    setRenderProducts([...products]);
  };

  const onChangeProduct = (product, suggestionKey) => {
    const foundedProduct = changeRenderProducts(
      {
        product_id: product.id,
      },
      suggestionKey
    );

    setProductsToFormikValue(foundedProduct);
  };

  const onClearProduct = suggestionKey => {
    const foundedProduct = changeRenderProducts(
      {
        product_id: null,
      },
      suggestionKey
    );

    setProductsToFormikValue(foundedProduct);
  };

  const removeProduct = suggestionKey => {
    if (renderProducts.length > 1) {
      const copyOfRenderProducts = [...renderProducts];
      const foundedIndex = copyOfRenderProducts.findIndex(({ key }) => key === suggestionKey);
      copyOfRenderProducts.splice(foundedIndex, 1);

      setProductsToFormikValue(copyOfRenderProducts);
    }
  };

  const addRenderProduct = () => {
    setRenderProducts(prevRenderProducts => [
      ...prevRenderProducts,
      {
        key: generateUUID(),
        id: null,
        product_id: null,
      },
    ]);
  };

  const onChangeAssociatedProduct = product => {
    formik.setFieldValue("associatedProduct", product);
  };

  return (
    <Form handleSubmit={e => e.preventDefault()}>
      <FormInputs>
        <Indent margin="0 0 2rem">
          <SuggestedProducts product={formik.values.associatedProduct} onSelectProduct={onChangeAssociatedProduct} />
        </Indent>
        <TextInput
          position
          label="Name"
          labelPosition="left"
          fluid
          placeholder="Bundle Name"
          value={formik.values.name}
          id="name"
          name="name"
          formik={formik}
          onChange={formik.handleChange}
        />
      </FormInputs>
      {renderProducts && renderProducts.length ? (
        <BundleAddFormProducts
          renderProducts={renderProducts}
          onChangeProduct={onChangeProduct}
          removeProduct={removeProduct}
          onClearProduct={onClearProduct}
          associatedProduct={formik.values.associatedProduct}
        />
      ) : null}

      {formik.errors.bundle_items && formik.touched.bundle_items && (
        <Span color="red" align="right" size={1.4}>
          {Array.isArray(formik.errors.bundle_items)
            ? formik.errors.bundle_items[formik.errors.bundle_items.length - 1][
                Object.keys(formik.errors.bundle_items[formik.errors.bundle_items.length - 1])
              ]
            : formik.errors.bundle_items}
        </Span>
      )}

      <Row margin="3rem 0 4rem" direction="column" align="flex-end">
        <ButtonRoundedPlus onClick={addRenderProduct} />
      </Row>
      <Row margin="3rem 0" direction="column" align="flex-end">
        <SaveButtons>
          <ButtonSecondaryLink outlined href="/bundles" uppercase disabled={loading}>
            Back to Bundles
          </ButtonSecondaryLink>
          <ButtonPrimary onClick={saveBundleToDatabase} uppercase disabled={loading}>
            Create Bundle
          </ButtonPrimary>
        </SaveButtons>
      </Row>
    </Form>
  );
};

export default BundleAddForm;
